<template>
  <div class="region-map">
    <svg
      id="Bundesrepublik_Deutschland"
      version="1.1"
      class="region-map"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 782 951.6"
      style="enable-background:new 0 0 782 951.6;"
      xml:space="preserve"
    >
      <g id="GERMANY">
        <path
          id="Thüringen"
          class="st0 available"
          d="M330,351.7l1.8-0.4l3.4,0.3l1.9,0.2l2.9,0.7l0.4,0.3l2,1.8l1.3-0.5l-1.7,2.2l-0.5,1.6l1.2,2.9
		l2.1,3.3l1.7,2.6l1,1.2l-0.1,2.4l-0.1,2.7l0.8,0.5l0.9,1l0.8,0.1l0.8-0.1l1.2-0.4l0.6-0.3l6.4,2l0.5,0.1l6.4,0.1l1.4,0l0.7-0.1
		l2.8,0.1l4.2,1.6l0.2,0.3l0,0.3l-0.3,1.9l0.5,1l0,0.4l1.8,0.6l-0.5,0.5l-0.1,0.1l0,0.1l0.5,0.6l0.7,0.6l0.3,0.1l0.4,0.2l1.3,1.2
		l0.5,0.5l0,0.3l-0.1,0.1l-0.4,0.7l-0.7,1.1l-1.5,2.1l-0.8,1.2l-0.5,0.6l-0.3,0.2l-0.5,0.2l-0.1,0l-0.8-0.9l-0.2,0l-0.2,0.2
		l-0.3,0.5l-0.6,1l-0.1,0.8l1.6,1.2l1.6,0l0.9-0.4l0.2,0l0.9,0.4l1.6,1.6l1.1,2.5v0.3l-0.5,1.2l-0.3,0.5l-1.3,0.2l0.8,3.4l0.5,0.4
		l0.9,0.4l0.1,0.3l3.9-0.1l0.8-0.7l0.3-0.2l0.3-0.8l0.2-0.2l6.3,1l1.6,1l0.3,1.2l0.2,1.3l0.1,0.6l4.8,1.5l1.8,0.5l2.8-0.6l2-0.5
		l0.8,0.3l3.5,2.2l2.2,1.6l0.3,0.2l2.3,3l0.2,0.5l0,0.2l0,0.9l1-0.4l0.3-0.1l3.2-0.5l0.2,0l3.6,1.2l1.1,0.5l-0.1-0.7l0.2-0.3
		l0.3-0.3l0.7-0.5l0.1,0.1l1.4,1.8l0.4,0.8l0.3,1.1l0,0l0.6-0.4l0.2-0.1l0.7-0.9l3.2-6.9l0.1-0.3l0-1l-0.5-0.6l-0.4-0.2l-1.3-1
		l-0.2-1.7l1.6-2.5l3-1.5l0.1,0l7.7,2.4l2.9,1.1l0.4,0.2l0.2,0.3l0.2,0.9l-0.1,0.7l-0.1,1.2l0.3,1.8l2.5,1.9l2.4,0.9l2.4,0.6
		l0.3,1.6l0.6,2.3l0.1,0.3l0.7,1.1l0.3,0.3l0.5,0.4l-0.8,1.2l-2.8,0.9l-1.4,0.3l-0.6-0.1l-1.9,0.3l-3.2,0.8l-0.3,0.1l-1.5,1.2l0,1.2
		l-0.9,2.6l-0.2,0.1l-1.3,0.4l0-0.1l-0.6-0.7l-0.6-0.2h-1.6l-0.3,0.1l-1.6,1.3l0,0.1l0,1.4l-4.4,1.6l-1.3-0.1l-0.6-0.7l-0.5,1
		l-0.8,5.4l-0.1,1l0,0.5l0,0.3l2.5,4.4l0.8,0.8l3,0.8l-0.2,1.3l-1.1,1.4l-0.1,0.1l-2,2.1l-1.9,0.4l-0.1,0l-3.6,1.6l-3.2,1.4
		l-0.5,0.2l-0.2,0.1l-0.3,0.8l2,0.6l0.4-0.3l0.7,0l0.1,0l0.2,0.4l-0.1,0.5l-0.1,0.1l-0.2,0.1l-0.8,0.1l-0.4-0.1l-1.2,0.8l-2.5-0.3
		l-4,0.2l0.5-2.8l-0.4-3.1l-0.8-0.8l-0.4,0.1l-0.1,0.1l-1.3,1.2l-0.1,0.8l-2.2,2.3l-0.2,0.1l-0.7,0.3l-0.4,0.2l-3.1,4.4l-0.1,0.3
		l0,0.1l0.7,1.8l0.3,0.5l0.9,0l1.1-0.1l0.8,0.8l2,1.9l0.2,0.5l0,0.3l-0.3,0.5l-1.8,1.7l-1.6,0.9l-0.6,0.5l-0.4,0.7l0.1,0.2l0.5,0.5
		l1.6,1.1l-2.7,1.2l-4.1,1.5l-12.5,1.7h-0.2l-0.3-0.2l-0.6-0.5l-0.4-0.5l-0.3-0.2l-0.4-0.2l-1.3,0.3l-0.2,0.1l-0.2,0.3l-0.2,0.8
		l0,0.2l-0.4,0.4l-1.9,0.6l-0.6-0.3l-1.7-1.9l-0.7-1.2l-0.5-2.2l-1.8,0.2l-2-1.2l-0.1-0.2l-0.1-0.6l0-2.1l0.1-1.6l0.4-0.7l0.4,0
		l0.1-0.1l0.3-0.7v-0.1l-0.6-0.8l-0.3-0.5l-0.3-0.2l-3.5-0.7l-1.6,0.2l-0.2,0.1l-1.1,1.2l-0.2,0.8l-2.2,1.8l-1.5,0.7l-0.1-0.2
		l-0.5-0.1l-0.4,0.1l-0.2,0.3l-0.1,0.6v0.2l0.1,2.1l0.7,0.5l0.4,0.2l0.3,0.8l1.2,7.6v0.1l-2,6.9l-3.1,0.5l-0.9,1.9l-0.1,0.1l-0.8,0
		l-2.2-2.1l-0.7-1l0-0.1l-0.1-0.5l0.1-0.6l0.4-0.5l0.3-0.3l0.6-0.4l0.4-0.1l0-0.6l-1.4-2.4l-1.6-1.4l-0.9,0l-0.7,0.2l-0.1,0.1
		l-0.4,1.2l-1.2,0.5l-1.4,0.5l-0.4-0.1l-3.6-0.6l-1.3-1.5l-1.1-2l-0.5-0.6l-0.2,0h-0.2l-6.6,0l-7.5,3.1l-0.2,0.9l0.2,3.1l0.1,0.4
		l0.4,0.3l5.5,3.9l0.2,0.1l0.6,0.1l1.3,0.4l0.6,0.3l0.4,0.5l0.1,1.4l-0.2,0.6l-0.1,0.2l-0.7,0.5l-0.7,0.2l-0.3,0l-2.8-0.6l-2.4-0.6
		l-0.6-0.1l-0.3,0.2l-0.1,0.2l0,1.7l0.1,0.1l0.1,1.8l-0.4,0.8l-0.2,0.1l-0.3,0l-5.2-1.9l-1.8-0.5l-0.2-0.4l-0.4-1.2l-0.7-2.9
		l0.3-3.6l0.3-2.8l-3-0.7l-4-1.5l-2.3-2.5l-0.3-1.2l-0.3-1l-0.9,0.4l-0.9,0.4l-1.9-0.2l-0.2,0l-0.5-0.8l0-0.6l-0.4-2.4l-2.4-5.2
		l-0.5-0.5l-0.5-0.4l-4.7-2l-1.5-2l-1.8-2.4l-0.5-0.1l-6.6,0.7l-1.8,2l-1,1l-0.4,0.2l-0.3,0.1l-0.2-9.7l0.2-0.4l0.4-0.6l0.2-0.2
		l1.8-1.5l-0.7-2.1l-0.8-1.4l-0.3-0.2l-1.3-0.1l-2.2-0.1l-0.5,0.1l-2.4,1l-0.2,0.3l0,0.6l1.2,1.5l-0.5,1.2l-0.9,0.2l-1.1-0.2
		l-3.3-0.6l-0.1-0.9l0.1-2.7l0.3-0.4l0.7-1l1.4-1.7l1.3-2.7l0.5-1.2v-0.3l-0.2-1.5l-0.9-1.1l1.3-0.4l0.3-0.1l0.1-0.1l0-0.5l-0.1-1
		l-0.4-0.7l0-0.6l0.4-1.8l0.1-0.3l1.1-0.9l0.5-0.3l1.6,0.2l1.4,0l0.7-1.2l1.6-4.1l0-0.8l-1-5.9l-1.5-4.8l0.6-1.3l0.1-0.3l0.1-0.1
		l0.3-0.1l0.9-0.1l1.4,0.1l2.3,0.3l1.3,0.7l0.3,0.4l0.3,0.2l0.5,0.1l0.4-0.1l1.7-0.2l0.2-0.2l0.2,0l0.5-0.2l0.3-0.2l0.5-0.7
		l-0.1-0.2l0.6-1.2l0.2-0.5l-0.5-0.9l-0.5-0.5l-0.2-0.2l-0.9-0.6l-1.8-0.7l-0.2-4.3l-0.6-1.2l-1.3-2.9l-0.1-0.3l0.5-0.6l2.6-0.6
		l1.1,1.1l0.4,1.3l0.3,0.4l0,0l0.4,0.7h0.6l0.2-0.2l0-0.8l0-1.8l-0.2,0l-0.4-0.5l0-0.3l0.4-1.3l0.3-0.3l0.4-0.2l0.6-0.4l0.3-0.8
		l-0.3-0.6l-1.2-0.3l-1.3-1.3l-0.6-0.5l-0.8-0.5l-1.7-0.6l-4.6-2.3l-0.9-2.9l-1.9-0.8l-3.4-1.2l-1.2-1.5L281,383l-0.6-2.2l-0.1-1
		l0.2-0.9l0.2,0.1l0.2-0.1l0.5-0.2l3.1-2.1l6.7-3.9l5.3-1.1l8-5.4l2.6-2.1l0.1-0.1l1.5-2.3l0.1-0.3l0.2-0.8l0-0.1l-0.4-0.2l0.9-1.9
		l0.7-1.2l2.4-0.6l0.2,0l0.8,0.3l1.5,0.7l0.4,0.2l0.7,0.5l0.4,0.3l1.2,1.8l0.6,0.3l2.4-0.3l4.6-1.2l0.9-1.8l2.1-5.5l0.3,0l1.2-0.2
		L330,351.7z"
        ></path>
        <path
          id="Schleswig-Holstein"
          class="st0 available"
          d="M340,114l0.9,3l-0.9,2.1l-2.6-0.2l-5.2,3.7l-1.4,2.3l0,1.8l1,2.5l0.2,0.4l0.3,0.9
		l0.1,0.5l-0.2,1.5l-0.4,3.1l3.8,3.7l1.5,0.9l0.2,0.1l1.3-0.1l0.4-0.1l0-0.2l0.2-0.1l0.2,0l1.1,0.2l0.9,0.4l1.3,1.4l0.7,3.8
		l-1.4,1.9l-0.4,2.4l-6.6,8.7l-2.1,2.5l-1,1.1l-0.3,0.3l-3.8,2.9l-1.4-0.3l-0.6-0.3l-0.5,0l-1,0.6l-0.6,1.3l-1.8,8.1l0.1,0l-0.1,0.1
		l-0.4,0.3l-0.3,0.1l-0.5,0l-4.1-1.2l-0.6-0.2l-0.9-0.5l-0.8-0.6l-2.4-1.2l-5.9-2.7l-1.6-0.9l-0.5-1l-3.7-4l-0.2-0.8l0.1-0.6
		l-0.5,0.1l-0.4-0.1l-3.5-2.4l-0.2-0.2l-0.9-2.4l-0.1-2.1l0.3-1.2l0.6-0.6l2.1-1.7l1.3-2.7l0-0.1l0.2-0.7l-1.6-2.2l-0.8-0.9
		l-0.1-4.7l0.6-1.4l0.1-0.9l-1.3-0.7l-0.2-0.1l-0.3,0l-4.3,2.1l-1.4,0.9l0.5,0.6l-0.8,1.9l-4.9,3.8h-0.8l-0.5-0.1l-1-0.2l-0.2-0.2
		l-1.2,0l-1.1,0.1l-0.1,0.8l0,0.3l-0.3,0.6l-0.4,0.6l-3.5,4.4l-0.8,0.4l-0.5-0.2l-1.1-1l-1.7-2.1l-0.1-0.7l0.1-0.5l-0.4,0l-0.2,0.3
		l-0.7,2.1l-0.3,0.9l-0.4,1.6l-0.4,2.3l-1.7-0.2l-1.6-0.6l-5.4-3.5l-2.3-1.8l-0.4-0.6l-0.4-0.9l-0.4-1.2l-0.1-1.6l-0.1-0.5l-0.4-1
		l-0.8-1.9l-0.3-0.6l-1-1.1l-2.3-1.7l-0.9-0.8l-0.6-0.8l-0.7-1.3l-0.8-1.9l-1.8-3.9l-0.5-1l-2.4-3.4l-1.8-1.3l-3.5-1.5l-1.9-0.3
		l-2.1,0l-3.6-0.1l-3.2-0.2l-1,0l-0.8,0.1l-0.3-2.6l-0.2,0l-1.5,0.7l-0.3,0.1l-0.5,0l-0.4-0.1l-1-0.9l-1.9-1.6l-0.9-1.3l-0.6-1.7
		l-0.3-1.1l-1.3-3.2l-2.1-3.3l-0.4,0.1l-0.3-0.1l-0.1-0.2l1.5-1.2l1.2-0.7l1-0.2l2.5,0.6l0.5,0.3l0.4,0.5l0.7,0.1l0.8-0.2l0.5-0.2
		l0.2-0.2l0.3-0.3l0.2-0.4l0.1-0.6l0.2-0.7l0-0.3l0-0.3l-0.1-0.4l-3.2-6.7l-0.2-0.3l-0.7-0.2l-0.3-0.1l-1,0.2l-0.4,0.3l-1.3,0.8
		l-0.5-0.3l-1.1-0.7l-0.1-0.2l-0.5-1l-1-2.8l-0.1-0.5l0-0.2l0.2-0.6l1.1-2.2l0.6-1.4l0.1-0.4l0.3-3.5l0.1-0.7V82l-0.2-0.1l0.2-0.1
		v-0.3l-0.1-0.6l-0.1-0.4l-0.3-0.5l-0.4-0.5l-0.2-0.1l-0.3-0.1l-3.8,0l-0.8,0.1l-2.4,0.2l-1,1l-0.6,0.8l0.1,0.1l0.5,0.1l0.1,0
		l0.1-0.1l0,0l-0.1,0.1l-0.1,0.1l-0.2,0.1l-0.4,0l-0.4-0.1l-4.4-2.3l-0.6-0.4l-0.5-1l-0.1-0.5l0.1-0.7l0.3-1.4l0.2-0.8l0.3-0.9
		l0.2-0.4l0.1-0.1l0.9-0.5l0.1,0.9l1.2,0.9l0.6-0.1l0.2-0.1l2.6-1.6l0.1-0.1l0.1-0.2v-0.9l0-0.2l-0.1-0.6l0.5-2.4l6.6-1.2l1.3,0.1
		l1.4,0.7l1.1-0.3l1.3-0.6l1.1-0.6l5.5-4.6l0.1-0.1l0.6-1.1l0.2-1.1l0-0.3l-0.2-0.8l-1.3-2.8l-6.4-9.1l-3-4.2l-1.2-0.7l-1.8-0.9
		l-1.2-1.2l-0.3-0.4l-2.2-5.7l-0.8-3.8l-0.9-1.8l-0.1-0.2l-2.3-2.7l-2.1-2.4l0-0.2l0.4-3.6l1.5-3.4l1.8,0l0.7,0.1l0.4,0.2l1.2,0.8
		l5.4-0.3l0.7-0.1l0.8-0.1l0.5,0l4.1,0.3l2.7,0.2l0.6,0.2l0.3,0.1l3.1,1.4l0.9,0.6l0,0.2l0.4,0.3l0.7,0.4l0.9,0.1l1.6,0.1l1.3-0.4
		l1.4,0l0.4,0.1l0.7,0.2l2.9,1.2l2.1,1l0.3,0.6l-0.4,0.5l-0.2,0.4l0,0.4l0.1,0.4l0.7,1.8l4.7,0.5l1-0.1l1.5-1.3l0.2-0.2l0.1-0.6
		l2.6-0.5l1.1,0.5l1.4-0.5l1.3-0.4l0.9-0.4l5.1-4.6l0.5-0.1l2.2,0.8l-0.4,0.7l-1,1.9l4.3,1.8l3.3,1.4l5.9,6.7l1,0.5l0.9,0.3l0.5,0.1
		h0.1l0.1-0.1l1.6-3.5l-0.1-0.2l0.2-0.5l0.2-0.3l0.1-0.1l1.1,0.7l1.7,1.2l1.1,2l3.4,10.6l-0.1,2.2l-0.2,3l0,5.4l0,0.4l-1.7,2.9
		l-0.2,0.3l-0.6,0.7l-1,0.9l-1.6,0.9l-2.6,1.3l-1.8,0.9l-0.4,0.1l-0.2-0.2l-0.2-0.2l-0.6,0l-0.3,0.1l-0.3,0.1l-0.1,0.1l0.7,1.4
		l0.3,0.5l0.4,0.4l0.5,0.3l0.3,0.1l2-0.3l0.7-0.1l0.3-0.2l1.9-0.8v-0.1l0.8-0.3l5.1-1.2l3.8-0.8l0.4-0.1l0.5,0l0.5,0.1l1.5,1
		l0.7,0.5l1.4,1.4l-0.5,0.8l-1,1.6l0.2,0.6l0.8,3.8l-1.2,1l-0.6,1.1l-0.1,0.6l1.3,1.8l0.3-1.2l2.3-5.1l0.5-0.5l4.6-2.3l1-0.2l0.6,0
		l0.6,0.1l1.2,0.3l1.7,0.5l12.4,6.2l0.7,0.4l1.5,1l0.4,0.3l0.5,0.7l0.2,0.5l-0.1,0.1l0.1,0.3l2,2.2l0.4,0.3l0.2,0.1l0.6,0.2l0.6,0
		l2.7-0.1l0.4-0.1l0.5-0.2l0.7-0.3l0.5-0.3l1.9-1.5l0.7-0.7l1.1-1.4l1.8-1.6l2.5-1.9l1.2-0.3l8.2-0.4l-0.3,3.6l-0.5,0.8l-0.1,0.3
		l0.1,2.3l0.6,6.7l0.7,5.7l0,0.1l-0.1,0.1l-2.6,2.1l-0.7,0.5l-3.2,2.3l-3,2.7l-2.6,3.3l-0.4,0.4l-0.5,0.3l-0.5,0.2l-2.2-0.4
		l-0.3-0.1l-0.5-0.3l-3.2,3.4l-0.3,0.3l-0.2,0.3l-0.2,0.6l-0.1,0.5l0,0.3l0.1,0.5l0.7,1.7l0.6,1.1l0.4,0.6l0.4,0.5l0.2,0.2l0.5,0.3
		l0.5,0.1h1.9l0.2,0l0.9-0.2l0.3,0l0.5,0.2l0.4,0.3l0.3,0.4l0.2,0.5l0,0.2l-0.1,1l0.1,0.8l0.5,0.2l0.3,0L340,114z M186.3,10.3
		l-1.1,2.9l-0.9,2.6l-0.3,1.4l-0.2,2l-0.2,2.4l-0.6,9.2l0,0.7l0.6,1.3l0.3,0.4l0.1,0l0.1-0.2l0-0.4l0.2-7.8l-0.1-0.2l-0.1-0.5v-0.2
		l0.2-1.5l0.4-1.4l0.3-0.3l2.1-1.4l0.6,0.5l0.1,0.2l1.4,0.8l1.4,1l0.5,0.2h0.2l2.7-1.4l1.7-1.5l-0.1-0.1l-0.4-0.1l-0.5-0.1l-2.2,0.1
		l-0.5-0.1l-0.3-0.1l-1.8-1.6l0,0l-1.6-1.7l-0.4-6l1.1-2.3l0.5-0.6l1-0.8l0.1-0.1l1.2-0.3l1.3-0.8l1.6-3.1l0-0.1l-0.1-0.1l-2.1-0.9
		l-0.6-0.2l-0.3,0l-0.2,0.1l-0.5,0.3l-0.1,0.1l-0.4,0.7L188.2,6l-0.4,0.9L186.3,10.3z M194.7,31.7l-0.5,0l-1.9,0.4l-0.6,0.2
		l-0.4,0.3l-0.5,0.6l-0.4,0.7l-0.5,1.3l-0.1,0.8l0.1,0.4l0.2,0.3l0.2,0.2l4,2l0.8,0.2l1,0.1l3.3,0.1l0.4,0l0.2-0.1l1.9-3.9l-0.6-2.4
		l-0.1-0.3l-0.9-0.6l-1.1-0.4l-0.5-0.1l-0.3-0.1l-0.7-0.1l-0.8,0.3l-0.3,0.1L194.7,31.7z M183.7,40.8l0,0.2l0.3,0.8l1.2,2l0.6,1
		l0.7,1.1l0.2,0.1l0.4,0.1l1.1,0l0.1,0l1.2-0.6l0.1-0.1l0.3-0.8l0-0.1l-0.1,0l-1.2-0.6l-1-1.6l-1.3-3.5l-0.1-0.7l0.2-0.5l0.3-0.5
		l1-1l-0.1-0.1l-0.2-0.1l-0.4,0.2l-2.5,2.2l-0.3,0.4l-0.4,0.9l0,0.8L183.7,40.8z M200.9,44.4l3.7-0.9l0.5-0.3l0.3-0.4l0.2-0.6l0.2-1
		l-0.2-0.1l-6.3,2.5l-1.6,0.7l-0.1,0.4l0.1,0.1l1.1,0.9L200.9,44.4z M196.7,49.6l-0.3,0l-0.2,0.2l0.1,0.2l0.3,0.4l0.2,0.1l1.6,1.2
		l0.3,0.2l0.9,0l0.5-0.1l0.1-0.2l0-0.4l-0.8-1.2l-0.1-0.2l-0.1-0.1l-0.3-0.1l-0.7,0.2L196.7,49.6z M202.2,58.3l0.6,0.5l0.3,0.1
		l0.7,0l1-0.2l0.9-0.3l0.2-0.1l0.3-0.4l1.1-2.4l0.6-1.4l0.1-1.5l-0.2-0.1l-0.6-0.1l-0.6,0.1l-2.2,0.7l-0.3,0.1l-2.6,1.1l-0.4,0.2
		l-0.2,0.3l-0.1,0.3l0,1.1l0.1,0.5l0.1,0.3l0.2,0.3l0.1,0.1L202.2,58.3z M217.4,55.6l-3.1,2l-0.4,0.5v0.3l0,2.6l0.7,0.5l0.6,0.3
		l0.8,0.2l2.2,0.2l0.9,0l1.3-1.1l0.6-0.7l0.1-0.1l1.5-2.3l0-0.2l-0.2-1.3l-0.3-0.4l-0.9-0.2l-2.2-0.1L217.4,55.6z M349.8,54.9
		l-0.5-0.2l-0.2-0.2l-0.1-0.2l-0.2,0.1l-0.3,0.3l-0.8,0.9l-0.6,0.8l-0.4,0.6l-0.7,1.6l-0.3,1l-0.1,0.3l-0.3,3.4l0.1,0.6l0.1,0.2
		l1.6,1.1l0.2,0.1l0.4,0.1l0.1-0.1l-0.1,0l0,0h-0.1l-0.4-0.1l-1-0.6l0-0.6l0.2-0.2l2.3-0.8l1.1,0.3l0.4,0.2l0.4,0.2l0.1,0.3l0.3,0.8
		l-0.1,0.9l-0.1,1.3l5.1,0.9l7.8,0.1l0.1,0l0-0.1l0-0.6l-1.3-2.7l-1-2.2l-3.1-4.8l-3.2-1.7L349.8,54.9z M206,104.8l-0.2-0.1
		l-0.3-0.4l-0.5-0.6l0-0.4l0.1-0.5l0.2-0.4l0.2-0.2l0.2-0.2l0.2-0.1l0.3-0.1l0.2,0l0.3,0.3l0,0.1l-0.1,2.5l-0.3,0.2L206,104.8z
		 M158.8,88.8l1.2-0.1l0.1,0.6l-0.9,1.5l-0.7-0.1l-0.3-0.1l-0.9-1.6l0-0.2l0.5-0.4l0.4-0.1L158.8,88.8z"
        ></path>
        <path
          id="Sachsen-Anhalt"
          class="st0 available"
          d="M384,207.8l1.4-0.6l0.3,0l0.5,0l0.2,0.1l0.2,0.1l0.2,0.2l0.1,0.2l0,0.2l-0.1,0.2l-0.9,1.2
		l-0.1,0.3l0.1,0.4l0.1,0.3l0.2,0.3l3.6,2.8l0.2,0.1l0.5,0.1l0.3-0.1l0.7-0.5l1-0.6l0.2,0l0.5,0.1l0.4,0.1l0.4,0.2l0.2,0.1l5.2,3
		l0.2,0.3l0.1,0.3l0,0.3l0,0.2l-0.1,0.3l-0.1,0.2l-0.5,0.5l-0.5,0.6l-0.2,0.4l-0.1,0.2l0.1,0.5l0.3,0.5l0.2,0.2l0.3,0.2l3.6,1.5
		l5.2,1.7l0.3,0.1l8.2-1l0.7-0.7l-0.1,0.2l-0.3,0.6l0,0.7l0.8,2l1.6-0.5l0.9-0.5l1.5-0.7l1.5,0.7l0.6,0.4l0.1,0.3l1.2,4.9l-0.2,1.8
		l-1.5,0.7l-0.7,1.3l-0.3,1.1l-0.4,4.4l0,0.1l1.2,2l1.2,1.8l0.3,0.9l0,0.6l-0.4,3.7l-1.6,1.2l-0.4,0l-0.4-0.1l-0.9,1.6l-1.8,7.1
		l0,0.8l0,0l1.8,2.2l0.9,1l1.2-0.2l1.1-0.3l0.3-0.5l0-0.1l0.2-0.9l5.5,1.9l0.4,3.3l-0.3,0.9l-0.6,1l-0.9,2.2l0.2,1.6l0.4,2.9
		l0.7,3.4l-1.2,2.4l-0.2,0.1l-0.1,0.2l-1.2,2.6l-0.6,1.8l-0.1,0.5l-0.3,2.5l0.2,4l-1.8,4.1l2.9,3.8l2.1,3.2l2.6,3.4l1.7,1.9l4.6,2.9
		l7.2,2.8l4,0.6l0.8-0.1l1.8-1.3l0.2-1.4l0.4-0.6l0.9,0l2,0.9l0.7,0.4l1.6,0.9l1.8,0.9l0.3,0l0.5,1.4l4.9,2.8l2.4,0.6l0.5,0l1.1-0.4
		l4.9,2.6l3,3.1l0.3,0.2l0.2,0l2.4,0.2l2.1-0.8l0.3-0.2l-0.1-0.7l0.9,0l0.4,0.3l0.7,0.7l0.1,0.6l0,0.6l-1.7,0.3l0,0.2l0,0.3
		l3.3,11.7l0.5,0.4l0.3,0.3l0.5,0.9l0,0.5v0.1l-1.4,2.5l-0.5,0.6l-6.5,4.3l-2.2,0.3l-6.6,0.3H471l-9.5,0.2l-0.9-0.3l-0.1-0.1
		l-0.7-0.5l-1-0.4l-0.3-0.1l-0.5-0.1h-1l-0.4,0.2l-0.3,0.3l-0.1,0.2L456,348l-0.7,1.9l-0.2,0.2l-0.8,0.6l-0.8,0.6l-1.6,0.8l-2-1.4
		l-0.3,1.1l-2,1l-7.5,2.2l-2.3,0.2l-2.5,0.1l-0.7,0.1l-5.3,2.8l-3.5,3.1l-0.2,2.4l-2.4,5.3l0.4,1.4l1,3.4l0.1,0.1l0,0.1l0.2,0.7
		l1,5.5l0.2,1.6l-1.3,0.5l-1.6,0.9l1.7,4.5l1.3,1.8l0.4,1.9l0,0.3l0.4,2.4l0,7.2l2,0.4l0.3,0.2l0.5,0.6l-0.1,0.4l-0.7,0.6l2.3,2
		l1.4,1l-1.6,2.5l0.3,1.7l1.3,1l0.4,0.2l0.5,0.6l0,1l-0.1,0.3l-3.2,6.9l-0.7,0.9l-0.2,0.1l-0.6,0.4l0,0l-0.3-1.1l-0.4-0.8l-1.4-1.8
		l-0.1-0.1l-0.7,0.5l-0.3,0.3l-0.2,0.3l0.1,0.7l-1.1-0.5l-3.6-1.2l-0.2,0l-3.2,0.5l-0.3,0.1l-1,0.4l0-0.9l0-0.2l-0.2-0.5l-2.3-3
		l-0.3-0.2l-2.2-1.6l-3.5-2.2l-0.8-0.3l-2,0.5l-2.8,0.6l-1.8-0.5l-4.8-1.5l-0.1-0.6l-0.2-1.3l-0.3-1.2l-1.6-1l-6.3-1l-0.2,0.2
		l-0.3,0.8l-0.3,0.2l-0.8,0.7l-3.9,0.1l-0.1-0.3l-0.9-0.4l-0.5-0.4l-0.8-3.4l1.3-0.2l0.3-0.5l0.5-1.2v-0.3l-1.1-2.5l-1.6-1.6
		l-0.9-0.4l-0.2,0l-0.9,0.4l-1.6,0l-1.6-1.2l0.1-0.8l0.6-1l0.3-0.5l0.2-0.2l0.2,0l0.8,0.9l0.1,0l0.5-0.2l0.3-0.2l0.5-0.6l0.8-1.2
		l1.5-2.1l0.7-1.1l0.4-0.7l0.1-0.1l0-0.3l-0.5-0.5l-1.3-1.2l-0.4-0.2l-0.3-0.1l-0.7-0.6l-0.5-0.6l0-0.1l0.1-0.1l0.5-0.5l-1.8-0.6
		l0-0.4l-0.6-1l0.3-1.9l0-0.3l-0.2-0.3l-4.2-1.6l-2.8-0.1l-0.7,0.1l-1.4,0l-6.4-0.1l-0.5-0.1l-6.4-2l-0.6,0.3l-1.2,0.4l-0.8,0.1
		l-0.8-0.1l-0.9-1l-0.8-0.5l0.1-2.7l0.1-2.4l-1-1.2l-1.7-2.6l-2.1-3.3l-1.2-2.9l0.5-1.6l1.7-2.2l-1.3,0.5l-2-1.8l-0.4-0.3l-2.9-0.7
		l-1.9-0.2l-3.4-0.3l-1.8,0.4l-0.4-0.1l-0.1-0.1l-0.3-0.4l-1.3-2l-0.1-0.4l-0.2-1.3l-0.1-0.9l0.2-0.5l-0.9-2.2l-3-4.5l-1-0.6
		l-0.7-1.5l-0.3-2.8l0-3.5l0.1-0.5l0.4-0.5l2.1-1.8l0.4-0.2l0.4,0l0.2-0.1l1-1.6l0.1-0.3v-0.7l-0.1-0.7l-0.1-0.3l-0.5-0.6l-0.8-0.3
		l-0.9-0.3l0-1.6l0-0.2l0.8-1.1l1.3-0.4l-3.7-2.8l-1.7-2.4l4.2-0.4l1.8-1.7l0.1-0.1l0-0.3l-0.2-0.4l0.1-1l1.9-0.6l1-0.2l5-0.1h0.3
		l0.9,0.2l1.8-0.1l1.4-0.3l0.4-0.1l0.9-0.7l0.5-0.1l3.4-0.1l1.7,0.2l0.1,0.1l0.3-3.1l0.4-1.9l2-1.4l1.9-1.5l1.2-1.8l0.2-0.3l0.1-1.5
		l-0.1-0.4l-0.1-0.1l-0.2,0L351,297l-0.5,0l-0.7-0.4l-0.4-0.3l-0.2-0.2l-0.1-0.3l-0.1-0.4l0.1-0.8l0.4-1l0.3-0.5l0.3-0.2l1-0.5
		l0.7-0.2l1.1-0.2l0.6-0.8l0-0.1l0-0.1l-0.4-1l-1.4-2l-1.4-1.4l-1.4-1.6l-0.1-0.1l-0.4-4.1l1.2-1.7l0.6,0.1l0.2-0.1l1.1-0.5l0.8-0.6
		l0.1-0.2l0-1.4l-0.1-0.4l-0.6-0.6l-0.8-0.7l-0.2-0.1l-0.3-0.1l-1.3-0.8l-4.5-5.8l-0.9-1.8l0.6-1.6l2.2-1.2l1.7,0.1l-1.8-2.1
		l-1.3-1.7l-0.8-1.3l-0.3-0.8l-0.3-1.4l0.2-2.2l0,0l-2.8-5.4l-3.7-5.3l-2.6-1.9l-2.6-7.2l0.1-0.6l0.1-0.6l-0.2-2.3l0-0.2l0.5-2.2
		l2.1-0.6l2.7-0.6l6.1-0.2l2.9-3.1l0.2-0.4l0-0.2l-0.1-0.5l0-0.8l0.2-0.7l0.3-0.4l0.3-0.1l0.3,0l9.3,0.4l3.9,0.8l0.7,0.8l0.3,0.3
		l0.1,0.3v0.3l0,0.1l0.1,0.1l0.3,0.1l3.2,0.3l1.1-0.3l5.7-2.8l0.1-0.2l0.9-1.2l1.8-1.7l1.1-0.6l1.2-0.3l0.9,0l-0.8-1.9l0.6-3.2
		l0.2-1l0.3-0.7l0.8-0.2l0.1,0l0.4,0.5l0.6,0.4l0.7,0.1l0-0.1l2.5-3.6L384,207.8L384,207.8z"
        ></path>
        <path
          id="Sachsen"
          class="st0 available"
          d="M479.8,347.3l2.4,3.9l0.6,0.3l0.7-0.5l0.3-0.3l0.2-0.3l2.6,1.4l0.4,0.8l1.9,4.1l1.1,2.7l0.2,0.7
		l0.2,0.7l-0.2,5.2l-0.8,3.7l1.5,1.8l0.2,0.6l0.2,0.4l2.3,0.1l0.5-0.1l1.7-0.5l-0.1-1.1l0-0.1l2.6-2.3l4.5-2.4l0.6,0.5l2.3,2.1
		l2.1,1.7l4.9,3l2.9,0.9l0.3,0l2-0.1l3.3-0.1l0.5-0.7l0.3,0.4l0.8,0.5l0.5,0.1l3.1,0.4l0.8-0.6l2.1-1l1.6-0.6l2.8,0.1l0.3-0.1
		l7.5-1.5l2.7-3.5l1.4-5.5l1.5-4.4l2.1-3.1l0.4-0.1l1.3,0.2l3.1-0.1l4,0.5l2.9,1l4.3-2.5l3.4-0.4l0.3,0.2l-0.1-0.5l0-0.4l0.1-0.4
		l0.4-0.3l6.9-2.5l2.2,0.9l0.1,0.7l0.4,0.8l0.1,0.2l0.4,0.1l0.5,0.1l3.2-0.3l0.2-0.1l0.5-0.5l0.2-0.2l0.1-0.3l0.6-2.3l0.8-0.8l1,0.8
		l-1,2l0,0.2l1.3,3l0.4,0.5l1.1,0.4l0.9,0.2l0.9,0.1l0.9-0.1l0.2,0l8.2,3.1l0.2,0.1l1.7,0.9l0.1,0.1l0.4,0.7l1.3,2.1l0,0.4l-0.1,4
		l4.9,10.4l0.7,2l0,2.9l-1.2,2.7l-0.2,1.2l-2,14.3l-0.2,1.6l-0.9,1.2l-0.7,2.5l0.3,0.2l0,0.1l-0.2,1.1l-0.7,3.2l-1.2,3.7l-0.3,0.5
		l-1.5,2l-1.4,1.7l-1.4,2.7l-0.3,2.4l-0.4,2.4l-0.1,0.7l-0.3,0.2l-1.4,0.1l-0.5,0l-2.1-0.4l-5.5-2.1l-2.1-0.8l-0.1-0.8l0.8-2.4
		l-0.8-3.8l-2.1-6.2l-1.3-1.3l-5.3-4.5l-0.5,0.4l0.2,0.7l-0.1,1.4l-4.8,0.7l-0.5,0.1l-0.2,0l-4.6-1.7l-0.2-0.1l-0.2-0.2l-1-1.1
		l-0.2-0.2l-0.1,0l-0.7,0.1l-0.4,0.5l-0.3,0.6l-1.9,5.8l0.1,0.2l3.6,3.2l0.2,0.2l0.4,0.2l3.5,0.9l0.7-0.2l0.4,0.2l0.3,0.2l0.3,0.4
		l0.1,0.9l-0.7,2.5l-5.3,1.9l-11.2,5.7l-2,1.1l-1.1,1.4l-0.6,0.2l-8.4,2.7l-0.8,0.1l-1.8,0.1l0.3,0.5l0.2,3.2l-0.1,0.4l-0.3,0.8
		l-2.5,1.7l-0.6,0.2l-1.2,0.2l-0.1,0l-0.4-0.1l-0.6-0.4l-0.3-0.1l-1.9-0.4l-0.2,0l-7,0.9l-1,0.1l-7.2,4.3l0.2,0.5l0.2,0.9l0,0.1
		l-1.8,4.2l-0.9,0.6l-1.9,2.4l-3.9-1.9l-2.1-0.2l-5.9,4.5l-3.4,3.7l0.1,0.7l-1.9,4.5l-1.5,0.3l-3.1-1l-5.5,0.5l-0.6,0.2l-0.2,0.1
		l0,0.2l-0.4,2.1l-0.1,2.1l0.1,0.7l0.2,0.4l0,0.5l0,0l-0.1,0.4l-0.2,0.4l-2,2.5l-0.4,0.3l-2.1,1.3l-0.7-0.3l-2.9-1.9l-4.2-2.7
		l-0.7-0.4l-0.1,0l-5.3,3.2l-3.7,2.1l-1.1,0.1l-5,0.6l-4.5,1.2l-7.4,9.3l-2.1,3.1l-1.9,3.8l-0.2,0.8l-0.3,2.8l0.2,0.5l0.1,0.1
		l0.2,0.1l0.2,0.2l0.4,0.7l-0.2,1.9l-0.3,0.2h-0.8l-0.8-0.1l-1-0.2l-0.2-0.1l-0.1-0.1l-0.8-1.3l-0.3-0.6l0-0.3l0.5-0.2l0.2-0.1
		l0.2-0.8l-0.2-1.6l-5.8-8.4l-1.1-1.3l-0.5,0l-2,0l-2.7,0.1l-0.4,0.3l-0.5-0.6l-2-0.8l-4.5-1.3l-1.2-0.2l-0.4-0.3l0.3-1.8l0.2-1.7
		l0-0.4l-2.6-2.6l-0.7-0.6l-0.9-0.2l-1.6-1.1l-0.5-0.5l-0.1-0.2l0.4-0.7l0.6-0.5l1.6-0.9l1.8-1.7l0.3-0.5l0-0.3l-0.2-0.5l-2-1.9
		l-0.8-0.8l-1.1,0.1l-0.9,0l-0.3-0.5l-0.7-1.8l0-0.1l0.1-0.3l3.1-4.4l0.4-0.2l0.7-0.3l0.2-0.1l2.2-2.3l0.1-0.8l1.3-1.2l0.1-0.1
		l0.4-0.1l0.8,0.8l0.4,3.1L417,460l4-0.2l2.5,0.3l1.2-0.8l0.4,0.1l0.8-0.1l0.2-0.1l0.1-0.1l0.1-0.5l-0.2-0.4l-0.1,0l-0.7,0l-0.4,0.3
		l-2-0.6l0.3-0.8l0.2-0.1l0.5-0.2l3.2-1.4l3.6-1.6l0.1,0l1.9-0.4l2-2.1l0.1-0.1l1.1-1.4l0.2-1.3l-3-0.8l-0.8-0.8l-2.5-4.4l0-0.3
		l0-0.5l0.1-1l0.8-5.4l0.5-1l0.6,0.7l1.3,0.1l4.4-1.6l0-1.4l0-0.1l1.6-1.3l0.3-0.1h1.6l0.6,0.2l0.6,0.7l0,0.1l1.3-0.4l0.2-0.1
		l0.9-2.6l0-1.2l1.5-1.2l0.3-0.1l3.2-0.8l1.9-0.3l0.6,0.1l1.4-0.3l2.8-0.9l0.8-1.2l-0.5-0.4l-0.3-0.3l-0.7-1.1l-0.1-0.3l-0.6-2.3
		l-0.3-1.6l-2.4-0.6l-2.4-0.9l-2.5-1.9l-0.3-1.8l0.1-1.2l0.1-0.7l-0.2-0.9l-0.2-0.3l-0.4-0.2l-2.9-1.1l-7.7-2.4l-0.1,0l-3,1.5
		l-1.4-1l-2.3-2l0.7-0.6l0.1-0.4l-0.5-0.6l-0.3-0.2l-2-0.4l0-7.2l-0.4-2.4l0-0.3l-0.4-1.9l-1.3-1.8l-1.7-4.5l1.6-0.9l1.3-0.5
		l-0.2-1.6l-1-5.5l-0.2-0.7l0-0.1l-0.1-0.1l-1-3.4l-0.4-1.4l2.4-5.3l0.2-2.4l3.5-3.1l5.3-2.8l0.7-0.1l2.5-0.1l2.3-0.2l7.5-2.2l2-1
		l0.3-1.1l2,1.4l1.6-0.8l0.8-0.6l0.8-0.6l0.2-0.2l0.7-1.9l0.1-0.6l0.1-0.2l0.3-0.3l0.4-0.2h1l0.5,0.1l0.3,0.1l1,0.4l0.7,0.5l0.1,0.1
		l0.9,0.4l9.5-0.2h0.1l6.6-0.3L479.8,347.3z"
        ></path>
        <path
          id="Saarland"
          class="st0 available"
          d="M43.9,560.3l0.5,0.3l7,2.2l1.3,0.2l4.4,0.3l7.1-1.2l4.3-1.2l0.6-0.3l1.3-0.8l0.2-0.1l0.5-0.5
		l0.2-0.3l0.6-0.6l0.3-0.2l0.8-0.4l3-1.2l1.9-0.7h0.4l0-0.1l0.1-0.1l0.1-0.1l3-1.8l0.4-0.1l2,0.3l0.9,0.2l-0.1,0.3l-0.2,0.7l0,0.2
		l0.1,0l1.6-0.6l0.5-0.5l0.6-0.6l2.6,1.7l3.3,3l6.1,3.3l0.8,0.1l0.8-0.3l1.1-0.6l0.4-0.4l0.2,0.3l0.4,0.7l0.5,1l0,0.2l0.1,0.8
		l0.1,4.4l-0.5,5.2l-1.7,0.5l-0.4,0.3l0,0.1l0.2,1l0.1,0.4l2.5,1.7l0.1,0.8l-0.3,1.5l0,0.4l5,2.9l0.7-0.2l1.2-0.6l0.4,0.4l-0.6,0.4
		l-0.7,4.3l0,0.1l0.6,0.4l-0.5,2.3l-0.6,0.8l-0.4,0.4l-0.6,0.3l-1.4,0.7l-2.9,1.9l-1,1.3l0,0.2l0,0.6l1,3.1l0.9,1.2l1.8,2.6l0.2,0.2
		l0.3,0.2l1,0l-0.5,0.9l-4.4,3.5l-0.4,0.2l-0.7-0.2l-1-0.7L100,606l-4.1-0.4l-0.4,0.1l-0.7,0.3l-0.6,0.7l-0.2-0.1l-0.8-0.5l-0.5-0.3
		l-1.3-1.2l-1-1.7l-0.8,2.5l-1.6,1.6l-0.7,0.2l-0.3-0.1l-0.3-0.3l-0.2-0.5l-0.7-3.8l-0.2-1.4l0.1-0.5l-1.2-1.9l-2.2-1.7l-2.3-0.8
		l-1.4-0.4l-4-0.5l-1.4,0.9l-0.1,0.2l0.4,0.4l0.6,0.8l0.6,1.9l0,0.3l-0.2,0.4l-0.9,1.5l-1.7,0.3l-5.5-1l-1.2-1.3l-0.6-1.4l1.4-1.8
		l-0.5-1.4l-2.1-1.2l0.1-1.8l0-0.2l-1.3-3.2l-1.9-1.7l-1.6-0.9L58,586l-0.9-1.2l-0.3-0.5l-1.4-2.5l-0.1-0.2l-1.5-6l-0.1-0.4l0.1-0.3
		l0.7-0.5l0.6-0.1l-0.4-0.6l-0.5-0.6l-0.5-0.4l-4.3-3.3l-2.3-0.9l-1.7,0.3l-0.3,0.2l-0.7,0.2l-1.3-0.1l-0.5-0.6l-0.4-6.2l0.1-0.2
		l0.3-0.4L43.9,560.3z"
        ></path>
        <path
          id="Rheinland-Pfalz"
          class="st0 available"
          d="M163.2,448.3l-0.9,1l-0.1,0.4l-0.1,0.5l0,0.9l0.9,4.9l0.1,0.3l0.4,0.2l1.2,0.7l0,0.6
		l-0.4,1.2l-0.7,1.8l-0.3,0.7l-0.8,1.9l-0.2,0.4l-0.8,0.1l-1.1-0.2l-1-0.3l-0.5-1l-1.2-0.5l-0.3,0l-0.6,0.2l-0.4,0.1l-2.4,2.8
		l-0.5,1.1l-0.5,2.4l0,0.3l0.5,1.3l0.7,1l-1.8,4.9l-0.3,0.8l0.5,0l5.3,3.5l2.7,4.9l0.2,0.4l0.1,0.2l0.7,2.2l0.1,0.4l0,1.9l-2,1.2
		l-2.1-0.9l-0.7-0.1l-0.9,0.3l-1,1.3l0.2,0.5l1.7,1.6l0.4,0.3l0.1,0.2l-1.7,1.9l-0.3,0l-1-0.2l-1.8-1.1l-4.3,1.9l-0.3,0.2l-0.6,0.4
		l-1.5,2l-0.1,0.1l0.1,1.3l0.4,0.5l0.5,0.3l0.8,0.6l1,0.9l0.1,0.4l-0.4,3l-0.3,0.4l-2.3-0.6l-0.4-0.1l-4.2,3.5l-2,1.1l-0.9,0.2
		l0.7,1.9l5.4,7.6l0.5,0.3l0.3,0.1l0.4,0l3.3-0.1l2-0.3l0.3-0.1l7-2.7l4.1-1.9l2.5-0.8l0.9-0.1l1.1,0.1l1.9,0.4l0.6,0.2l1.2,1.1
		l0.7,0.7l0.4,0.6l0.8,1.2l1.3,1.2l0.7,0.8l0.4,0.6l0.5,0.8l0.5,1.2l0,1l0.6,1.9l0.1,0.6l0,0.5l-0.1,0.3L176,529l-0.3,0.6l-0.1,0.4
		l-0.1,0.5v0.4l0.2,0.8l2.2,5.6l0.3,0.5l1,1.4l0.5,1l2.8,2.9l0.3,2.3l-0.6,1l-0.1,0.3l-0.1,0.2l-0.4,0.6l-0.5,0.4l-0.4,0.2l-0.4,0.2
		l-1.5,0.3l-0.7,0.3l-0.5,0.2l-0.5,0.3l-0.6,0.5l-0.1,0.1l-0.2,0.4l-0.1,0.2l-0.2,1l0.1,0.5l1.6,5.1l0.5,0.9l1.6,2.4l0.5,1.2
		l-0.1,1.1l-0.4,0.7l-0.1,0.5l0,0.4l0,0.4l0.1,0.3l0.4,0.8l1.1,4.4l0.1,0.6l0.4,3.2l-0.2,0.2l-0.2,0.2l-0.1,0.3l0,0.4l0.1,0.2
		l0.2,0.2l0.3,0.2l0.2,0.1l0.6,0.1l0.7,0l1,0l0.6,0.1l0.4,0.2l0.3,0.4l0.1,0.3l0,0.3l-0.8,2.1l0.2,2.3l0.1,0.9l0,0.6l-0.2,0.9
		l-0.4,0.8l-0.6,0.9l-0.5,0.5l-0.4,0.3l-0.4,0.3l-0.5,0.5l-0.2,0.3l-0.1,0.3l0,0.2l0,0.4l0.1,0.4l0.2,0.2l0.2,0.1l1,0.3l0.2,0.1
		l0.4,0.3l0.2,0.4l0.1,0.5l0,0.4l-0.1,0.2l-1.4,0.7l-1.8,2l-0.5,0.4l-1.4,0.9l-0.6,0.3l-0.5,0.4l-0.4,0.4l-0.3,0.5l-0.2,0.6
		l-0.1,0.7l0,0h0.1l0.2,0l0.2,1.8l-2.4,10.3l-0.2,0.5l-0.4,0.7l-0.7,0.8l0,0l-1,1.1l-0.7,1.1l-0.4,0.7l-0.5,1.2l-0.2,0.9l-0.2,1.4
		l-0.1,0.3l-0.1,0.3l-0.4,0.9l-0.3,0.5l-0.3,0.5l-0.4,0.4l-1.1,0.8l-1.6,1.2l-0.4,0.2l-0.3-0.6l-0.2-0.3l-0.1-0.1l-3.5-0.4l-0.3,0.1
		l-1,0l-0.9-0.2l-3-1.2l-1.6-0.7l-0.1-0.3l-0.7-0.8l-0.7-0.7l-5-2.4l-3.3-0.9l-1.3-0.1l-1-0.5L138,615l-3.4,0.8l-0.3,0.1l-1.9,0.1
		l-3.9-0.2l-2.9-1.1l-4.5-2.7l-0.9-0.6l-1.4-1.2l-2.1-4l0-0.8l0.2-1.2l-0.2-1.4l-3.2-1.6l-2.4,0.4l-2,0.4l-1.1,0.3l0,0.5l0.1,0.3
		l0.2,0.3l-1,0l-0.3-0.2l-0.2-0.2l-1.8-2.6l-0.9-1.2l-1-3l0-0.6l0-0.2l1-1.3l2.9-1.9l1.4-0.7l0.6-0.3l0.4-0.4l0.6-0.8l0.5-2.3
		l-0.5-0.5l0-0.1l0.7-4.3l0.6-0.4l-0.4-0.4l-1.2,0.6l-0.7,0.2l-5-2.9l0-0.4l0.3-1.5l-0.1-0.8l-2.5-1.7l-0.1-0.4l-0.2-1l0-0.1
		l0.4-0.3l1.7-0.5l0.5-5.2l-0.1-4.4l-0.1-0.8l0-0.2l-0.5-1l-0.4-0.7l-0.2-0.3l-0.4,0.4L101,561l-0.8,0.3l-0.8-0.1l-6.1-3.3l-3.3-3
		l-2.6-1.7l-0.5,0.6l-0.5,0.5l-1.6,0.6l-0.1,0l0-0.2l0.2-0.7l0.1-0.3l-0.9-0.2l-2-0.3l-0.4,0.1l-3.1,1.8l-0.1,0.1l-0.1,0.1l0,0.1
		h-0.4l-1.9,0.7l-3,1.2l-0.8,0.4l-0.3,0.2l-0.6,0.6l-0.2,0.3l-0.5,0.5l-0.2,0.1l-1.3,0.8l-0.6,0.3l-4.3,1.2l-7.1,1.2l-4.4-0.3
		l-1.3-0.2l-7-2.2l-0.5-0.3l-1.4-2.2V558l0.1-0.2l1.3-2l0.4-0.5l0.2-0.1l0.5,0l0.3-0.1l0.2-0.3l1.2-1.3l5.8-9.4l1.5-5.1l0-0.6
		l-0.6-4.2l-2-1.3l-1.5,0.9l-1.5,0l-0.1,0l-1.8-1l-0.2-0.2l-3.1-3.1l-5.6-3.4l-1.8-2.1l-3.3-5.6l-0.4-1l-0.3-0.8l-1.2-2.9l-1.5-6.1
		l0.3-3l2-7.2l2.5-2.1l-0.3-4.2l0.2-1.3l0-0.1l0.2-0.2l7.5-4.8l0.4-0.9l1.2-2.5l0.4-0.2l1-0.2l2.2,1.1l2.8-0.7l1.4,0.1l-0.7-1.1
		l-0.5-0.8l-0.2-0.5l-0.7-2.7l-0.3-1.3l1.9-0.4l2.2,1.9l0.1,0.1l0.7,1.1l4.8-0.7l4.7-1.6l1.3,1.4l0.1,0.2l3.8,1.6l3.2,0.9l3-0.9
		l0.8,0.1l0.6,0.1l-0.9-1.5l-1-1.8l-0.1-6l3.2-2.1l0.4,0.3l2.4,1.6l0.7-0.2l2.4-1.1l1.9-7.8l0.2-1.4l2.2,0.3l0.7,0.1l1.7-0.8
		l0.2-0.1l1.1-1.1l0.9-1.1l2.6-0.4l5.3-1.2l1.6-0.5l1-1l0.1-0.1l0.4-1.4l0.1-0.5l0.3-0.6l0.3-0.1l0.5,0l0.1,0.3v0.5l-0.1,1.6l0,0.4
		l3,0l1.6-0.4l0.8-0.2l2.8-0.9l1.3-0.7l0.2-0.3l0.5-2.1l0-1.6l-0.1-1.5l0.5-1l0.5-0.5l1.9-0.1l2.1,0.7l0.3-0.2l0.9-0.4l13.1-4.7
		l0.5-0.4l1.1-1.4l0.4-0.9l-0.2-1.6l-0.3-0.3l-1-0.8l0.6,0.1h0.3l1.6-0.6l0.1,0l2.4-2.1l1.3-1.8l-0.6-5l0.5-0.5l2.4-2.1l0.4-0.2
		l1.3,0.3l2.3,1.7l-0.5,0.8l-0.4,0.9l-0.5,1.6l-0.1,0.4l0,0.3l0.1,0.5l2.3,0.6l1.1,0.3l4.9,2.8l0.3,0.2l0.3,0.3l0.5,1.4l0,0.6
		l-0.2,2.1l-0.1,0.2l-0.4,0.4l-0.1,0.7l-0.1,0.9l0.2,1l0.3,0.5l0.6,0.8l0.3,0.3l1.5,1.4l1.5,1.5l0.4,0.4l0.1,1.6l-0.3,1.5l2.6-0.9
		l1.3,0.3l0.7,0.6L163.2,448.3z"
        ></path>
        <path
          id="Nordrhein-Westfalen"
          class="st0 available"
          d="M249.5,350.8l-1.3,2.2l-3.8,4.6l-3.2,5.3l-4.5,3.5l-4.3,3.5l-0.7,1l-0.6,1l-0.6,0.1
		l-2.8-0.2l-0.6-0.2l-0.3-0.2l-0.2-0.2l-0.6-1.6l0.7-1.8l-1.2-2l-2.9-1.2l-1.2,0.1l-2.2,0.5l-1.6,0.5l-2.9,1.2l-0.7,0.7v0.4l0.2,0.7
		l2.5,4.1l0.5,0.1l0.3,0.2l0,0.2l0.2,1.4l-0.8,2.5l-0.1,0.1l-3.4-0.2l-4.2,0.1l-3.8,1l-5,1.5l-3,1.9l-1.1,0.7l-1.1,1.4l-2.6,3.8
		l-0.6,0.9l2.1,2.9l0.2,0.2l0.3,0.1l0.8,0l0.1,0l1-0.7l4.6-2.1l0.7-0.1l0.9,0l0.9,1.2l0.1,0.3l0.7,6.4l0.2,2l-0.1,0.4l-0.3,0.7
		l-2.8,6.2l-3.2,1.2l-0.4,0l-1.1-0.3l-0.8-0.3l-1.7-0.3l-2.8-0.2l-0.1,0.1l-1.3,0.5l-0.1,0.1l-0.8,0.6l-0.5,0.5l-0.4,0.6v0.2
		l0.1,0.3l1.1,3.3l0.7,1.5l0.4,0.9l-0.5,0.7l-0.4,0.4l-1,0.9l-1.4,1.6l-0.9,0.9l-0.1,0.2l0,0.2l0.3,0.4l-0.2-0.1l-0.6,0l-0.1,0.1
		l-0.4,0.2l-0.1,0.1l-0.6,2.6l-4.3,6.5l-1.1,1.2l-0.1,0.1l-0.5,0.1l-0.2-0.1l-4.1-2.2l-0.2-0.1l-1,0l-0.2,0l-0.2,0.2l-9.5,9.3l0.4,1
		l0.4,0.8l0.8,1.5l0.8,1.1l0.2,1l-0.1,0.4l-2,3.7l-0.7,1l-1-1.2l-0.7-0.6l-1.3-0.3l-2.7,0.9l0.3-1.5l-0.1-1.6l-0.4-0.4l-1.5-1.5
		l-1.5-1.4l-0.3-0.3l-0.6-0.8l-0.3-0.5l-0.2-1l0.1-0.9l0-0.7l0.4-0.4l0.1-0.2l0.2-2.1l0-0.6l-0.6-1.4l-0.3-0.3l-0.3-0.2l-4.9-2.8
		l-1.1-0.3l-2.3-0.6l-0.1-0.5l0-0.3l0.1-0.4l0.5-1.6l0.4-0.9l0.5-0.8l-2.3-1.7l-1.3-0.3l-0.4,0.2l-2.4,2.1l-0.5,0.5l0.6,5l-1.3,1.8
		l-2.4,2.1l-0.1,0l-1.6,0.6h-0.3l-0.6-0.1l1,0.8l0.3,0.3l0.2,1.6l-0.3,0.9l-1.1,1.4l-0.5,0.4l-13.1,4.7l-0.9,0.4l-0.3,0.2l-2.1-0.7
		l-1.9,0.1l-0.5,0.5l-0.5,1l0.1,1.5l0,1.6l-0.5,2.1l-0.2,0.3l-1.3,0.7l-2.8,0.9l-0.8,0.2l-1.6,0.4l-3,0l0-0.4l0.1-1.6v-0.5l-0.1-0.3
		l-0.5,0l-0.3,0.1l-0.3,0.6l-0.1,0.5l-0.4,1.4l-0.1,0.1l-1,1l-1.6,0.5l-5.3,1.2l-2.6,0.4l-0.9,1.1l-1.1,1.1l-0.2,0.1l-1.7,0.8
		l-0.7-0.1l-2.2-0.3l-0.2,1.4l-1.9,7.8l-2.4,1.1l-0.7,0.2l-2.4-1.6l-0.4-0.3l-3.2,2.1l0.1,6l1,1.8l0.9,1.5l-0.6-0.1l-0.8-0.1l-3,0.9
		l-3.2-0.9l-3.8-1.6l-0.1-0.2l-1.3-1.4l-4.7,1.6l-4.8,0.7l-0.7-1.1l-0.1-0.1l-2.2-1.9l-1.9,0.4l0.3,1.3l0.7,2.7l0.2,0.5l0.5,0.8
		l0.7,1.1l-1.4-0.1l0.3-1.2l0-0.1l-0.5-0.9l-2.4-2.9l-0.1-2l0.6-1l0.3-0.8l0.5-2.5l0.1-0.7l-0.1-1.1l-0.2-0.5l-1.7-2.9l-0.5-0.7
		l-1.1-0.7l-0.2-0.3l-0.2-0.2l-2.5-0.4l-1.2,0l-0.1,0l-0.5,0.4l-1,0.4l-0.1,0l-0.1,0l-2-2.8l-0.3-0.6l-0.5-1.6l-0.1-1.5l0.1-0.3
		l3.3-3.1l0.8-3.6l-3.5-0.9l-0.9-1.1l0.2-0.1l1.1-0.4l-0.8-0.7l-0.5-0.7l-1-1.6l-0.3-0.5l-0.1-0.5l-1.3-3.4l-0.3-0.3l-0.2-0.1
		l-4.5-2.5l-2.7-4.5l1.6-5.2l0.2-0.7l1-0.4l1-0.3l0.4,0l0.1,0.3l1,0.5l1.1-2.6l-0.1-0.7l-0.3-0.4l-0.3-0.5l-0.1-0.5l0.4-1.5l0.1-0.2
		l0.4-0.4l0.1-0.3l0.1-0.4l-0.8-0.3l-1.3-0.6l-2.4-0.9l-0.1-1.4l0.1-1.4l0.6-1.2l0.3-0.9l0-0.1l-0.5-0.1l-3.9-0.3l-2.5,0.6l-0.9-1
		l-1.4-3.1l-0.6-3l0-0.5l0.1-0.1l3-1.3l6.2-2l7.9-5.3l1-2.9l2.4-1l0.4-0.2l-1-0.8l-1.1,0.4l-1.7,0.8l-0.5,0.3l-0.1,0.2l-0.2,0.2
		l-0.4,0.2l-0.3,0.1l-0.4,0l-0.2,0l-0.5-0.1l-0.5-1.1l-0.1-3.2l1.2-3.4l7.2-9.1L43,374l-1-3.6l0.6-3.2l0.5-1.6l0.3-2.3l0-0.7l-0.5-4
		l-0.7-1.4l-2.6-4.2l-0.7-0.5l-3.3-3.8l0.2-1.1l-0.2-4.5l-3.2-1.5l-0.4-0.5l-0.1-0.1l-1.9-6.2l-1.5-7l1.5-2.5l4.4-2l2.2,0.1l4.9-0.7
		l2-1.3l4.9,0.2l0.2,0.1l0.7,0.3l0.7,0.4l3,1.9l1.9,1.7l1.3,0.7l0.4,0.1l-1.1-3.5l0-0.3l0.3-0.9l0.2,0l2.1,0.9l4.9,0.1l0.9-0.5
		l1.5-0.8l2.9-1.1l5.8-2l0.6-0.1l0.5,0.3l0.3,0.5l1.4,1.4l0,0l0.7-0.2l1.2-0.8l1.2-0.9l1.3-1.3l2.7-3.4l0.3-0.6l0-0.2l-0.1-1.4
		l-0.2-0.8l-4.5-3.8l-2.4-1.3l1.5-3.5l1.5-2.5l5.7-1.8l0.6-0.1l0.8-0.7l0.4-0.4l0.4-1.6l0.2-0.8l1.8-2l7.7-4.8l2.5-1.5l2.1-0.1
		l0.7-1l0.2-0.3l0.6-0.6l2.1-0.5l2.6,0.3l1.3,0.1l5,0.3l0.9-1l0.4-0.6l0.8-0.1l1-0.1l1-0.5l0.3-0.5l0.3-0.7l0.6-0.5l3.7-2.7l4.2-2.6
		l1.7-1l0.3,0l0.3,0l2.3-1.2l1.8-3.2l0.1-0.7l-1.4-0.7l-0.7-0.5l0.5-1.5l0.4-0.8l0.6-1l0.8-1l0.2,0.1l4.6,1.8l0.2,0.2l0.2,0.3
		l0.5,1.1l1.6,4.5l5.1,3.1l2.2,0.3l1.3-0.6l0.3-0.2l1.6-0.4l2.8,1.7l1,1.7l0.7,2.5l-2.1,2.2l-0.1,0.4l0,0.5l0.1,0.6l0.2,0.2l1.4,1.4
		l-1.1,0.7l-2.7,5.8l0,0.1l0.2,0.8l0.3,1l0.3,0.5l1.2,0.9l0.1,0l2.3,0.5l2,1.8l0.2,4.1l-3.2,1l-3.7,1.2l-0.9,0.7l0,0.1h0.3l1.4,2.3
		l0.2,1l1,0.5l1.8,0.8l1.1,0.4l0.6-0.9l0.4-0.5l2.3-1.7l0.2,0l1.5,0.1l1,0.1l1.1,0.3l0.3,0.6l2.3-1.4l1.6-0.4l4-2.9l1.7-1.6l1.4-1
		l0.6,0.1l2.9,0.6l2.4,0.9l3,0.3l-0.2-0.7l-0.1-0.6l0.1-0.3l0.3-0.5l0.4-0.4l2.2-1.5l0.2-0.1l0.6,0l0.4-0.1l0.5-0.3l0.2-0.2l1-1.1
		l0.5-0.6l0.6-0.8l-1.2-0.6l-1.2-0.4l-0.4,0l-0.3-0.1l-0.8-0.7l-0.5-1l-0.1-0.2l1.1-4.5l0.5-1l-0.2-3.3l0.1-1.8l-1.6-4.6l-2.2-1.7
		l-5.2-3.9l-0.6-3.2l-0.5-1.1l0.4-0.3l0.4,0.1l2.4,0.6l0.3,0.3l0,0.2l0.5,0.5l3.4-0.3l1-0.1l1.5-1.3l0.1-0.3l0.2-1.3l0.1-1.4
		l3.1-2.4l3.1,1.5l5.9-3.1l2.1,1.7l0.7,0.7l0.2,0.6l0,0.3l-0.3,1.3l0.2,7.6l0.2,1.5l0.5,0.4l1.8,0.8l6.7-0.1l5.1-1.1l0.2-0.1
		l2.9-3.8l2.1-2.8l0.8-1.4l1.6-2.1l2.3,0.5l2.7,1.2l0.2,0.7l0.1,0.2l-1.8,4.1l0.9,2.3l-0.1,0.7l-0.2,0.5l-1.5,3.1l-0.8,1.5l-0.1,0.1
		l-0.7,0.7l-0.8,0.5l-1.2,0.2l-1,0.5l-1,0.7l-0.7,0.5l-2.3,4.8l0.5,1.6l0.2,0.1l0.3,0.1l1.6,0.4l2,0.8l2.5,1.7l-0.1,0.2l-2,4.9
		l-1.5,0l-2.3-1.2l1.9,2.5l3.5,2.7l3.5,0.7l0.9,0.3l0.2,0.1l0.5,0.6l0.3,1l-0.3,2.2l1.3,1.2l0.9,1.6l-0.1,0.1l-0.5,0.3l-0.5,0.7
		l0,0.2l0,1.8l0.3,6.2l0.6,1.5l5.7,4.1l0.9,0.5l1-0.2l0.1,0l0.3,0.1l1.1,0.6l0.1,0.1l0.5,0.8l0.3,1.3l0,0.6l-0.1,1l-0.5,2.4
		l-0.2,0.1l1-0.6l0.3-0.2l0.6-0.2l0.2,0l0.4,0.1l1.5,0.6l0.6,0.3l1.8,1.8l0,3.4l-1.2,3l-1.8,5.6l-0.9,7.2l0.5,0.3l0.3,0.1h0.2l1.6,0
		l0.1,0l0.5-0.2l0.1,0l0.6-0.2l0.2,0L249.5,350.8z"
        ></path>
        <path
          id="Niedersachsen"
          class="st0 available"
          d="M194.9,149.4l-0.9-2.2l-1.2-3l0-2v-1.3l0.1-0.7l3.8-11.4l0.5-1.4l1-2.6l2.2-2.9l0.8-0.8
		l1.8-0.5l1.3-0.3l1.1-0.1l0,0.5l0.1,0.3l0.1,0.3l1.5,1.8l2.8,2.1l1,0.7l0.6,0.3l0.7,0.2l1.6,0.2l5.1,0.4l1.6-0.3l0.3-0.1l0.1-0.1
		l0-0.1l0.3-0.1l2.1-0.4l0.5,0l2.1,0.5l-0.5-0.6l-0.8-2l-0.4-1.2l0.8-0.4l0.9-0.3l0.8-0.1l1,0l3.2,0.2l3.6,0.1l2.1,0l1.9,0.3
		l3.5,1.5l1.8,1.3l2.4,3.4l0.5,1l1.8,3.9l0.8,1.9l0.7,1.3l0.6,0.8l0.9,0.8l2.3,1.7l1,1.1l0.3,0.6l0.8,1.9l0.4,1l0.1,0.5l0.1,1.6
		l0.3,1.3l0.4,0.9l0.4,0.6l2.3,1.8l5.4,3.5l1.6,0.6l1.7,0.2l2.7,0.4v2.8l-0.3,2.2l0.8,0.9l1,2.2l0.1,0.6l3.4,3.5l0.3-0.1l0.8-0.6
		l1.3-1.4l0.3-0.2l0.3,0.2l0.7,0.9l2.1,2l1.6,1.3l0.4,0l2.8-2.1l0.2-0.9l0.6-1.3l0.6-0.7l1.8,1l0.5,0.6l0.4,0.5l1.8,2l2.5,2.4
		l0.4,0.3l0.4,0.1l3.2,0.1l0.4-0.1l0.2-0.1l0.2-0.3l0.6-1.5l0.2-0.3l0.2-0.3l0.4-0.2l2.2-1.2l0.2-0.1l0.1,0l1.6,0.9l5.9,2.7l2.4,1.2
		l0.8,0.6l0.9,0.5l0.6,0.2l4.1,1.2l0.5,0l0.3-0.1l0.4-0.3l0.1-0.1l-0.1,0l0.7-0.4l0.7-0.2l1.1-0.1l4,0l0.2,0.1l0.2,0.1l1,0.8
		l0.6,0.6l1.2,1.5l0.5,0.7l0.3,0.6l1.1,1l0.7,0.4l2.7,1l0.9-0.5l1.1-0.7l1.1-0.7l0-0.3l0-0.1l0.4-0.8l1.4-1.1l2.6,0.7l1.8,0.5
		l2.4,5.6l2.3,4.4l1.1,1.6l0.7,0.7l2.9,2.1l1.2,0.3l0.9,0.2l0.6,0.1l0.8,0.4l0.1,0.2l0,0.4l-0.3,0.9l-0.1,0.2l-0.5,0.6l-0.5,0.2
		l3.6,1.4l0.6,0.3l0.6,0.4l0.9,1l0.2,0.3l0.2,0.4l0,1.4l0,0.3l0.1,0.2l1.1,1.4l3,3.1l0.3,0.2l0.2,0l0.5,0l1.7-0.9l1-0.8l0.3-0.2
		l0.4-0.1l2-0.6l0.4,0l0.5,0l0.4,0.2l0.4,0.3l0.7,0.8l1.2,1.2l0.6,0.4l0.6,0.2l4.4,1.1l0.6,0l0.1,0.1l0,0.1l-2.5,3.6l0,0.1l-0.7-0.1
		l-0.6-0.4l-0.4-0.5l-0.1,0l-0.8,0.2l-0.3,0.7l-0.2,1l-0.6,3.2l0.8,1.9l-0.9,0l-1.2,0.3l-1.1,0.6l-1.8,1.7l-0.9,1.2l-0.1,0.2
		l-5.7,2.8l-1.1,0.3l-3.2-0.3l-0.3-0.1l-0.1-0.1l0-0.1v-0.3l-0.1-0.3l-0.3-0.3l-0.7-0.8l-3.9-0.8l-9.3-0.4l-0.3,0l-0.3,0.1l-0.3,0.4
		l-0.2,0.7l0,0.8l0.1,0.5l0,0.2l-0.2,0.4l-2.8,3.1l-6.1,0.2l-2.7,0.5l-2.1,0.6l-0.5,2.2l0,0.2l0.2,2.3l-0.1,0.6l-0.1,0.6l2.6,7.2
		l2.6,1.9l3.7,5.3l2.8,5.4l0,0l-0.2,2.2l0.3,1.3l0.3,0.8l0.8,1.3l1.3,1.7l1.8,2.1l-1.7-0.1l-2.2,1.2l-0.6,1.6l0.9,1.8l4.5,5.8
		l1.3,0.8l0.3,0.1l0.2,0.1l0.8,0.7l0.6,0.6l0.1,0.4l0,1.4l-0.1,0.2l-0.8,0.6l-1.1,0.5l-0.2,0.1l-0.6-0.1l-1.2,1.7l0.4,4.1l0.1,0.1
		l1.4,1.6l1.4,1.4l1.4,2l0.4,1l0,0.1l0,0.1l-0.6,0.8l-1.1,0.2l-0.7,0.2l-1,0.5l-0.3,0.2l-0.3,0.5l-0.4,1l-0.1,0.8l0.1,0.4l0.1,0.3
		l0.2,0.2l0.4,0.3l0.7,0.4l0.5,0l0.9-0.1l0.2,0l0.1,0.1l0.1,0.4l-0.1,1.5l-0.2,0.3l-1.2,1.8l-1.9,1.5l-2,1.4l-0.4,1.9l-0.3,3.1
		l-0.1-0.1l-1.7-0.2l-3.4,0.1l-0.5,0.1l-0.9,0.7l-0.4,0.1l-1.4,0.3L336,310l-0.9-0.2h-0.3l-5,0.1l-1,0.2l-1.9,0.6l-0.1,1l0.2,0.4
		l0,0.3l-0.1,0.1l-1.8,1.7l-4.2,0.4l1.7,2.4l3.7,2.8l-1.3,0.4l-0.8,1.1l0,0.2l0,1.6l0.9,0.3l0.8,0.3l0.5,0.6l0.1,0.3l0.1,0.7v0.7
		l-0.1,0.3l-1,1.6l-0.2,0.1l-0.4,0l-0.4,0.2l-2.1,1.8l-0.4,0.5l-0.1,0.5l0,3.5l0.3,2.8l0.7,1.5l1,0.6l3,4.5l0.9,2.2l-0.2,0.5
		l0.1,0.9l0.2,1.3l0.1,0.4l1.3,2l0.3,0.4l0.1,0.1l0.4,0.1l-0.1,0.2l-1.2,0.2l-0.3,0l-2.1,5.5l-0.9,1.8l-4.6,1.2l-2.4,0.3l-0.6-0.3
		l-1.2-1.8l-0.4-0.3l-0.7-0.5l-0.4-0.2l-1.5-0.7l-0.8-0.3l-0.2,0l-2.4,0.6l-0.7,1.2l-0.9,1.9l0.4,0.2l0,0.1l-0.2,0.8l-0.1,0.3
		l-1.5,2.3l-0.1,0.1l-2.6,2.1l-8,5.4l-5.3,1.1l-6.7,3.9l-3.1,2.1l-0.5,0.2l-0.2,0.1l-0.2-0.1l0.4-0.2l0-0.1l0-1.2l-0.3-0.6l-1.3-2
		l-0.3-0.2l-3.1,0.4l-3.2,0.5l-0.2,0l-0.4,0.3l-4.4,3.7l-1.1,1.5l1.8,2.8l1.7-0.8l0.5,0l0.1,0.1l0.1,1.4l-0.1,0.5l-0.3,1.2l-2.3,1.2
		l-0.1-0.2l-3.4-1.7l-5.3-2.2l-1.7-0.6l-0.7-1.2l-0.5-0.8l0.1-0.3l0.5-2l0.2-0.4l1.1-1.7l0.5-0.1l1.6-0.3l1.3,0.1l0.7-1.1l0.1-0.2
		l-0.8-4.3l-0.3-1.6l-0.5-0.6l-2.1-3.5l-0.1-0.2l0-0.1l0.1-0.3l2.4-2.7l1.5-0.3l0.2-0.4l0.6-0.8l0.7-0.6l0.7,0.2h0.1l0.1-0.2
		l0.3-0.8l-0.2-0.4l-0.2-0.2l-0.8-0.5l-0.7-0.6l-1.4-1.6l-0.1-0.1l-3.4-1.7l-1,0l-3.2-0.1l-0.7-0.1l-0.7-0.4l-0.3-0.4l0-0.3l0.2-0.8
		l0.2-0.5l0-0.4l-0.1-0.2l-0.3-0.1l-3.3,0.7l-0.1-0.1l-0.2,0l-0.6,0.2l-0.1,0l-0.5,0.2l-0.1,0l-1.6,0h-0.2l-0.3-0.1l-0.5-0.3
		l0.9-7.2l1.8-5.6l1.2-3l0-3.4l-1.8-1.8l-0.6-0.3l-1.5-0.6L245,329l-0.2,0l-0.6,0.2l-0.3,0.2l-1,0.6l0.2-0.1l0.5-2.4l0.1-1l0-0.6
		l-0.3-1.3l-0.5-0.8l-0.1-0.1l-1.1-0.6l-0.3-0.1l-0.1,0l-1,0.2l-0.9-0.5l-5.7-4.1l-0.6-1.5l-0.3-6.2l0-1.8l0-0.2l0.5-0.7l0.5-0.3
		l0.1-0.1l-0.9-1.6l-1.3-1.2l0.3-2.2l-0.3-1l-0.5-0.6l-0.2-0.1l-0.9-0.3l-3.5-0.7l-3.5-2.7l-1.9-2.5l2.3,1.2l1.5,0l2-4.9l0.1-0.2
		l-2.5-1.7l-2-0.8L221,288l-0.3-0.1l-0.2-0.1l-0.5-1.6l2.3-4.8l0.7-0.5l1-0.7l1-0.5l1.2-0.2l0.8-0.5l0.8-0.7l0.1-0.1l0.8-1.5l1.5-3
		l0.2-0.5l0.1-0.7l-0.9-2.3l1.8-4.1l-0.1-0.2l-0.2-0.7l-2.7-1.2l-2.3-0.5l-1.6,2.1l-0.8,1.4l-2.1,2.8l-2.9,3.8l-0.2,0.1l-5.1,1.1
		l-6.7,0.1l-1.8-0.8l-0.5-0.4l-0.2-1.5l-0.2-7.6l0.3-1.3l0-0.3l-0.2-0.6l-0.7-0.7l-2.1-1.7L195,263l-3.1-1.5l-3.1,2.4l-0.1,1.4
		l-0.2,1.3l-0.1,0.3l-1.5,1.3l-1,0.1l-3.4,0.3l-0.5-0.5l0-0.2l-0.3-0.3l-2.4-0.6L179,267l-0.4,0.3l0.5,1.1l0.7,3.2l5.2,3.9l2.2,1.7
		l1.6,4.6l-0.1,1.8l0.2,3.3l-0.5,1l-1.1,4.5l0.1,0.2l0.5,1l0.8,0.7l0.3,0.1l0.4,0l1.2,0.4l1.2,0.6l-0.6,0.8l-0.5,0.6l-1,1.1
		l-0.2,0.2l-0.5,0.3l-0.4,0.1l-0.6,0l-0.2,0.1l-2.2,1.5l-0.4,0.4l-0.3,0.5l-0.1,0.3l0.1,0.6l0.2,0.7l-3-0.3l-2.4-0.9l-2.9-0.6
		l-0.6-0.1l-1.4,1l-1.7,1.6l-4,2.9l-1.6,0.4l-2.3,1.4l-0.3-0.6l-1.1-0.3l-1-0.1l-1.5-0.1l-0.2,0l-2.3,1.7l-0.4,0.5l-0.6,0.9
		l-1.1-0.4l-1.8-0.8l-1-0.5l-0.2-1l-1.4-2.3h-0.3l0-0.1l0.9-0.7l3.7-1.2l3.2-1l-0.2-4.1l-2-1.8l-2.3-0.4l-0.1,0l-1.2-0.9l-0.3-0.5
		l-0.3-1l-0.2-0.8l0-0.1l2.7-5.8l1.1-0.7l-1.4-1.4l-0.2-0.2l-0.1-0.6l0-0.5l0.1-0.4l2.1-2.2l-0.7-2.5l-1-1.7l-2.8-1.7l-1.6,0.4
		l-0.3,0.2l-1.3,0.6l-2.2-0.3l-5.1-3.1l-1.6-4.5l-0.5-1.1l-0.2-0.3l-0.2-0.2l-4.6-1.8l-0.2-0.1l-0.8,1l-0.6,1l-0.4,0.8l-0.5,1.5
		l0.7,0.5l1.4,0.7l-0.1,0.7l-1.8,3.2l-2.3,1.2l-0.3,0l-0.3,0l-1.7,1l-4.2,2.6l-3.7,2.7l-0.6,0.5l-0.3,0.7l-0.3,0.5l-1,0.5l-1,0.1
		l-0.8,0.1l-0.4,0.6l-0.9,1l-5-0.4l-1.3-0.1l-2.6-0.3l-2.1,0.5l-0.6,0.6l-0.2,0.3l-0.7,1l-2.1,0.1l-1.4-1.6l-0.6-1.6l-0.1-0.8
		l-0.1-0.8l0.1-0.5l1.4-3.1l0.7-1.5l1.1-1.4l0.1-2.1l-0.5-1.9l-4.1-7.8l-0.1-0.1l-0.3-0.2l-0.6,0.4l-0.6,1l-0.5,1.1l-1,0.7l-0.3,0.1
		l-10.4-2.9l-1.3-0.5l-3.4-2.5l0-0.3l0.4-2l0.2-1.2l0.8-2.9l0.4-4.1l-0.4-4l2.1-1.7l1.1-0.3l1.6-0.3l0.4,0l6.5,0.4l5.3,0.9l1.3,0.4
		l0.2,0.2l0,0.1l0.4,0.2l1.8,0.5l0.1,0l0.2-0.2l0.6-0.8l0.8-7.2l0.4-4.1l0.6-5.8l1.1-4l2.1-2.9l4.1-6.2l1.4-4.2l0.6-1.8l0.2-1.1
		l-0.5-6.9l0.3-3.4l-1.2-0.9l-0.1-0.8l0-0.8l0.9-2.4l0.8-1.4l0.9-2.1l0.4-5.5l1.1-4.7l0.7-2.1l0.1-0.3l0.1-0.1l0.5-0.2h2.9l-0.4-0.1
		l-2.3-0.6l-1.2-0.3l-0.9-0.1l-3.1-0.3l-7-0.2l-2-0.3l-0.4-0.2l-0.3-0.4l-0.7-1.4l-0.2-0.5l0.1-0.3l1-1.7l0.1-2.6l1.6-12.8l3.4,1.5
		l1,1.4l1.9-1.9l1.1-1.1l-1-1.8l-0.7-0.4l-1.6-1.1l-0.5-0.7l0.3-0.9l4.6-4.8l1.2-1.2l2.7-2l1.1-0.8l2.8-1l1.3-0.3l1.2-0.1l1.7-0.1
		l7.5,0.6l0.4,0.1l0.6,0.4l0.1,0.2l0.5,0.4l0.7,0.4l0.5,0l1.8-0.3l3.9-1.3l4.2-1.2l0.4-0.1l0.6-0.1l0.4,0l0,0.2l0.6,0.1l1.1,0
		l1.2-0.1l3.2-1l6.2-0.6l3.2,0.4l2.9,0.5l1,0.5l0.2,0.3l0,0.5l-0.1,0.2l-0.4,0.6l-0.1,0.7l0.3,2.3l0.2,0.9l0.5,1.4l2.9,0.4l0.3,0.3
		l0.5,0.8l0.6,1.1l3.4,7.9v0.1l-0.1,0.3l-0.1,0.4l-0.3,0.9l-0.3,0.6l-0.9,0.5l-0.9,0.3l-2.1,0.8l-0.4,0.1l-0.9,0l-1-0.8l0.1,2.1
		l0.1,0.9l0.1,0.5l0.2,0.7l0.3,0.7l0.6,0.8l0.8,0.9l0.4-0.2l0.8-0.6l0.2,0l0.9,0l0.7,0.2l1,1.3l1.2,1.8l0.6,1l0.1,0.1l0,0.2l0.2,0.4
		l0.1,0.1l0.6,0.3l1.1,0.3l0.8,0.1l0.4,0l0.6-0.3l0.5-0.4l0.2-0.2l0.7-0.8l0.6-0.8l0.5-0.8l0.7-1.7l0.3-0.8l0.1-0.8l0.2-3.7
		l-0.1-1.6l-0.1-0.2l-0.2-0.5l-0.1-0.2l-0.4-0.3l-1.6,0.6l-0.5,0.2l-2.1-0.4l0.5-4.2l0.3-1.7l0.1-0.3l0.4-0.9l0.3-0.5l0.9-0.9
		l0.9-0.7l1.3-0.5l1.2,0.1l1.4,0.9l0.4,0.6l0.7,1.4l0.9,1.4l1.1,0.6l3.7,1.7l0.2,0l0.4-0.1l0.5,0l1.4,0.2l0.8,0.1l1.9,1.2l0.1,0.1
		l0.1,0.3l0.2,1l0,0.1l-0.2,0.2l-0.3,0.3l0.3,0.5l1.4,1.8v0.2L198,161l-0.4,0.5l0.2,0.2l0.3,0.2l1.8,0.2l0.2,0l2.6-2.7l0.1-0.5
		l-0.9-3.9l1.1-5l-0.1-0.1l-1.7-0.3l-5.4-0.3L194.9,149.4z M222.5,199.2l-0.3-0.6l-1.4-1.7l-0.7-0.5l-0.3,0l-1.1,0.7l-4.7-0.1
		l-3.8-1.9l-4.2-2.1l-6.5-0.8l-7.5-4.1l-0.3-0.9l0-0.7l0,0.7l0.2,1.3l0.4,0.9l0.3,0.6l0.7,1.1l1.5,1.2l2.4,1.1l0.8,0.3l0.9,0.1
		l0.8,0.2l0.4,0.3l0.3,0.5l1.6,4.2l0.1,1.3l0.9,0.6l2,2.1l0.5,0.7l0.1,0.6l0.1,2.6l0.5,0.5l0.5,0.5l0.3,0.1l1.1-0.4l0.4-0.1l1.3,0
		l5.3,1.9l3.1,1.2l3.3-2.8l0.1-2l-0.4-4.8l-0.8-1.2l0.1-0.1l0.1-0.1l1-0.4L222.5,199.2z M155.6,131.3l-1-1.3l-0.1-0.1v-0.2l0.4-0.4
		l0.4-0.2l1.3-0.1l0.7,0l0.7,0.1l1.8,0.4l0.6,0.2l0.2,0.1l1.2,0.9l0.1,0.5l-0.2,0l-1.6-0.6l-3.8-1l-0.1,0l-0.1,0.1l-0.3,0.4
		L155.6,131.3z M164.9,134.5l-0.1,0l-0.3-0.1l-0.3-0.2l-0.2-0.2l-0.1-0.2l-0.4-1.7l0-0.4l0.7-0.9l0.4,0.4l0.4,2.1l0,1.1L164.9,134.5
		z M144.2,133.2l-0.5-0.1l-0.2-0.1l-0.2-0.4l0-0.4l0.3-0.6l0.6-0.8l0.3-0.3l0.3-0.2l0.6-0.2l1.1,0l1.5,0l2.4,0.2l0.4,0.1l1.4,0.4
		l0.4,0.4l0.1,0.1l-0.1,0l-3.7,0.7l-3.7-0.2L144.2,133.2z M131.5,136l-0.2,0l-0.4-0.3l-0.1-0.9l0-0.7l0.1-0.3l0.7-1l0.2-0.2l0.6-0.3
		l0.4-0.1l3.4,0l2.7,0.1l1,0.1l0.2,0.1l0.4,0.1l0.4,0.2l0.1,0.2l0,0.5l0,0.1l0,0l-0.8,0.1l0-0.1l0.1-0.1l0-0.1l-0.1,0l-2.1-0.1h-0.2
		l-1.6,0.1l-2.3,0.2l-0.3,0.1L131.5,136z M129.2,136.1l-1.4-0.1l-1.6-0.2l-1.4-0.3l-0.3-0.5l0-0.1l0.1-0.1l1.5-0.6l0.3-0.1h0.3
		l2.1,0.5l0.1,0.1l0.3,1.1L129.2,136.1L129.2,136.1z M111.8,137.7l-0.6-0.5l-0.3-0.4l-0.1-0.2l0.1-0.2l0.3-0.2l0.4-0.2l1.4-0.6
		l0.9-0.2l0.5-0.1l7.1,0l1.1,0l0.5,0.1l0.2,0.1l0.2,0.5l0,0.1l-0.9,0.3l-0.6,0.2l-7.7,1l-2,0.2L111.8,137.7z M96.3,140.4l-0.6,0
		l-0.1,0l-0.3-0.1l-0.5-0.3l-0.1-0.2l0.1-0.1l0.3-0.1l2.1-0.2l6.9-0.7h3.1l0.9,0.2l0.1,0.2l0.1,0.1l0,0.3l-0.2,0.2l-0.1-0.2l-0.1,0
		l-1.5-0.1l-0.6,0l-0.7,0l-3.5,0.1l-4.7,0.7L96.3,140.4L96.3,140.4z M86.6,150.9l-0.5-1.1l-0.3-0.2l-0.5,0l-0.4,0.2l-0.1,0.1v0.3
		l0.1,0.3l0.1,0.1l0,0l-0.1,0l-0.6-0.1l-2.6-2.1l-0.4-0.7l-0.2-0.9l0.1-0.4l0.2-0.3l0.1-0.1l0.3-0.1l5.2-1.6l0.3,0l0.6,0.1l0.7,0.3
		l1.8,1.3l0.5,0.6l0.1,0.2l-5.3,1.5l1.3,1.1l0.7,0.8l-0.1,0.1l-0.6,0.4L86.6,150.9z"
        ></path>
        <path
          id="Mecklenburg-Vorpommern"
          class="st0 available"
          d="M340,114l4.6-3.1l3-1.8l1-0.4l0.4-0.1l2.2-0.5l0.7-0.1l4.9-0.2l0.3,0.2l0.4,0.4
		l3.4,3.8l-0.2,1.9l0,0.5l0.3,0.8l0.6,0.6l0.6,0.3l0.5,0.2l0.5-0.1l0.1-0.1l1.2-0.8l0.5-0.3l0.2-0.3l1.6-0.4l2.4,1l1.3,0.6l-0.1,0.9
		l0.1,0.2l0.6,0.5l1.3,0.8l0.8,0.4l1.5-2.5l0.3-1l0.6-5.6l0.9-1.8l0.1-0.2l0.4-0.4l1.8-1l0.9-0.3l0.3-0.2l0.5-0.4l0.2-0.2l2.8-4.3
		l0.1-1.2l-0.1-0.3l-0.6-1l-0.2-0.2h-0.2l-0.3,0.3l-1.3,1.3l-0.6,1l-0.8,1l-0.8,0.9l-1.7,2.1l-0.1,0l-0.1-0.1l-0.1-0.9l0-0.5
		l0.1-0.5l0.6-1.2l1.7-2.4l7.6-6.2l0.2-0.1l0.5-0.1l0.6,0l3.4-0.1l0.2,0l0.6,0.3l0.7,0.2l1,0.2l1.2,0.1l1,0l0.9-0.1l0.6-0.1l1.3-0.4
		l7-2.8l4.1,0.6l2.9,0.4l1.5-3.4l2-3.8l0.3-0.5l1-0.9l1.6-1.1l3.5-2.2l1.1-0.7l0.5-0.4l0.5-0.4l0.6-0.6l0.5-0.6l0.6-0.9l4.2-7.9
		l2.7-5.4l2.2-3.8l1.8,1.4l0.8,0.4l0.5,0.1l4.1,0.5l3.6,0.4l7.3-0.2L458,61l1.3-0.2l1.8,0l1.3,0.1l0.1,0.1l0.1,0.1l0,0.2l-0.1,0.1
		l-2.6,1.7l-0.4,0.2l-1.9,0.5l-4.2,0.3l-2.9,0.1l-1-0.1l-0.1,0l-0.2-0.2l-0.4-0.7l-0.3-0.7l-0.3-0.1h-0.1l-1.5,0.1l-3.3,0.8
		l-3.1,1.9l-8.9,3.3l-0.9,0.8l-2.7,6.4l-0.3,1.9l0,2.5l0,0.1l2.2,1.5l1.1,0.4l1.2,0.1l1-0.2l0.4-0.2l0-0.2l-0.3-0.2l-0.3-0.2
		l-1.2-0.3h-0.5l-0.2-0.1l-0.6-1.2l-0.1-0.9l0.4-1l3.2-3.6l4.7-3.6l6-2.9l5.1,1l4.7,2.1l2-0.4l0.4-0.2l1.4-1.2L458,69l0.2-0.4
		l0.1-0.5l0-1l0.6-1.5l0.1-0.1l1.4-1.2l0.2-0.2l3.8-1.4l2.2,0.2l4.1,6l-0.8,1.8l0.2,0.8l1.7,5.6l2.5,2.8l1.7-1l0.7-0.3h0.2l6.1,2.9
		l0.1,0.1l0.4,0.7l1.6,3.3l0.3,0.6l0.2,0.4l0.2,0.1l0.2,0l0.3,0.6l2.2,0l2.2,0.8l0,0.1l-1.7,1.5l0.1,0.7l0.2,0.2l0.3,0l0.5-0.3
		l0.2-0.2l0.2,0l1.2,0.8l0.1,0.1l0.1,0.3l0.7,1.4l0,0.9l0.1,0.3l1.1,1.8l0.4,0.3l0.4,0.2l1.5,0.3l0.2-0.1l0.2-0.2l0-0.1l-0.1-0.2
		l-0.4-0.6l-0.5-0.7L495,93l0-0.2l0.9-0.8l0.3-0.1l8.8-2.9l3.5-1.1l3.9,3.9l0.6,0.7l0.5,0.9l0.2,0.7l0,0.4l0,4.2l3.3,6.8l3.9,4.1
		l0.7,1l0.4,0.7l0.1,0.3l0,0.2l-0.4,0.6l-0.3,0.3l-0.1,0.1l-0.2-0.1l-0.4,0l-0.4,0.2l-0.3,0.3l-0.8,0.9l-1.8,2.1l-1.5,2.1l0.1,0.4
		l0.6,1.2l0.2,0.2l5.8,3.6l2.1,1.5l1.6,1.4l0.4,0.2l6.8,2.4l1.3,0.4l1,0.2l3.4,0.1l0.3,0l0.2-0.1l1.1-0.9l0.8-0.9l0.4-0.6l0.1-0.1
		l0.4,0l0.7,0.2l0.4,0.2l0.8,0.7l0.4,1.1l1.1,5.7l2,6.4l0.4,3.8l-0.4,2.8l0,0.1l0.3,1l0.7,2l0.8,2.1l0.2,0.1l1.6,2.3l0.4,2.2
		l1.2,4.9l2.5,7.3l0.3,0.7l-0.4-0.1l-0.3,0l-5,2.1l-0.2,0.9l-0.6,1.8l-2,2.8l-0.8,0.3l-2.5,0.4l-5.8-0.2l-1.1-0.1l0.2-1.2l0.1-0.5
		l0.5-0.8l1.6-1.9l0.7-0.7l1-1.1l1.1-1.3l0.8-1l1-1.6l0.4-1l0.1-0.5l0.5-2l0-0.5l-0.2-2.8l-0.4-0.7l-0.7-0.5l-6.3-0.4l-0.7,1.4
		l-3.9,0.1l-2.7-0.4l-3.4,0.6l-0.6,0.3l-1.6-0.7l-1.5-3.2l-1-3.3l-5-5.8l-0.5,0.1l-0.4,0.6l-0.1,0.3l-0.3,1.9l0,0.3l0.2,1.4l1.4,1.2
		l-2.7,2.4l-2.7,0.2l-1.7,1.4l-1,0.9l-6.6,6.3l-0.2,0.5l-1.2,2.8l-0.5,1.6l0.2,1.3l0.3,1.8l0,0.1l-0.1,0.1l-2.1,2.9l-4.7,5l-0.7,0.3
		l-0.4-0.1l-1.1-1.6l-0.1-0.9l-0.1-0.2l-0.4-0.5l-0.2-0.1l-3.5-0.1l-1.1,0.5l-1.8,1.6l-0.4,0.5l-0.2,0.8l-0.1,1.2l0,0.5l-0.1,0.7
		l-0.5,0.9l-1.5-0.2l-0.2-0.2l-0.8-1.8l-0.2-0.6l-0.3-0.6l-1.9,0.3l-0.2,0l-2.3,1.1l-0.4,0.4l-7,7.6l-0.3,0.2l-0.2,0l-2.1-0.9
		l-0.1-0.1l0-0.1l0.3-0.4l0.9-0.7l1-0.6l0-0.2l-0.2-0.5l0,0l-0.7-0.2l-7.3,0.2l-1.8,0.6l-0.1,0.1l0,0.1l-1.2,0.4l-0.8,0.3l-1.2-0.1
		l-0.4-0.7l-2.5-2.6l-3.4-3.2l-0.8-0.1l-7.7-0.7l-1.8,0.4l0,0.5l-0.2,0.2l-3.3,0.4l-2.5-2.8l-0.5-1.4l-1-0.8l-1.9-1l-0.7-0.4
		l-0.6-0.2l-0.6-0.4l-2-0.6l-3.9-3l-0.3,0l-5.5,1.1l-1.5,0.5l-1.7-0.2l-1.5-0.3l-2.1,3l0.2,1.8l-0.4,0.5l-3.7,2.7l-0.4,0.2l-5,1.8
		l-3.2,0.2l1.1,1.9l0.1,0.3l0,0.1l-0.2,0.2l-5.5,1.2l-0.6,0.1l-0.7-1.5l-1.7-1l-1-0.1l-2.7,0l-0.6,0.4l-3.9,3.5l0.7,4.1l-0.6,2.5
		l-0.3,1l-0.9,0.6l-1.5-0.1l-1.6-0.3l-0.6-0.3l0-0.3l-0.8-0.6l-3.4,0.4l-2.8,2.2l-5.6-0.3l-0.2-0.3l-0.2-0.3l-0.9-1l-0.6-0.4
		l-0.6-0.3l-3.6-1.4l0.5-0.2l0.6-0.6l0.1-0.2l0.3-0.9l0-0.4l-0.1-0.2l-0.8-0.4l-0.6-0.1l-0.9-0.2l-1.2-0.3l-2.9-2.1l-0.7-0.7
		l-1.1-1.6l-2.3-4.4L346,178l-1.8-0.5l-2.6-0.7l-1.4,1.1l-0.4,0.8l0,0.1l0,0.3l-1.1,0.7l-1.1,0.7l-0.9,0.5l-2.7-1l-0.7-0.4l-1.1-1
		L332,178l-0.5-0.7l-1.2-1.5l-0.6-0.6l-1-0.8l-0.2-0.1l-0.2-0.1l-4,0l-1.1,0.1l-0.7,0.2l-0.7,0.4l1.8-8.1l0.6-1.3l1-0.6l0.5,0
		l0.6,0.3l1.4,0.3l3.8-2.9l0.3-0.3l1-1.1l2.1-2.5l6.6-8.7l0.4-2.4l1.4-1.9l-0.7-3.8l-1.3-1.4l-0.9-0.4l-1.1-0.2l-0.2,0l-0.2,0.1
		l0,0.2l-0.4,0.1l-1.3,0.1l-0.2-0.1l-1.5-0.9l-3.8-3.7l0.4-3.1l0.2-1.5l-0.1-0.5l-0.3-0.9l-0.2-0.4l-1-2.5l0-1.8l1.4-2.3l5.2-3.7
		l2.6,0.2l0.9-2.1L340,114z M481,36.3l-0.6,0.4l-1.8,1.4l-1.3,1.5l-0.2,0.3l-3,7.5l-0.1,0.2l-0.1,0.9l0.1,0l0.5-0.2l0.2-0.2l3-2.9
		l0.7-1.7l-0.2-0.6l-0.3-0.9l0-0.1l0.1-0.1l1.7-2.3l1-0.3l0.1,0l0.2,0.4l0.2,1.2l0,0.3l-0.1,0.6l-0.3,1l-0.7,1.3l-0.3,0.4l-0.2,0.6
		l-0.4,2l0,1l2.8-1.3l2.1-1.2l6.8,2.8l0.2,0l0.1,0l1.9-0.2l1.9-0.8l0.1,5.2l-0.1,2.8l-0.1,0.1l-0.4,0l-1.7,0l-2.7-0.9l-0.7-0.3
		l-4-2.3l-0.2-0.3l0-0.3l0.3-3.1l-3.1,0.1l-3.3,0.1l-1.1,0.3l-3.3,0.9l-0.9,0.5l0.6,2.7l0.5,0.9l2-0.1l1.6,0.1l2.5,2.3l-0.9,1.6
		l-0.2,0.1l-2.2,0.8l-1.2,0.2l-1.3,0.3l-1.2,2.6l0,0.5l0,0.1l0.3,0.2l5.3,1.2l0.7,0.1l-1.4,0.9l-5.9,2.9l-0.2,0.2l-0.1,0.4l-0.4,2.5
		l0,1l1.7,5L474,77l15.5,5.2l1.3-1l0.3-0.3l0.1-0.3l0-0.1l-0.1-0.6l-0.4-0.9l-0.9-1l-0.1,0h-0.6l-0.2,0.2l-0.1,0.2l0.1,0.2l0,0.1
		l-0.6,0.6l-0.8,0.4l-0.4,0l-0.4-0.4l0-1.2l4.2-4.4l3.3-3.2l1.1-0.8l0.3-0.1l10-1.2l0.4,0.1l1.4,2.3l0,0.1l0,0.5l-0.2,0.4l-0.1,0.1
		l-0.8,0.6l-0.1,0l-0.9,0.5l0,1.5l2.1-0.6l0.9-1.8l1.2-2.5L509,68l-1.8-2.9l-1.3-1l-1.3-0.9l-0.1,0.1l-0.2,0.1l-0.6,0.1l-1.2,0.1
		l-0.2,0l-0.3-0.2l-0.4-0.4l-1-1.2l-1.1-2.1l-0.3-0.7l-0.3-1.1l-0.1-1.5l0.1-0.6l0.2-0.3l0.2-0.2l0.1-0.1l1.6-1.8l2.1-1.8l0.6-0.3
		l0.2,0l0.6-0.4l0.6-1.2l0.1-1.1l-0.2-2.1l-0.4-0.5l-0.7-0.6l-1.4-0.9l-0.3-0.1h-0.5l-1.8,0.2l-0.4,0l-2.3,0.5l-0.3,0.1l-2,0.6
		l-2.9,0.5l-0.6,0l-1-0.3l-0.4-0.1l-0.5-0.3l-0.3-0.2l-0.8-0.8l-0.7-1l-0.5-1.1l-0.4-1.5l-0.1-0.9l0-0.3l0.3-1l0.3-0.5l0.2-0.1
		l0.5-0.3l0.4-0.1l0.4,0l0.3-0.1l0.4-0.4l0.2-0.5l0.4-1.4l0-0.1l-0.4-0.5l-0.2-0.3l-3,0.3l-4.5,1.3L481,36.3z M468.6,58.5l0.1,0.3
		h0.1l0.2-0.3l0.2-0.7l1.1-6.1l0.8-3.9l2.3-1.2l-3-1.1L468.5,56l-0.1,1l0,0.5l0.1,0.3L468.6,58.5z M473.4,60l4.3-2.3l0.5-0.4
		l0.1-0.1l-0.3-0.9l-0.3-0.3l-1.3-0.6l-0.2-0.1l-0.7-0.1l-0.7,0.2l-0.7,0.4l-0.8,1.1l-0.2,0.4l-1.1,2.5l0,0.7l0.1,0.2l0.1,0.1
		L473.4,60z M463.8,60.9l-0.1,0l-0.2,0l-0.2,0.1l-0.2,0.1l-0.1,0.1l0,0.1l0.3,0.3l0.2,0.2l0.7,0l3.2-1.1l0.7-0.6l0.4-0.4l-0.5-0.5
		l-0.2,0.1l-2.8,1.1l-0.4,0.4l-0.5,0.6L463.8,60.9z M515.1,86.9l-0.5-0.6l-0.2-0.2l-0.5-0.1l-0.3,0l-0.7,0.2l-0.7,0.4l-0.5,0.4
		l-0.1,0.2l-0.2,0.4l0,1.3l0.3,1.1l0.2,0.3l2,2.2l0.2,0.1l0.5,5l3.7,5.8l0.1,0.2l0.2,0.1l0.2,0l3.8-1.8l0,0l0.4-1.1l0-0.1l-1.3-1.9
		l-0.4-1.6l0-0.1l0.1-0.1l3.5,0.3l1.5,1.3l2.7,3l0.3,0.3l0.2,0.4l0.4,1.1l-0.1,5.1l-3.6-0.6l-0.7-1.5l-0.6-2l-0.2-0.1l-0.5-0.2
		l-0.7-0.1l-1.9,0.5l-0.1,0.1l-0.1,0.1l-0.4,0.8l-0.2,1.4l0,0.2l0.2,0.4l0.3,0.3l1.2,1.5l0.4,0.9l0.8,2.3l0,0.5l-0.4,1.1l-0.3,0.8
		l-0.2,0.2l-1.1,0.6l-1.3,0.7l-1.3,0.6l-0.4,0.1l-0.4,0l-0.3,0l-0.8,1.5l0.7,0.7l1.2,0.5l1.4,0.3l1-0.1l2.6-0.5l2.7-0.8l0.3-0.1
		l4.2-2.1l7.5-0.8l0.3,0l0.2,0l1.3-6l-3.9-3.2l-4.3-3.9l-5.1-4.8l-1.1-0.6l-1.5-0.8l-0.4-0.2l-0.3-0.1l-1-0.2l-1-0.4l-1.8-1
		l-0.5-0.3l-1.1-0.9l-1.1-1.1l-0.8-0.9l-0.2-0.4L515.1,86.9z M374.9,111l0.7-2.2l0.2-1.2l-0.1-0.5l-0.1-0.2l-0.3-0.1l-0.9-0.2
		l-1.1,0.2l-0.3,0.1l-0.5,0.2l-2.3,1.2l-1.5,1.1l-0.3,0.6l0.1,1l0.1,0.7l0.2,0.5l0.2,0.3l0.4,0.3l0.4,0.1l3.5,0.7L374.9,111z"
        ></path>
        <path
          id="Hessen"
          class="st0 available"
          d="M280.5,379.1l-0.2,0.9l0.1,1l0.6,2.2l1.4,3.4l1.2,1.5l3.4,1.2l1.9,0.8l0.9,2.9l4.6,2.3l1.7,0.6
		l0.8,0.5l0.6,0.5l1.3,1.3l1.2,0.3l0.3,0.6l-0.3,0.8l-0.6,0.4l-0.4,0.2l-0.3,0.3l-0.4,1.3l0,0.3l0.4,0.5l0.2,0l0,1.8l0,0.8l-0.2,0.2
		h-0.6l-0.4-0.7l0,0l-0.3-0.4l-0.4-1.3l-1.1-1.1l-2.6,0.6l-0.5,0.6l0.1,0.3l1.3,2.9l0.6,1.2l0.2,4.3l1.8,0.7l0.9,0.6l0.2,0.2
		l0.5,0.5l0.5,0.9l-0.2,0.5l-0.6,1.2l0.1,0.2l-0.5,0.7l-0.3,0.2l-0.5,0.2l-0.2,0l-0.2,0.2l-1.7,0.2l-0.4,0.1l-0.5-0.1l-0.3-0.2
		l-0.3-0.4l-1.3-0.7l-2.3-0.3l-1.4-0.1l-0.9,0.1l-0.3,0.1l-0.1,0.1l-0.1,0.3l-0.6,1.3l1.5,4.8l1,5.9l0,0.8l-1.6,4.1l-0.7,1.2l-1.4,0
		l-1.6-0.2l-0.5,0.3l-1.1,0.9l-0.1,0.3l-0.4,1.8l0,0.6l0.4,0.7l0.1,1l0,0.5l-0.1,0.1l-0.3,0.1l-1.3,0.4l0.9,1.1l0.2,1.5v0.3
		l-0.5,1.2l-1.2,2.7l-1.4,1.7l-0.7,1l-0.3,0.4l-0.1,2.7l0.1,0.9l3.3,0.6l1.1,0.2l0.9-0.2l0.5-1.2l-1.2-1.5l0-0.6l0.2-0.3l2.4-1
		l0.5-0.1l2.2,0.1l1.3,0.1l0.3,0.2l0.8,1.4l0.7,2.1l-1.8,1.5l-0.2,0.2l-0.4,0.6l-0.2,0.4l0.2,9.7l0,0.8l-0.3,1.6l-0.5,1.3l-0.9,1.4
		l-3.5,3.8l-1.6,0.9l-1.2,1l-1.2,0.7l-1.2,0.5l-1.5,0.2l-2.2-0.9l-0.8-0.6l-3.6,1l-0.3,1.6l-0.6,2.1l-0.2,0.3l-0.7,0.6l0.7,1
		l0.3,0.6l0.1,1.6l-0.1,1.1l-0.1,0.4l-0.4,0.8l-0.8,0.8l-1.6,1.5l-0.7,0.3l-0.1,0h-0.7l-1.7,0.6l-0.1,0.2l-0.3,0.5l-0.7,1.3
		l-0.9,2.4l-0.1,0.4l-0.1,0.1l-1.6,0.1l-0.8,0l-3.1-0.8l-1.1-0.4l-1.2,2.5l-0.4,3.4l0.7,4.1l0.2,1.2l0.4,1v0.8l-0.4,1.2l-5.1,0.9
		l-1.3,0.1h-0.2l-0.5-0.4l-1.9-4.3l-0.1-0.1l-0.3-0.1l-9.7-1.9l-13.5,3.7l-0.1,0.1l-0.5,0.6l-0.7,1.2l-0.5,2.5l-0.6,1.3l1.3,1.1
		l0.3-0.1l0.6,0l0.5,0.2l0.1,0.1l0.3,0.3l0.1,0.3l0.4,3.9l-1.6,0.9l0.3,0.5l0,0.2l0.4,3l0.1,2.9l0.1,2.1l0.4,3l0.1,0.2l0.7,1
		l-0.9-0.1l-0.2,0.1l0,0.6l0.1,1.4l0.1,0.4l0.3,0.3l4.4,4.4l0.4,0l0.8,0.2l0.2,0.1l0.2,0.1l1.2,5.3l-1.6,4.3l-0.5,0.2l-1.4,3.7
		l-0.8,7l0.9,1.6l-0.2,1.6l0.1,0.1l-0.4,1l-0.1,0.2l-0.5,0.4l1.6,0.9l0.3,0.3l0.6,1.4l0,0.4l-0.3,0.4l-0.9,0.2l-1.5-1.7l-1.1-0.3
		l-5.6,1.8l-0.2,0.1l-1,0.7l-1.6,1.8l-0.4,0.7l-0.2,0.6l0.7,0.7l0.6,0.8l-6.5,5.9l-0.3,0.2l-0.1,0l-1.9-0.1l-0.5-0.1l-0.6-0.6
		L205,580l0.4-0.9l1.8-3.7l2.3-0.8l2.3-2.1l-0.2-1.2l0.2-0.6h-2.4l-6.5-1.3l-0.5-0.2l-2.3-1l-1.5-1l-1.9-7.6l0.4-0.2l0.1,0.2
		l0.4,0.2l0.1-0.1l0.5-1l-0.1-0.5l-1-0.2l-3.9,1l-1.7,0.5l-0.3,0.6l0,0.8l0.2,0.4l0.6-0.1l0.9,4.1v0.5l-0.1,0.5l-0.7,1.1l-1.7,1.4
		l-0.9,0l-0.3-0.1l-0.5-0.2l-0.9-0.7l-0.6-0.9l-0.4-1.2l-2-2.6l-1.5-1.3l-0.6-0.5l-1-0.1l-0.3,0l-0.3,0.1l-1,0.5l-0.2,0.1l-0.5-1.2
		l-1.6-2.4l-0.5-0.9l-1.6-5.1l-0.1-0.5l0.2-1l0.1-0.2l0.2-0.4l0.1-0.1l0.6-0.5l0.6-0.3l0.5-0.2l0.7-0.3l1.5-0.3l0.4-0.2l0.4-0.2
		l0.5-0.4l0.4-0.6l0.1-0.2l0.1-0.3l0.6-1l-0.3-2.3l-2.8-2.9l-0.5-1l-1-1.4l-0.3-0.5l-2.2-5.6l-0.2-0.8v-0.4l0.1-0.5l0.1-0.3l0.3-0.6
		l0.3-0.6l0.1-0.3l0-0.5l-0.1-0.5l-0.6-1.9l0-1l-0.5-1.2l-0.5-0.8l-0.4-0.6l-0.7-0.8l-1.3-1.2l-0.8-1.2l-0.4-0.6l-0.7-0.8l-1.2-1.1
		l-0.6-0.2l-1.9-0.4l-1.1-0.1l-0.9,0.1l-2.5,0.8l-4.1,1.9l-7,2.7l-0.3,0.1l-2,0.3l-3.3,0.1l-0.4,0l-0.3-0.1l-0.5-0.3l-5.4-7.6
		l-0.7-1.9l0.9-0.2l2-1.1l4.2-3.5l0.4,0.1l2.3,0.6l0.3-0.4l0.4-3l-0.1-0.4l-1-0.9l-0.8-0.6l-0.5-0.3l-0.4-0.5l-0.1-1.3l0.1-0.1
		l1.5-2l0.6-0.4l0.3-0.2l4.3-1.9l1.8,1.1l1,0.2l0.3,0l1.7-1.9l-0.1-0.2l-0.4-0.3l-1.7-1.6l-0.2-0.5l1-1.3l0.9-0.3l0.7,0.1l2.1,0.9
		l2-1.2l0-1.9l-0.1-0.4l-0.7-2.2l-0.1-0.2l-0.2-0.4l-2.7-4.9l-5.3-3.5l-0.5,0l0.3-0.8l1.8-4.9l-0.7-1l-0.5-1.3l0-0.3l0.5-2.4
		l0.5-1.1l2.4-2.8l0.4-0.1l0.6-0.2l0.3,0l1.2,0.5l0.5,1l1,0.3l1.1,0.2l0.8-0.1l0.2-0.4l0.8-1.9l0.3-0.7l0.7-1.8l0.4-1.2l0-0.6
		l-1.2-0.7l-0.4-0.2l-0.1-0.3l-0.9-4.9l0-0.9l0.1-0.5l0.1-0.4l0.9-1l0.7-1l2-3.7l0.1-0.3l-0.2-1l-0.8-1.1l-0.8-1.5l-0.4-0.8l-0.4-1
		l9.5-9.3l0.2-0.2l0.2,0l1,0l0.2,0.1l4.1,2.2l0.2,0.1l0.5-0.1l0.1-0.1l1.1-1.2l4.3-6.5l0.6-2.6l0.1-0.1l0.4-0.2l0.1-0.1l0.6,0
		l0.2,0.1l-0.3-0.4l0-0.2l0.1-0.2l0.9-0.9l1.4-1.6l1-0.9l0.4-0.4l0.5-0.7l-0.4-0.9l-0.7-1.5l-1.1-3.3l-0.1-0.3v-0.2l0.4-0.6l0.5-0.5
		l0.8-0.6l0.1-0.1l1.3-0.5l0.1-0.1l2.8,0.2l1.7,0.3l0.8,0.3l1.1,0.3l0.4,0l3.2-1.2l2.8-6.2l0.3-0.7l0.1-0.4l-0.2-2l-0.8-6.4
		l-0.1-0.3l-0.9-1.2l-0.9,0l-0.7,0.1l-4.6,2.1l-1,0.7l-0.1,0l-0.8,0l-0.3-0.1l-0.2-0.2l-2.1-2.9l0.6-0.9l2.6-3.8l1.1-1.4l1.1-0.7
		l3-1.9l5-1.5l3.8-1l4.2-0.1l3.4,0.2l0.1-0.1l0.8-2.5l-0.2-1.4l0-0.2l-0.3-0.2L217,373l-2.5-4.1l-0.2-0.7v-0.4l0.7-0.7l2.9-1.2
		l1.6-0.5l2.2-0.5l1.2-0.1l2.9,1.2l1.2,2l-0.7,1.8l0.6,1.6l0.2,0.2l0.3,0.2l0.6,0.2l2.8,0.2l0.6-0.1l0.6-1l0.6-1l4.3-3.5l4.5-3.5
		l3.2-5.3l3.8-4.6l1.3-2.2l3.3-0.7l0.3,0.1l0.1,0.2l0,0.4l-0.2,0.5l-0.2,0.8l0,0.3l0.3,0.4l0.7,0.4l0.7,0.1l3.2,0.1l1,0l3.4,1.7
		l0.1,0.1l1.4,1.6l0.7,0.6l0.8,0.5l0.2,0.2l0.2,0.4l-0.3,0.8l-0.1,0.2h-0.1l-0.7-0.2l-0.7,0.6l-0.6,0.8l-0.2,0.4l-1.5,0.3l-2.4,2.7
		l-0.1,0.3l0,0.1l0.1,0.2l2.1,3.5l0.6,0.6l0.3,1.6l0.8,4.3l-0.1,0.2l-0.7,1.1l-1.3-0.1l-1.6,0.3l-0.5,0.1l-1.1,1.7l-0.2,0.4l-0.5,2
		l-0.1,0.3l0.5,0.8l0.7,1.2l1.7,0.6l5.3,2.2l3.4,1.7l0.1,0.2l2.3-1.2l0.3-1.2l0.1-0.5l-0.1-1.4l-0.1-0.1l-0.5,0l-1.7,0.8l-1.8-2.8
		l1.1-1.5l4.4-3.7l0.4-0.3l0.2,0l3.2-0.5l3.1-0.4l0.3,0.2l1.3,2l0.3,0.6l0,1.2l0,0.1L280.5,379.1z"
        ></path>
        <path
          id="Hamburg"
          class="st0 available"
          d="M268.8,155.3l0.4-2.3l0.4-1.6l0.3-0.9l0.7-2.1l0.2-0.3l0.4,0l-0.1,0.5l0.1,0.7l1.7,2.1l1.1,1
		l0.5,0.2l0.8-0.4l3.5-4.4l0.4-0.6l0.3-0.6l0-0.3l0.1-0.8l1.1-0.1l1.2,0l0.2,0.2l1,0.2l0.5,0.1h0.8l4.9-3.8l0.8-1.9l-0.5-0.6
		l1.4-0.9l4.3-2.1l0.3,0l0.2,0.1l1.3,0.7l-0.1,0.9l-0.6,1.4l0.1,4.7l0.8,0.9l1.6,2.2l-0.2,0.7l0,0.1l-1.3,2.7l-2.1,1.7l-0.7,0.6
		l-0.3,1.2l0.1,2.1l0.9,2.4l0.2,0.2l3.5,2.4l0.4,0.1l0.5-0.1l-0.1,0.6l0.2,0.8l3.7,4l0.5,1l-0.1,0l-0.2,0.1l-2.2,1.2l-0.4,0.2
		l-0.2,0.3l-0.2,0.3l-0.6,1.5l-0.2,0.3l-0.2,0.1l-0.4,0.1l-3.2-0.1l-0.4-0.1l-0.4-0.3l-2.5-2.4l-1.8-2l-0.4-0.5l-0.5-0.6l-1.8-1
		l-0.6,0.7l-0.6,1.3l-0.2,0.9l-2.8,2.1l-0.4,0l-1.6-1.3l-2.1-2l-0.7-0.9l-0.3-0.2l-0.3,0.2l-1.3,1.4l-0.8,0.6l-0.3,0.1l-3.4-3.5
		l-0.1-0.6l-1-2.2l-0.8-0.9l0.3-2.2v-2.8L268.8,155.3z"
        ></path>
        <path
          id="Bremen"
          class="st0 available"
          d="M196.4,158.2l0.6-0.4l0.2-0.5l0.1-0.3l0-0.8l-0.1-0.3l-0.1-0.3l-2.1-3.8l-0.9-1.3l1-1.2l0.9-0.1
		l5.4,0.3l1.7,0.3l0.1,0.1l-1.1,5l0.9,3.9l-0.1,0.5l-2.6,2.7l-0.2,0l-1.8-0.2l-0.3-0.2l-0.2-0.2l0.4-0.5l0.1-0.2v-0.2l-1.4-1.8
		L196.4,158.2z M222.2,198.6l-1.4-1.7l-0.7-0.5l-0.3,0l-1.1,0.7l-4.7-0.1l-3.8-1.9l-4.2-2.1l-6.5-0.8l-7.5-4.1l-0.3-0.9l0-0.7l0,0.7
		l0.2,1.3l0.4,0.9l0.3,0.6l0.7,1.1l1.6,1.2l2.4,1.1l0.8,0.3l0.9,0.1l0.8,0.2l0.4,0.3l0.3,0.5l1.6,4.2l0.1,1.3l0.9,0.6l2,2.1l0.5,0.7
		l0.1,0.6l0.1,2.6l0.5,0.5l0.5,0.5l0.3,0.1l1.1-0.4l0.4-0.1l1.3,0l5.3,1.9l3.1,1.2l3.3-2.8l0.1-2l-0.4-4.8l-0.8-1.2l0.1-0.1l0.1-0.1
		l1-0.4l1-0.4L222.2,198.6z"
        ></path>
        <path
          id="Brandenburg"
          class="st0 available"
          d="M556.2,170.7l-0.2,2.1l1.1,3.3l0.8,0l0.6,0.1l0.3,0.9l0.1,0.5l-1,2.2l-2.4,3.7l-0.5,0.2
		l-0.2,0.1l-0.3,2.1l-0.1,1l0,0.2l0.3,1.1l0.1,0.4l1,1.4l-0.3,2.6l-0.1,0.5l-0.5,3.2l-0.7,2.3l-0.6,1l-0.3,0.3l-3.3,3.4l-1.2,1.2
		l-1.7,1.4l-0.9,0.5l-0.8,0.3l-2.1,1.4l-1.5,1.4l-0.3,0.9l0,0.2l0.3,1.7l1.1,3.9l0.3,0.7l-0.1,1.3l-0.7,1.2l-0.8,1.1l-0.5,1.1l0,0.6
		l0.1,0.2l0.4,0.6l0.2,0.2l0.7,0.3l1.8,0.1l3.7,1.5l7.8,5.1l1.5,1.5l1.6,1.9l1.7,2l0.1,0.1l0.2,0.4l0.2,0.7l2.1,2l1,0.7l1.8,1.2
		l0.8,0.4l1.4,0.7l0.9,0.4l0.3,0.1l0.5,0.1l0.3,0.2l1.7,1.2l0.4,0.5l2.3,3l0.2,0.3l0.2,7.8l-0.1,0.7l-0.1,0.3l-1.7,3l-0.8,1
		l-1.6,1.1l-0.3,0.2l-0.3,0.4l-0.2,0.5l-0.1,0.5l-0.4,2.3l-0.1,0.8l1,1.6l0.3,0.6l0.3,2.7l1.5,5.6l0.1,0.3l0.2,0.2l0.9,1.1l0.2,0.2
		l0.4,0.2l0.7,0l0.3,0.1l4.7,1.2l0.1,0l0.3,0.2l0.2,0.3l1,1.4l0.1,0.2l0,0.2l-0.2,6.4l-0.1,0.4l-0.5,0.9l-0.8,1l-0.1,0.3l0,0.3
		l0.3,2.6l0.4,0.8l0.3,0.4l0.5,0.5l0.2,0.1l0.7,0.1l0.6,0.2l0.4,0.2l0.6,0.4l0.4,0.4l0.9,1.5l0,0.3l-0.4,3.3l-0.1,0.1l-0.4,0.7
		l-1.4,2.4l0.1,0.1l0.3,0.6l-0.2,6l-1,3.4l-2.9,4l-1.2,1l-1.7,1.8l-0.4,0.4v0.1l0,1.2l0.1,0.5l0.8,1.3l0.2,0.3l0.2,0.1l1,0.3
		l2.9,6.3l0.5,1.1l1.5,1.7l2.3,1.7l0.3,0.2l0.7,0.6l0.6,0.7l0.7,1.4l0.5,4.2l-0.8,2.7l-0.9,1.4l-1-0.8l-0.8,0.8l-0.7,2.3l-0.1,0.3
		l-0.2,0.2l-0.5,0.5l-0.2,0.1l-3.2,0.3l-0.5-0.1l-0.4-0.1l-0.1-0.2l-0.4-0.8l-0.1-0.7l-2.2-0.9l-6.9,2.5l-0.4,0.3l-0.1,0.4l0,0.4
		l0.1,0.5l-0.3-0.2l-3.4,0.4l-4.3,2.5l-2.9-1l-4-0.5l-3.1,0.1l-1.3-0.2l-0.4,0.1l-2.1,3.1l-1.5,4.4l-1.4,5.5l-2.7,3.5l-7.5,1.5
		l-0.3,0.1l-2.8-0.1l-1.6,0.6l-2.1,1l-0.8,0.6l-3.1-0.4l-0.5-0.1l-0.8-0.5l-0.3-0.4l-0.5,0.7l-3.3,0.1l-2,0.1l-0.4,0l-2.9-0.9
		l-4.9-3l-2.1-1.7l-2.3-2.1l-0.6-0.5l-4.5,2.4l-2.6,2.3l0,0.1l0.1,1.1l-1.7,0.5l-0.5,0.1l-2.3-0.1l-0.2-0.4l-0.2-0.6l-1.5-1.8
		l0.8-3.7l0.2-5.2l-0.2-0.7l-0.2-0.7l-1.1-2.7l-1.9-4.1l-0.4-0.8l-2.6-1.4l-0.2,0.3l-0.3,0.3l-0.7,0.5l-0.6-0.3l-2.4-3.9l6.5-4.3
		l0.5-0.6l1.4-2.5v-0.1l0-0.5l-0.5-0.9l-0.3-0.3l-0.5-0.4l-3.3-11.7l0-0.3l0-0.2l1.7-0.3l0-0.6l-0.1-0.6l-0.7-0.7L484,323l-0.9,0
		l0.1,0.7l-0.3,0.2l-2.1,0.8l-2.4-0.2l-0.2,0l-0.3-0.2l-3-3.1l-4.9-2.6l-1.1,0.4l-0.5,0l-2.4-0.6l-4.9-2.8l-0.5-1.4l-0.3,0l-1.8-0.9
		l-1.6-0.9l-0.7-0.4l-2-0.9l-0.9,0l-0.4,0.6l-0.2,1.4l-1.8,1.3l-0.8,0.1l-4-0.6l-7.2-2.8l-4.6-2.9l-1.7-1.9l-2.6-3.4l-2.1-3.2
		l-2.9-3.8l1.8-4.1l-0.2-4l0.3-2.5l0.1-0.5l0.6-1.8l1.2-2.6l0.1-0.2l0.2-0.1l1.2-2.4l-0.6-3.4l-0.4-2.9l-0.2-1.6l0.9-2.2l0.6-1
		l0.3-0.9l-0.4-3.3l-5.5-1.9l-0.2,0.9l0,0.1l-0.3,0.5l-1.1,0.3l-1.2,0.2l-0.9-1l-1.8-2.2l0,0l0-0.8l1.8-7.1l0.9-1.6l0.4,0.1l0.4,0
		l1.6-1.2l0.4-3.7l0-0.6l-0.3-0.9l-1.2-1.8l-1.2-2l0-0.1l0.4-4.4l0.3-1.1l0.7-1.3l1.5-0.7l0.2-1.8l-1.2-4.9l-0.1-0.3l-0.6-0.4
		l-1.5-0.7l-1.5,0.7l-0.9,0.5l-1.6,0.5l-0.8-2l0-0.7l0.2-0.6l0.1-0.2l-0.7,0.7l-8.2,1l-0.3-0.1l-5.2-1.7l-3.6-1.5l-0.4-0.2l-0.2-0.2
		l-0.3-0.5l-0.1-0.5l0.1-0.2l0.2-0.4l0.5-0.6l0.5-0.5l0.1-0.2l0.1-0.3l0-0.2l0-0.3l-0.1-0.3l-0.2-0.3l-5.2-3l-0.2-0.1l-0.4-0.2
		l-0.4-0.1l-0.5-0.1l-0.2,0l-1,0.6l-0.7,0.5l-0.3,0.1l-0.5-0.1l-0.2-0.1l-3.6-2.8l-0.2-0.3l-0.1-0.3l-0.1-0.4l0.1-0.3l0.9-1.2
		l0.1-0.2l0-0.2l-0.1-0.2l-0.2-0.2l-0.2-0.1l-0.2-0.1l-0.5,0l-0.3,0l-1.4,0.6l-0.6,0l-4.4-1.1l-0.6-0.2l-0.6-0.4l-1.2-1.2l-0.7-0.8
		l-0.4-0.3l-0.4-0.2l-0.5,0l-0.4,0l-2,0.6l-0.4,0.1l-0.3,0.2l-1,0.8l-1.7,0.9l-0.5,0l-0.2,0l-0.3-0.2l-3-3.1l-1.1-1.4l-0.1-0.2
		l0-0.3l0-1.5l5.6,0.3l2.8-2.2l3.4-0.4l0.8,0.6l0,0.3l0.6,0.3l1.6,0.3l1.5,0.1l0.9-0.6l0.3-1l0.6-2.5l-0.7-4.1l3.9-3.5l0.6-0.4
		l2.7,0l1,0.1l1.7,1l0.7,1.5l0.6-0.1l5.5-1.2l0.2-0.2l0-0.1l-0.1-0.3l-1.1-1.9l3.2-0.2l5-1.8l0.4-0.2l3.7-2.7l0.4-0.5l-0.2-1.8
		l2.1-3l1.5,0.3l1.7,0.2l1.5-0.5l5.5-1.1l0.3,0l3.9,3l2,0.6l0.5,0.4l0.6,0.2l0.7,0.3l1.9,1l1,0.8l0.5,1.4l2.5,2.8l3.3-0.4l0.2-0.2
		l0-0.5l1.8-0.4l7.7,0.7l0.8,0.1l3.4,3.2l2.5,2.6l0.4,0.7l1.2,0.1l0.8-0.3l1.2-0.4l0-0.1l0.1-0.1l1.8-0.6l7.3-0.2l0.7,0.2l0,0
		l0.2,0.5l0,0.2l-1,0.6l-0.9,0.7l-0.3,0.4l0,0.1l0.1,0.1l2.1,0.9l0.2,0l0.3-0.2l7-7.6l0.4-0.4l2.3-1.1l0.2,0l1.9-0.3l0.3,0.6
		l0.2,0.6l0.8,1.8l0.2,0.2l1.5,0.2l0.5-0.9l0.1-0.7l0-0.5l0.1-1.2l0.2-0.8l0.4-0.5l1.8-1.6l1.1-0.5l3.5,0.1l0.2,0.1l0.4,0.5l0.1,0.2
		l0.1,0.9l1.1,1.6l0.4,0.1l0.7-0.3l4.7-5l2.1-2.9l0.1-0.1l0-0.1l-0.3-1.8l-0.2-1.3l0.5-1.6l1.2-2.8l0.2-0.5l6.6-6.3l1-0.9l1.7-1.4
		l2.7-0.2l2.8-2.4l-1.4-1.2l-0.2-1.4l0-0.3l0.3-1.9l0.1-0.3l0.4-0.6l0.5-0.1l5,5.8l1,3.3l1.5,3.1l1.6,0.7l0.6-0.3l3.4-0.6l2.7,0.4
		l3.9-0.1l0.7-1.4l6.3,0.4l0.7,0.5l0.4,0.7l0.2,2.8l0,0.5l-0.5,2l-0.1,0.5l-0.4,1l-1,1.6l-0.8,1l-1.1,1.3l-1,1.1l-0.7,0.7l-1.6,1.9
		l-0.5,0.8l-0.1,0.5l-0.2,1.2l1.1,0.1l5.8,0.2l2.5-0.4l0.8-0.3l2-2.8l0.6-1.8l0.2-0.9l5-2.1l0.3,0L556.2,170.7z M497.2,243.8
		l-0.6,1.1l-0.7,0.9l-0.5,0.4l-0.4,0.2l-0.4,0.1l-0.7,0l-0.9-0.1l-1.1-0.2l-5.6,0.2l-1.1,2.2l0,0.1l0.1,0.3l0.5,0.6l0.4,0.3l0.1,0.1
		l0,0.1l-0.1,0.4l-1.1-0.1l-0.5-0.1l-1.2-0.5l-0.5-0.3l-0.5,0.1l-1.7,1.1l0,0.4l-0.4,6.2l0.1,0.6l1.6-0.3l1.6,1.1l-2.2,2.9l-0.4,0.3
		l-0.5,0.7l-0.3,0.5v1.5l0.5,0.5l0.4,0.7l-0.6,1l-0.5,0.5l-1,0.5l0.2,0.8l0.2,0.2l2.1,1.3l2,0.1l0.6-0.1l-0.5-0.1l0.1-0.6l2.2-1.4
		l1.6-0.6l0.2-0.1l1.2-0.1l3.4,0.7l1,1.3l1.9-1l2.9,2.9l2,0.1l-0.2-3.2l0-0.2l0.1-0.1l1.6-0.8l0.7-0.3l0.5-0.2l0.8,1.9l3.8,1.2
		l7.1,2l1.3,0.3l1.6-0.5l0.4-0.7l0.1-0.4l-0.1-0.3l-0.2,0l-0.5-0.2l0-0.3l1.8-1.5l0.7-1.7l0.8-2.5l-1.5-1.6l-0.9-0.1l-2.5-1.7
		l-1.6-0.5l-0.8-3.2l-1.6-3.5l-3.6-3.8l-4.7-8.7l-0.9-0.5l-0.8,0.6l-0.6,0.8l-0.6,1.4l-0.4,1.2l-0.6,0.2l-1.1-0.6L497.2,243.8z"
        ></path>
        <path
          id="Berlin"
          class="st0 available"
          d="M497.2,243.8l0.5,0.5l1.1,0.6l0.6-0.2l0.4-1.2l0.6-1.4l0.6-0.8l0.8-0.6l0.9,0.5l4.7,8.7l3.6,3.8
		l1.6,3.5l0.8,3.2l1.6,0.5l2.5,1.7l0.9,0.1l1.5,1.6l-0.8,2.5l-0.7,1.7l-1.8,1.5l0,0.3l0.5,0.2l0.2,0l0.1,0.4l-0.1,0.4l-0.4,0.7
		l-1.6,0.5l-1.3-0.3l-7.1-2l-3.8-1.2l-0.8-1.9l-0.5,0.2l-0.7,0.3l-1.6,0.8l-0.1,0.1l0,0.2l0.2,3.2l-2-0.1l-2.9-2.9l-1.9,1l-1-1.3
		l-3.4-0.7l-1.2,0.1l-0.2,0.1l-1.6,0.6l-2.2,1.4l-0.1,0.6l0.5,0.1l-0.6,0.1l-2-0.1l-2.1-1.3l-0.2-0.2l-0.2-0.8l1-0.5l0.5-0.5l0.6-1
		l-0.4-0.7l-0.5-0.5v-1.5l0.3-0.5l0.5-0.7l0.4-0.3l2.2-2.9l-1.6-1.1l-1.6,0.3l-0.1-0.6l0.4-6.2l0-0.4l1.7-1.1l0.5-0.1l0.5,0.3
		l1.2,0.5l0.5,0.1l1.1,0.1l0.1-0.4l0-0.1l-0.1-0.1l-0.4-0.3l-0.5-0.6l-0.1-0.3l0-0.1l1.1-2.2l5.6-0.2l1.1,0.2l0.9,0.1l0.7,0l0.4-0.1
		l0.4-0.2l0.5-0.4l0.7-0.9L497.2,243.8z"
        ></path>
        <path
          id="Bayern"
          class="st0 available"
          d="M411.1,475l0.9,0.2l0.7,0.6l2.6,2.6l0,0.4l-0.2,1.7l-0.3,1.8l0.4,0.3l1.2,0.2l4.5,1.3l2,0.8
		l0.5,0.6l1.5,1.5l1.2,2.7l-0.1,0.2l-0.1,0.1l-0.7,0.6l-0.9,0.2l-0.4,0.4l-1,1.2l0.1,0.3l1.3,1.1l3.3,1.7l0.3,0.2l1.1,1.2l1.4,1.7
		l1,2.1l-0.1,0.4l-0.2,0.6l-0.7,0.7l-0.3,1l0,0.5l0.2,1.6l0.2,1.2l0.1,0.2l0.5,0.6l0.1,0.1l0.7-0.2l0.5,0.1l1,0.8l1.5,1.6l-0.3,2.1
		l3.9,0.6l3.3,3.4l4.1,2.4l1.7,0l1,0.2l1.4,0.8l0.6,0.9l2.8,4.8l-0.1,3.5l-0.3,0.3l-0.6,0.7l-2.2,4.2l-0.6,1.7l-0.3,3.4l-0.2,0.7
		l-4.3,3l0,0.3l0,0.2l0.4,1.4l0.5,0.8l1.9,2.7l0.2,0.2l0.2,0.1l0.5,0.1l4.5,1.3l0.2,0.1l0.4,1.6l0.8,5.3l2.7,5.9l1,1.4l0.4,0.3
		l-0.3,0.4l0.9,0.6l1.2,0.4l0.5,0.1l0.1-0.4l0.1-0.1l0.3,0l0.3,0.5l0.1,0.2l0.1,1.4l0.1,2.1v0.6l0,0.1l-0.6,0.8l1.7,2.6l6.8,5.4
		l0.3,1.3l-0.2,0.7l1.5,2.8l0.5,0.3l1.1,0.4l5,0.7l0.7,0l0.1-0.1l-0.2-0.6l-0.4-0.5l-0.1-0.7l0.6-0.1l0.8,0.1l3,0.5l0.3,0.1l1.5,0.9
		l2.1,1.9l2,2.6l5.8,7.8l0.9,1.2l1.7,1.5l0.9,0.7l0.3,0.1l0.4,0l0.1,3l0.9,0.9l1.1,0.7l0.9,0.5l1.7,0.7l0.6-0.2l0.7-0.3l1.4-0.3
		l0.4,0l0.5,0.2l2.5,1.8l1.4,1.1l3.2,3.6l0.6,0.9l0.7,2.3l1,4.3l1.1,0.7l4.4,2.9l0.2,0l0.6-0.1l0-0.9l-0.1-0.4l-0.1-0.5l0-0.9
		l1.5-0.6l3.4,0.1l0.3,0.2l0.2,0.2l2.5,2.8l1.1,1.3l0.8,1.7l0.7,1.5l1.1,1.3l0.3,0.1l0.9,0l1.9-0.1l0.3-0.2l0.4-0.4l0.6,0l5.8,8.5
		l1.3,2.7l-0.6,2.2l-0.7,2.1l-0.9,1.6l1.3,2.3l1.2,5.7l0,1.6l-1.3,4.5l-4.6,6.5l-0.2,0.2l-1.4-0.4l-2.9-1.5l-0.3-0.2l-5-3.2l-5.4-2
		l-0.4,1.6l-0.2,0.3l-0.8,0.9l-1.1,0.9l-0.5,0.2l-2,0.6l0.8,0.9l0.1,0.1l0.7,2.7l0,0.2l-1.3,3.9l-0.3,0.5l-0.3,1l0,0.4l0,0.2
		l0.8,1.2l0.1,0.5l0,0.3l-0.1,0.4l-1.3,5l-0.2,0.5l-0.2,0.3l-0.3,0.3l-5.1,5.2l-0.5,0.4l-3.9,2.6l-0.4,0.2l-0.5,0.1l-5,0.1l-2.8,1.6
		l-0.5,0.2l-0.7,0.1l-1.2,0l-0.6,0l-0.6,0.1l-3.9,2.1l-0.3,0.2l-0.3,0.3l-0.2,0.3l-0.3,0.7l-0.2,0.4l-2.3,2.7l-0.4,0.3l-0.4,0.2
		l-0.4,0.2l-0.3,0.1l-1.9,0.1l-2.9,0.5l-0.2,0.1l-0.3,0.2l-0.2,0.3l0,0.2l0,0.7l-0.1,0.3l-1,2.1l-0.9,1.1l-0.2,0.2l-2.2,1.7
		l-2.6,2.1l-0.5,0.6l-0.1,0.2l0,0.3l0.3,1.8l0.2,0.8l0.3,0.7l0.6,0.8l2.9,2.4l2,1.6l0.8,0.8l0.4,0.5l0.3,0.5l0.1,0.3l0.9,3.6
		l0.2,0.5l0.3,0.3l2.4,0.4l0.5,0.2l0.2,0.2l0.9,1.3l2.3,3.5l2.8,5.1l0.2,0.5l0.1,0.5l-0.1,0.3l-2.6,4.6l-3.7,7.5l0,0.2l0.1,0.2
		l0.5,0.6l0.9,0.6l3.3,0.3l1-0.1l0.3-0.1l0.7-0.5l0.2-0.2l0.1-0.2l-0.1-0.1l0.2-0.4l0.2-0.1l0.5,0l2,1l0.3,0.2l1.5,1.9l2.1,5.3
		l0.1,0.5L500,759l-0.3,0.3l-1.3,1.5l-1.8,2.9l-0.1,0.7l-0.6,4.5l0,0.1l0.1,0.2l0.3,0.3l0.3,0.1l0.3,0.2l0.1,0.3l0.1,2.3l0,0.7
		l-2.7,3l-0.2,0.1l-0.2-0.1l-2.6-1.1l-0.8-0.4l-2.9-1.6l-7.7-5.2l-0.7-0.7l-0.8-1.2l-0.3-1l0.1-0.4l0.7-1.9l1-1l-2.7-5.6l-1.5-0.4
		l-3.9-1.1h-0.8l-0.4,0l-0.8,0.2l-3.9,0.9l-0.3,0.1l-0.5,0.7l-0.9,1.9l-4.8,2.5l-0.9,0.1l-2.4-2.5l-1.8-2.4l-0.1-0.3l-5.2-0.6
		l-1.8-0.7l-0.2-0.2l0.2-0.5l-4.8,1.8l-1.2-3.6l0.4-0.6l0.4-1.1l-0.1-0.5l-0.4-0.6l-0.5,0.2l-4.3,4.2l-0.1,3.5l1.2,2.5l0.3,1
		l0.5,1.9l0,0.2l-0.1,0.3l-0.1,0.2l-0.2,0.1l-1.4,0.3l-3.7-0.1l-3.3-0.3l-1.5-0.9l-2.8,0.8l-3.6-0.4l-3.9,0.5l-3.2,1.2l-1.2,2
		l-0.2,0.1l-1-0.1l-2.8-0.5l-9.7,0l-1.2-0.2l-1.8,1.1l-0.1,0.1l-1.1,2.5l-0.1,1.1l0,0.2l0,0.2l0.1,0.3l0.1,1.4l-1.1,1.1l-0.2,0.1
		l-4.4,1l-3.5-0.3l-0.7-0.2l-0.5-0.6l-0.2,0.1l-0.2,0.2l-1.4,1.6l-0.2,0.4l-1.7,2l-5.5,4.9l-0.6,1l-0.2,0.4l-0.3,1l-0.4,0.5
		l-0.8,0.5l-0.2,0.1l-2.9,0.3l-0.2,0l-0.2-0.2l0.1-0.4l1.1-1.9l0.9-0.8l-0.2-0.4l-0.1-0.1l-3-0.1l-3.4,1.3l-0.4,0.2l-1.3,0.7
		l-3.5,1.7l-5.3,0.3l-0.7,0l-1.2-0.2l-0.6-0.3l-0.1-1.2l0.1-0.5l0.1-0.1l0.6-0.1l0.3-0.3l-0.1-0.9l-1.6-1.9l-2.3-2.4l-3.3-0.7
		l-0.2,0l-0.5-0.2l-0.1-0.4l0.1-1.4l0.4-0.4l2-1.5l-1.4-2l-2.2,0.3l-3.9,1.5l-2.4-0.5l-4.9-2l-0.2-0.6l0-0.3l0.1-0.2l-0.1-0.3
		l-5.1-0.8l-1.2-0.1l-0.8,0.9l-0.5,0.9l0,0.2L325,773l-0.3,0l-3.1-0.1l-2.5-0.5l-2-1.3l-0.5-0.3l0.4-0.6l0.6-0.8l0.2-1l0-0.8h-0.3
		l-2.2,0.3l-0.1,0l-0.4,0.7l0.1,7.6l0.7,1.8l0.2,0.2l0.2,0.1l0.4-0.2l0.7,0.3l0.5,0.5l0.1,0.8l0.3,3.9l-4,5.4l-4.8,6.2l-1.5,1.4
		l-3,1.8l-2.8,1l-4.5,0.8l-0.3,0l-0.1-0.4l0-0.5l0.2-0.9l0.2-0.8l2.6-6.1l0.6-0.8l0.7-1l0-0.1l-0.1-0.9l-0.5-0.7l-0.2-0.1l-3.2-0.3
		l-0.9,0.7l0,1.1l0,0.4l-0.3,0.3l-4.6,1.5l-1.6-5l0.5-1.1l0.6-0.3l0.1-0.1l0.7-1.1l-0.9-1.6l-0.9-1.2l-1.9-2.2l-2.1-0.8l-0.9,0.2
		l-0.5,0l-0.1-0.1l-0.4-0.4l-2.1-3.6l0-1.1l0-0.7l0.2-0.8l-0.2,0.2l-2.2,1.2l-0.8,0.3l-3.4,0.1l-4.1-1.9l0-0.2l0.1-0.4l0.1-0.1
		l0.1,0l0.2-0.2l0.2-0.3l0.1-1.9l-0.3-0.8l-1.5-0.9l-0.1,0l-1.6,0.1l-0.8,0.6l-0.6,0.9l-2.5,3.4l-3.8-1.2l-3.2-1.3l-0.9-0.6
		l-0.3-0.8l2.7-2l1.2-0.8l2.4,0.2l0.1,0.4l0.1,0.2l0.3,0.2l0.4,0.1l3.1-0.1l5.5-5.6l0.1-0.1l4.8-0.3l5.5-0.1l0.3-0.5l1.1-1.3
		l0.2-0.2l0.2-0.1l1.3-0.2l2.6,0.5l0,0l0.3,0.6l0,0.1l0.9,3.6l-0.1-0.3l0.4-1.2l0.4-0.4l0.6-0.8l0.4-0.3l0.7,0.1h0.2l1.1-1l0.7-2.2
		l0-0.6l-2.5-6.2l-0.1-3.7l1,0l1.3-1.2l-0.3-0.8l-0.4-0.3l-0.5-0.6l-0.5-1.1l-0.5-2.5l-0.1-3.5l0-0.4l0-0.3l0.2-1.8l0.4-1.4
		l-0.8-0.5l-0.3-0.3l-0.2-0.3l-0.2-0.4l-0.1-0.6l0-0.6l0.1-0.5l0.1-0.2l0.2-0.2l0.3-0.2l0.7-0.2l0.7-0.1l0.1-1l0.2-1.9l0.4-0.7
		l0.6-0.6l0.2-0.7l0.3-2.4l-0.1-3l-0.2-3.4l-0.8-0.7l-0.9-0.9l-0.1-0.1l-0.2-0.6l-0.9-2.8v-0.5l-0.3-0.7l-0.3-1.8l-0.1-0.8l-1.2-8.8
		l-1-0.9l-2.9-4.5l-0.8-1.5l-0.4-2l1.5-3.5l0.1-0.2l1.2-2.2l0.3-1.7l-0.2-0.7l-0.3-0.5l1.6-0.1l3.3-1.9l0.9,0.7l1.1,1.4l2.3-0.5
		l4.7-4.2l-0.2-0.6l-0.2-0.5l1.6-0.8l0.2-0.1l0.5-0.1l0.9,0.4h0.6l0.7-0.1l1.1-0.6l0.1-3.9l-0.1-0.6l-1-5.7l-1.3-1.8l-1.2-1.6
		l-0.6-0.8l0.1-1.3l0.1-0.2l0.9-2.4l4,1.3l0.6,0.2l4.8-1l1.6-0.1l0.3,0.3l0.1,0.2l0.1,0.3l-1,2.2l-0.4,0.3l-0.1,0.2l0.1,0.2l0,0l1,0
		l2.2-0.7l1.5-1.6l0.3-0.4l-0.3-0.6l-0.2,0l-0.6-0.6l-0.7-2.6l-0.1-0.9l-2.5-0.8l-0.2,0l-0.3-3.3l0.7-0.3l1.2-3.1l0-0.1l0.3-5.1
		l0.1-6.2l-0.4-1.9l-0.5-0.6l-0.1-0.1l-1.3-1.3l-0.7-2l-0.1-0.3l-6.1-4.5l-4.2-1.8l-0.2-1l0-0.7h0.1l0.4-0.2l-1.3-6.7l-2.1-3.8
		l-1.7-0.7l-1.5-0.8l-2.6-1.8l-0.3-0.3l0-0.2l0.2-6.2l1-2.7l-0.5-0.9l-2-4.3l-0.1-0.3l0-0.3l0.3-3.5l0.3-0.6l2.3-0.1l0.7-0.3
		l0.3-0.5l0.1-0.2l-0.6-1.7l-1.1-2.3l-1.2-3.9l-0.1-0.1l-1-1.3l-0.1-0.6l0-0.2l0.2-0.5l0.4-0.4l-0.3-1.4l-0.8-1.8l-0.7-1.1l-1.1-0.2
		l-0.3,0.1l-1.5,1.4l-0.1,0.1l0.2,0.3l0.7,0.6l0.6,0.1l-0.5,0.9l-0.2,0.2l-2.2,2.4l-0.4,0.1l-4.1,0.3l-1.5-0.4l-0.2-0.1l-0.8-0.8
		l0.1-0.7l0.4-0.4l0.2-0.4l0-0.1l0.2-4.2l-0.2-1.1l0-0.1l-0.7-2.2l-0.1-0.3l-0.3-0.1l-0.5,0l-0.3,0.1l-1.7-2.7l-0.2-1l-2.4-8.4
		l-0.7-0.7l-1.7-1.7l-1.1,0.4l-4.2,1.4l-2.7,1.5l-0.9,0.4l-0.5,0.1l-1.4-0.8l-0.5-0.7l0.4-1.4l0.1-0.1l0.6-0.3l0.1-0.3l0.6-6l-0.1-1
		l-1.2,0.6l-4.3,4.1l-0.1,0.1l-0.2,0l-0.3-0.1l-2.6-1.7l-0.3-0.2l-0.2-0.4l-0.1-0.2l0-0.4l0.2-0.6l0-0.3l-0.1-0.2l-0.3-0.3l-0.2-0.2
		l-0.2-0.1l-1.3,0.1l-0.2,0.2l-0.4,0.5l-0.7,1l-1.2-0.5l-5.6,0.3l-2.3,0.3l-0.4,0.1l-0.3,0.1l-0.1,0.2v0.6l0.5,1.9l1.5,2.5l0.9,1.3
		l0.6,0.1l0.7-0.1l0.3-0.1l0-1.6l-0.1-0.5l0.4-0.6l0.9,0.2l1,1.8l0.4,1.7l-0.8,3.3l0.5,0.5l-0.2,0.5l-0.7,0.8l-1.5,0.3l-2.3-0.7
		l-3-0.4l-1.3,1.8l-0.7,1.1l0.6,0.8l0.1,0.2l-2.4,3.1l-0.6,0.3l-1.2,0.4l-0.6,0.1l-1.3,0.2l-4.9,0l-0.2,0l-1-0.4l-0.9-1.6l0.8-7
		l1.4-3.7l0.5-0.2l1.6-4.3l-1.2-5.3l-0.2-0.1l-0.2-0.1l-0.8-0.2l-0.4,0l-4.4-4.4l-0.3-0.3l-0.1-0.4l-0.1-1.4l0-0.6l0.2-0.1l0.9,0.1
		l-0.7-0.9l-0.1-0.2l-0.4-3l-0.1-2.1l-0.1-2.9l-0.4-3l0-0.2l-0.3-0.5l1.6-0.9l-0.4-3.9l-0.1-0.3l-0.3-0.3l-0.1-0.1l-0.5-0.2l-0.6,0
		l-0.3,0.1L218,514l0.6-1.3l0.5-2.5l0.7-1.2l0.5-0.6l0.1-0.1l13.5-3.7l9.7,1.9l0.3,0.1l0.1,0.1l1.9,4.3l0.5,0.4h0.2l1.3-0.1l5.1-0.9
		l0.4-1.2v-0.8l-0.4-1l-0.2-1.2l-0.7-4.1l0.4-3.4l1.2-2.5l1.1,0.4l3.1,0.8l0.8,0l1.6-0.1l0.1-0.1l0.1-0.4l0.9-2.4l0.7-1.3l0.3-0.5
		l0.1-0.2l1.7-0.6h0.7l0.1,0l0.7-0.3l1.6-1.5l0.8-0.8l0.4-0.8l0.1-0.4l0.1-1.1l-0.1-1.6l-0.3-0.6l-0.7-1l0.7-0.6l0.2-0.3l0.6-2.1
		l0.4-1.6l3.6-1l0.8,0.6l2.2,0.9l1.5-0.2l1.2-0.5l1.2-0.7l1.2-1l1.6-0.9l3.5-3.8l0.9-1.4l0.5-1.3l0.3-1.6l0-0.8l0.3-0.1l0.4-0.2l1-1
		l1.8-2l6.6-0.7l0.5,0.1l1.8,2.4l1.5,2l4.7,2l0.5,0.4l0.5,0.5l2.4,5.1l0.4,2.4l0,0.6l0.5,0.8l0.2,0l1.9,0.2l0.9-0.4l0.9-0.4l0.3,1
		l0.3,1.2l2.3,2.5l4,1.5l3,0.7l-0.3,2.8l-0.3,3.6l0.7,2.9l0.4,1.2l0.2,0.4l1.8,0.4l5.2,1.9l0.3,0l0.2-0.1l0.4-0.8l-0.1-1.8l-0.1-0.1
		l0-1.7l0.1-0.2l0.3-0.2l0.6,0.1l2.4,0.6l2.8,0.6l0.3,0l0.7-0.2l0.7-0.5l0.1-0.2l0.2-0.6l-0.1-1.4l-0.4-0.5l-0.6-0.3l-1.3-0.4
		l-0.6-0.1l-0.2-0.1l-5.5-3.9l-0.4-0.3l-0.1-0.4l-0.2-3.1l0.2-0.9l7.5-3.1l6.6,0h0.2l0.2,0l0.6,0.6l1.1,2l1.3,1.5l3.6,0.6l0.4,0.1
		l1.4-0.5l1.2-0.5l0.4-1.2l0.1-0.1l0.8-0.2l0.9,0l1.6,1.4l1.4,2.4l0,0.6l-0.4,0.1l-0.6,0.4l-0.3,0.3l-0.4,0.5l-0.1,0.6l0.1,0.5
		l0,0.1l0.7,1l2.2,2.1l0.8,0l0.1-0.1l0.9-1.9l3.1-0.5l2-6.9v-0.1l-1.2-7.6l-0.3-0.8l-0.4-0.2l-0.7-0.5l-0.1-2.1v-0.2l0.1-0.6
		l0.2-0.3l0.4-0.1l0.5,0.1l0.1,0.2l1.5-0.7l2.2-1.8l0.2-0.8l1.1-1.2l0.2-0.1l1.6-0.2l3.5,0.7l0.3,0.2l0.3,0.5l0.6,0.8v0.1l-0.3,0.7
		l-0.1,0.1l-0.4,0l-0.4,0.7l-0.1,1.6l0,2.1l0.1,0.6l0.1,0.2l2,1.2l1.8-0.2l0.6,2.2l0.7,1.2l1.7,1.9l0.6,0.3l1.9-0.6l0.4-0.4l0-0.2
		l0.2-0.8l0.2-0.3l0.2-0.1l1.3-0.3l0.4,0.2l0.3,0.2l0.4,0.5l0.6,0.5l0.3,0.2h0.2l12.5-1.7l4.1-1.5L411.1,475z"
        ></path>
        <path
          id="Baden__x26__Württemberg"
          class="st0 available"
          d="M225.4,563.1l1,0.4l0.2,0l4.9,0l1.3-0.2l0.6-0.1l1.2-0.4l0.6-0.3l2.4-3.1
		l-0.1-0.2l-0.6-0.8l0.7-1.1l1.3-1.8l3,0.4l2.3,0.7l1.5-0.3l0.7-0.8l0.2-0.5l-0.5-0.5l0.8-3.3l-0.4-1.7l-1-1.8l-0.9-0.2l-0.4,0.6
		l0.1,0.5l0,1.6l-0.3,0.1l-0.7,0.1l-0.6-0.1l-0.9-1.3l-1.5-2.5l-0.5-1.9V544l0.1-0.2l0.3-0.1l0.4-0.1l2.3-0.3l5.6-0.3l1.2,0.5l0.7-1
		l0.4-0.5l0.2-0.2l1.3-0.1l0.2,0.1l0.2,0.2l0.3,0.3l0.1,0.2l0,0.3l-0.2,0.6l0,0.4l0.1,0.2l0.2,0.4l0.3,0.2l2.6,1.7l0.3,0.1l0.2,0
		l0.1-0.1l4.3-4.1l1.2-0.6l0.1,1l-0.6,6l-0.1,0.3l-0.6,0.3l-0.1,0.1l-0.4,1.4l0.5,0.7l1.4,0.8l0.5-0.1l0.9-0.4l2.7-1.5l4.2-1.4
		l1.1-0.4l1.7,1.7l0.7,0.7l2.4,8.4l0.2,1l1.7,2.7l0.3-0.1l0.5,0l0.3,0.1l0.1,0.3l0.7,2.2l0,0.1l0.2,1.1l-0.2,4.2l0,0.1l-0.2,0.4
		l-0.4,0.4l-0.1,0.7l0.8,0.8l0.2,0.1l1.5,0.4l4.1-0.3l0.4-0.1l2.2-2.4l0.2-0.2l0.5-0.9l-0.6-0.2l-0.7-0.6l-0.2-0.3l0.1-0.1l1.5-1.4
		l0.3-0.1l1.1,0.2l0.7,1.1l0.8,1.8l0.3,1.4l-0.4,0.4l-0.2,0.5l0,0.2l0.1,0.6l1,1.3l0.1,0.1l1.2,3.9l1.1,2.3l0.6,1.7l-0.1,0.2
		l-0.3,0.5l-0.7,0.3l-2.3,0.1l-0.3,0.6l-0.3,3.5l0,0.3l0.1,0.3l2,4.3l0.5,0.9l-1,2.7l-0.2,6.2l0,0.2l0.3,0.3l2.6,1.8l1.5,0.8
		l1.7,0.7l2.1,3.7l1.3,6.7l-0.4,0.2h-0.1l0,0.7l0.2,1l4.2,1.8l6.1,4.5l0.1,0.4l0.7,2l1.3,1.3l0.1,0.1l0.5,0.6l0.4,1.9l-0.1,6.2
		l-0.3,5.1l0,0.1l-1.2,3.1l-0.7,0.3l0.3,3.3l0.2,0l2.5,0.8l0.1,0.9l0.7,2.6l0.6,0.6l0.2,0l0.3,0.6l-0.3,0.4l-1.5,1.6l-2.2,0.7l-1,0
		l0,0l-0.1-0.2l0.1-0.2l0.4-0.3l1-2.2l-0.1-0.3l-0.1-0.2l-0.3-0.3l-1.6,0.1l-4.8,1l-0.6-0.2l-4-1.3l-0.9,2.4l-0.1,0.2l-0.1,1.3
		l0.6,0.8l1.2,1.7l1.3,1.8l1,5.7l0.1,0.6l-0.1,3.9l-1.1,0.6l-0.7,0.1h-0.6l-0.9-0.4l-0.5,0.1l-0.2,0.1l-1.6,0.8l0.2,0.5l0.2,0.6
		l-4.7,4.2l-2.3,0.5l-1.1-1.4l-0.9-0.7l-3.3,1.9l-1.6,0.1l0.3,0.5l0.2,0.7l-0.3,1.7l-1.2,2.2l-0.1,0.2l-1.5,3.5l0.4,2l0.8,1.5
		l2.9,4.5l1,0.9l1.2,8.9l0.1,0.8l0.3,1.8l0.3,0.7v0.5l0.9,2.8l0.2,0.6l0.1,0.1l0.9,0.9l0.8,0.7l0.2,3.4l0.1,3l-0.3,2.4l-0.2,0.7
		l-0.6,0.6l-0.4,0.7l-0.2,1.9l-0.1,1l-0.7,0.1l-0.7,0.2l-0.3,0.2l-0.2,0.2l-0.1,0.2l-0.1,0.5l0,0.6l0.1,0.6l0.2,0.4l0.2,0.3l0.3,0.3
		l0.8,0.5l-0.4,1.4l-0.2,1.8l0,0.3l0,0.4l0.1,3.5l0.5,2.5l0.5,1.1l0.5,0.6l0.4,0.3l0.3,0.8l-1.3,1.2l-1,0l0.1,3.7l2.5,6.2l0,0.6
		l-0.7,2.2l-1.1,1H293l-0.7-0.1l-0.4,0.3l-0.6,0.8l-0.2,0.4l-0.4,1.2l0.1,0.3l-0.9-3.5l0-0.1l-0.3-0.6l0,0l-2.6-0.5l-1.3,0.2
		l-0.2,0.1l-0.2,0.2l-1.1,1.3l-0.3,0.5l-5.5,0.1l-4.8,0.3l-0.1,0.1l-5.5,5.6l-3.1,0.1l-0.4-0.1l-0.3-0.2l-0.1-0.2l-0.1-0.4l-2.4-0.2
		l-1.2,0.8l-2.7,2h-2.1l-0.8-0.1l-0.3-0.2l-0.3-0.2l-1.4-1.6l-0.8-1.9l-0.1-0.9l-0.4-0.8l-0.7-0.9l-0.6-0.5l-4.9-1.6l-1-0.3l-0.6,0
		l-0.4,0.1l-1.3,0.2l-0.2,0l-0.5,0.3l-0.5,0.2l-0.4,0l-0.3-0.1l-0.9-0.5l-2-1.1l-2.7-1.8l-1-0.9l-0.8-0.8l-0.5-0.9l-0.2-0.5
		l-0.1-0.9l0.1-0.2l0-0.4l-0.1-0.1l-0.6-0.6l-7.6-5l-2.1-1.3l-0.3-0.1l-0.4-0.6l-0.3-0.2l-1.5-0.3l-0.4,0.6l-0.2,0.5l0,0.1l0.1,0.2
		l2.5,2.6l0.8,0.6l0.6,0.4l3.3,2.2l0.1,0l0.5-0.1l0.3,0l1.7,1.4l2.6,5.4l0.4,1.6l-1.6,1l-1.4,0.2l-2.5-1l-2.3-1.2l-0.4-0.2l-0.5-0.1
		l-2.5-0.6l-0.4-0.1l-1.8-0.2l-0.3,0l-0.4,0.1l-1.2,0.5l-1.2,0.6l-0.4,0.3l-0.5,0.5l-0.8,0.5l-1.2,0.6l-2.9,0.8h-0.2l-1.3-0.8
		l-4.6-4l-1,1.8l-0.3,0l-0.6-0.2l-3.8-1.7l-0.1-0.3l-1-3.6l0.2-0.9l0.6-0.7l-1.1-1.9l-1.6-1.4l-2.3-0.7l0.3,1.1l-0.4,0.6l-0.2,0.3
		l-0.9,0.6l-0.3-0.1l-0.6-0.9l-0.3-3.4l-2.8-0.6l-0.8,1.1l-0.4,1.2l-3.2,0.7l-1.5,0.3l-1.1,0.9l-1.2,1.5l-3.2,5.6l-0.5,2l0,0.1
		l0.4,0.7l4,2.9l0.2,0.1l3.9-1.6l-0.1-0.6l0.4-0.3l1.3-0.6l0.6-0.1l2.6-0.2l0.2,0l0.4,0.1l1.5,2l0,0.3l-0.4,0.9l-1.5,3.1l-0.9,0.9
		l-0.6,0.5l-1-0.2l-0.3-0.5l0.2-0.7l0.3-0.1l0.1-0.2l-0.1-0.3l-0.1-0.2l-0.7-0.8l-1.3-0.7l0,0l-1.1-0.3l-3.7,3l1.4,1.7l0.4,0.6
		l0,0.1l-0.6,0.4l-1.5,0.5l-1.8,0.5l-0.5,0.1l-6.7-0.6l-0.4-0.1l-0.1-0.1l-0.3-0.1l-1.6-1.5l-0.2-0.2l-0.1-0.3v-0.4l0.2-0.9l0-0.1
		l-0.2-0.2l-0.6-0.3l-1.9-0.5l-3.2-0.6l-0.5-0.1l-0.3,0.1l-0.2,0.1l-6.2,3.6l-0.3,0.3l-0.1,0.2l-0.1,0.3l-0.1,0.9l-0.2,0.4l-0.4,0.3
		l-0.3,0.1l-4.6,0.6h-0.2l-4.2-0.9l-3.4-1.4l-1.1-1.6l-0.2-0.1l-0.4-0.2l-0.2,0l-3.5,0.1l-1.4,1.4l-0.2,0.4l-1,1.1l-0.5,0.3l-2.7,1
		l-3.8,1.1l-0.3,0l-0.8-0.1l-0.8-0.3l-0.1-0.1l-1.2-1.3l-0.7-1.1l0.3-0.1l0.5,0.1l2.5-1l0-0.3l-0.2-0.9l-0.4-1l-5.8-1.5l-4.4-5.7
		l-0.2-0.3l-0.2-0.4l-0.1-0.4l-0.4-2.8l0.1-0.4l0.1-0.2l1.6-1.2l0.2-0.3l0.3-0.4l0.2-0.6l0.1-0.7l0-0.3l-0.1-0.5l-1-2.9l-0.1-0.4
		l0.1-0.8l0.1-0.4l0.9-2.2l1.2-2.9l0.2-0.7l0.1-0.5l-0.1-0.6l-0.3-1l-0.1-0.4v-0.7l0.1-0.5l1.8-4.8l1.8-2.5l0.5-0.7l0.5-0.9l0.1-0.5
		l0-0.6l-0.3-1.2l-0.3-0.6l-0.5-0.8l-2.1-2.7l-0.2-0.2L118,719l0-0.4l0.2-4.3l0.1-0.4l0.6-3.2l0-0.5l0.1-0.3l1.6-3.4l0.2-0.3
		l2.2-2.9l2.4-3.2l0.2-0.4l0.3-1.1l0.7-2.3l0.7-3.7l0.1-0.3l0.2-0.5l0.4-0.5l0.5-0.4l1.7-0.6l0.5-0.3l0.2-0.2l0.1-0.2l0.2-0.4
		l0.1-0.3l0-0.3l-0.7-3.9l-0.1-0.9v-0.6l0.1-0.7l0.1-0.7l0.2-0.6l2.1-5.2l2.3-4.7l0.3-0.4l0.3-0.7l0.1-0.5l0-0.4l0-3.6l-0.1-0.9
		l-0.2-1.1l0-0.5l0-0.3l0.1-0.4l2.7-5.2l0.1-0.2l0.2-0.2l1.2-0.5l1.5-0.8l0.5-0.4l4.8-5.5l0.7-3.6l0.1-0.3l0.1-0.1l0.5-0.2l0.4,0
		l2.5-0.6l3.5-2.5l0.8-0.7l0.7-0.9l0.4-0.7l0.2-0.5l0.2-0.8l0.3-1.2l0.4-1.3l0.6-1.7l0.4-0.9l0.4-0.9l0.9-1.6l3.4-5.3l0.3-0.3
		l0.3-0.2l2-0.6l0.4-0.2l1.6-1.2l1.1-0.8l0.4-0.4l0.3-0.5l0.3-0.5l0.4-0.9l0.1-0.3l0.1-0.3l0.2-1.4l0.2-0.9l0.5-1.2l0.4-0.7l0.7-1.1
		l1-1.1l0,0l0.7-0.8l0.4-0.7l0.2-0.5l2.4-10.3l-0.2-1.8l-0.2,0h-0.1l0,0l0.1-0.7l0.2-0.6l0.3-0.5l0.4-0.4l0.5-0.4l0.6-0.3l1.4-0.9
		l0.5-0.4l1.8-2l1.4-0.7l0.1-0.2l0.1-0.4l-0.1-0.5l-0.2-0.4l-0.3-0.3l-0.2-0.1l-1-0.3l-0.2-0.1l-0.2-0.2l-0.1-0.4l0-0.4l0-0.2
		l0.1-0.3l0.2-0.3l0.5-0.5l0.4-0.3l0.4-0.3l0.5-0.5l0.6-0.9l0.4-0.8l0.2-0.9l0-0.6l-0.1-0.9l-0.2-2.3l0.8-2.1l0-0.3l-0.1-0.3
		l-0.3-0.4l-0.4-0.2l-0.6-0.1l-1,0l-0.7,0l-0.6-0.1l-0.2-0.1l-0.3-0.2l-0.2-0.2l-0.1-0.2l0-0.4l0.1-0.3l0.2-0.2l0.2-0.2l-0.4-3.2
		l-0.1-0.6l-1.1-4.4l-0.4-0.8l-0.1-0.3l0-0.4l0-0.4l0.1-0.5l0.4-0.7l0.1-1.1l0.2-0.1l1-0.5l0.3-0.1l0.3,0l1,0.1l0.6,0.5l1.5,1.3
		l2,2.6l0.4,1.2l0.6,0.9l0.9,0.7l0.5,0.2l0.3,0.1l0.9,0l1.7-1.4l0.7-1.1l0.1-0.5V565l-0.9-4.1l-0.6,0.1l-0.2-0.4l0-0.8l0.3-0.6
		l1.7-0.5l3.9-1l1,0.2l0.1,0.5l-0.5,1l-0.1,0.1l-0.4-0.2l-0.1-0.2l-0.4,0.2l1.9,7.6l1.5,1l2.3,1l0.5,0.2l6.5,1.3h2.4l-0.2,0.6
		l0.2,1.2l-2.3,2.1l-2.3,0.8l-1.8,3.7l-0.4,0.9l0.3,0.9l0.6,0.6l0.5,0.1l1.9,0.1l0.1,0l0.3-0.2l6.5-5.9l-0.6-0.8l-0.7-0.7l0.2-0.6
		l0.4-0.7l1.6-1.8l1-0.7l0.2-0.1l5.6-1.8l1.1,0.3l1.5,1.7l0.9-0.2l0.3-0.4l0-0.4l-0.6-1.4l-0.4-0.3l-1.6-0.9l0.5-0.4l0.1-0.2l0.4-1
		l-0.1-0.1L225.4,563.1z M196.3,755.8l-0.7-1.9l-0.2-0.2l-2.5,0.1l0.2,2.2l0.6,0.5l2.1-0.2l0.7-0.1L196.3,755.8z"
        ></path>
      </g>
      <path
        id="Östereich"
        class="st1 available"
        d="M297.5,802.1l2.2-1l2.1,0.4l3-1.2l1.9-1.9h1.9l4-5.1l-0.7-1l1.9-2.1l1.4,0.1l0.3-2.1l3-3.7
	l-0.7-5.4l-2.5-1.4l0.8-1.7l-1-1.8l1.4-2.1l-1.8-2.2l3.7-1.1l0.1,1.2l-0.6,1.8l2.9,1.9h3.7l0.1-1.7l2.2-2.1l2.2,1.2l3.9-0.1l1.9,2.3
	l2.8-0.1l0.6,1.8h2.8l4.8-1.9l2.5,2.2l-3,2.1l0.8,1.5l2.9-0.3l4.8,6.2l-1.4,1.9l3.2,1.8l3-0.8l4,0.4l3.3-3.6l2.9-0.8l1.9-0.4
	l1.4,1.1l-1.5,2.5l2.3,0.4l2.1-1.7l0.4-1.7l3.2-2.9l4,0.1l0.4-2.1l-1.7-0.4l3.6-4.4l4.1,1l4.6-0.6l1.9-2.2v-3.7l3.2-2.8l2.5,1
	l7.3-0.7l4.6,0.8l0.6-2.2l10.4-2.8l1.8,0.7l1-0.7l7.9,1l3-0.7l-0.3-4.8l-1.8-4.4l2.9-1.7l2.5-1.9l0.7,1l-1.5,2.1l1.1,2.6l4.8-1.7
	l2.8,0.8l4.7-1.2l1.4,3l4.3,3.6l2.1-1.5l1.8,0.6l2.3-4l2.3,0.1l2.2-1.1l2.9,0.1l1.4-0.4l2.3,1.2l-0.1,2.2l3.5,1.7l1,1.9l-1.8,0.3
	l-1,3l1.8,3.7h2.2l3.3,2.9l0.1,1.2l5.3,3.2l1.9-1.2l1.5,1.9l1-2.2l1.8-0.7c0,0,1-2.3,0.3-2.3c-0.7,0-1.5-1.7-1.5-1.7l0.1-1.5
	l1.2-1.8l-1.2-1.4l0.6-1.4h1.4l0.1-3.6l1.8-1.9l-1.7-3l-1.1-2.8l-3-3l-2.1,1.8h-4.7l-1-1.4l1.4-2.9l-0.1-3l2.1-2.3l2.5-5.3l-3.5-5.5
	l-3.2-4.7l-2.2-0.8l-1-1.1l-1-1.2v-1.9l-0.6-1.9l-1.8-1.7l-3.5-2.1l-1.4-3l-1-3.6l1.4-0.7l1.9,0.3l-0.1-1.7l1-1.4l2.2-1.1l0.3-1.8
	v-1.8l1.7-1h2.5l2.2-0.4l1.8-0.4l0.7-1.2l1.4-2.3l0.6-1.4l1.8-0.3h2.1l1.1-0.7l0.8-0.8l1.5-0.1l1.4-1.2l2.3-1.1l5.4-0.1l1.7-0.6
	l3-2.2l2.1-2.3l1.9-2.1l1.4-1.7l0.4-1.7l0.6-1.4l0.4-1.4v-2.1l-0.1-1.9v-1.7l0.4-1.7l1.4-2.1l-1-2.5l-0.7-1.9v-1.5l1.4-1.1l1.9-0.8
	l2.1-0.6l1.8,1.2l1.1,1.1l1.7-0.6l1-0.1l1.2,1l2.2,0.1l0.6,1.1l0.8,1l2.1,1.2l1.2,0.6l1-1.2l0.3-3.5l1.8-0.3l2.2-2.1v-2.2l1.7-1.9
	l-1.5-6.8l1.1-1.7l-2.9-2.3l1.2-1.5l0.6-1.4l0.6-2.2l1.8,0.3l1.9,0.1l5.4,4.4l2.8,0.3l1.9,2.9l1.9,1.2v1.7l-2.1,1v1.2l1.4,1.2
	l0.1,1.1l2.5,1.4l8.4,0.1l2.2,1.2l2.1,0.1l1.8,0.3l1.8,0.6l2.1,1.4l2.1-2.2l1.5-1.8l1.7,0.1l1.2-2.8v-3l2.8-0.7l1.7,1.9l1.8,0.8
	l1.8,0.8l1.4-1.4l1.4-1.1l1.2,0.7l0.4,1.7h2.2l0.7,2.2l2.1,0.1l1-3.3l-0.3-4.8l1-3.7l4.3-4.6l0.7-5.1h4l3.6,0.6l1.5,0.8h0.8l0.8-1.8
	l-1-1.8l-0.6-1.8l0.8-2.6l0.1-1.7l-0.3-1.8l0.6-2.2l0.4-2.1l-1.5-6.4l0.8-2.2l0.3-2.3l5,0.3l1.1,1.1h4.8l1.8,2.8l-1.4,2.5l2.1,0.3
	l2.1-1.7l3.2,0.1l1-4.3l2.2,1.5l2.5-1l5.4,2.6h3.3l2.6,3.6l4,0.8l3.5,0.4l2.3,1.2l1.7,1.1l0.7,1l5,0.4l0.7-1.4l0.3-1h3.7l7.7,4
	l1.4,2.2l9.5,5.4h3l1.4-1.1l5,1l4.6-0.4h0.7l1,1l1.1-1.7h1.2l1.2,1.1l3-2.2l2.2-6.2l2.8,0.7l2.1-1.4l5.1,2.8l2.9-1.4l2.1,1.9v2.3
	l0.6,1.8l1.4-0.7l0.4-1.2l3,0.4l1.9,2.2h4l2.9-1.7l2.1,2.5l2.3,7.5l0.7,2.3v2.1l1.4,3.9l-1.9,3.3l-1.7,3l-1.7,3.6l0.1,7l-1,1.2
	l0.8,2.5l3.6,1.9l0.6,2.5l1.5,1.9l0.6,0.8l2.1,0.6v3.2l0.8,2.2l0.6,2.1l0.8,2.9l1.5,0.3l1.8,1.9l1.7,0.6h1.2l1.8,2.2l0.4,2.1
	l0.1,3.2l0.7,3.2l4,0.1l1.1,1.4l-3.2,4.6l0.6,1.5l-0.7,2.3l0.3,4.3l-2.3,2.8l-3.2,0.7v0.8l3.5,2.5l0.8,1.5L772,720l0.1,1.2l1.5,1.7
	l-0.1,4.1l1.7,2.8l-4,0.1l-9.8,2.9l-1.7-2.6l-1.7,4.8l-5.1-1.4l-1.8-1l-0.8-2.2l-1.1-0.7H748l-0.4-1l-2.3,0.1l-2.2-1.7l-2.2,1.1h-3
	l-0.7,1.5l1,2.5l-3.3,1.8l-1.4,2.3h-1.8l-1.4,2.1l4.8,1.9l1.9-0.7l5.4,2.1l2.3-1l2.8,2.3l0.3,3.9l3,1.9l0.1,2.3l-1.5,1.2l-1.9,0.8
	l0.6,2.1l0.7,1.8l-1.7,2.1l-4.6,4.3l-3.2,0.1l-1.7,1.9l-1.9-1.9h-1.2l-0.7,1.4l0.8,2.6l-0.4,3.9l1.9,0.4l0.1,3.5l-1.1,1.9l0.1,2.6
	l-0.1,4.1l-1,1.5l1,2.1h1.1l0.8,1.5l-0.8,1.1l0.7,2.5l2.5-1.8l1.8-0.1l1,1.2l-0.7,1.5l-0.8,0.6l-1.1,1.2l-1.2,1.4l1.5,2.6h2.1
	l-1,1.9l-3.5,1.5l-0.4,1.5h2.3l1.9,0.6l0.7,1l-0.7,0.7l-2.6,0.1l-1.1-0.3l-1.2,1l-2.1-1.2l-1.7,1l-1.8-0.6l-2.3,1h-1.1l-1-0.8
	l-0.3,1.7l-0.6,1.7l-1.4,1.4l-0.6,1.8l-0.6,1.2l-2.5,0.3l-0.6,2.2l-1.4,1.8l-2.6,4l-2.6,0.7l-2.6,2.1l-2.2,1.5l-2.2,0.6l1.1,1.8v4
	l0.4,3.6l1.2,1.9l1.7,0.3l0.3,1.7l1.2,1.1v3.6h-1.2l-2.9-3.7l-1.4,0.6l-7.6-3l-4.1,1.1l-2.9,2.1l-2.3,0.6h-1.4l-1.7-1h-1.9L684,845
	l-0.4,1l-2.2-0.8l-3.9,1.7v1.8v2.1l-2.2,1.8l-3.2,0.4l-1.8-1.7l-1.4-1.4h-2.6l-6.9,1l-5.8,0.6l-2.9-1.1l-5.4,1l-4.8,1.1l-1.5,3
	l-0.8,1.4l-2.8-2.9l-1.7,2.3l-4,0.4l-3,10.9l-5.7,1.2l-1.5,3.7l-1.8,0.3l-1.2,2.1l-5.3,1.5v3.6l-1.2,2.1l-4-3.7l-1.2,1.2l-1.9-0.8
	l-1.7-1.1l-1.7-1.8l-5.1,1.1l-2.8,1l-1.9-1l-4.4,0.7l-4.7,0.7l-3-4.4l-4.6-0.6l-4.1,0.3l-5.1-3.5l-4.7,0.1l-3.3,1.9l-6.1-0.6
	l-7.2-2.6l-2.3,1.4l-2.2-2.1l-2.2,0.6l-2.1-1.7l-3.9,1.2l-2.5-1.7l-6.1,2.3l-5.5,0.3l-5.9-3l-6.4-0.4l-7.6-0.4l-7,0.1l-5.9-3.2
	l-1.4-0.7L477,862l-6.6-0.4l-3.3-0.4l-9.7-4.4l-3-1.8c0,0-1.2-5.5-1.8-5.7c-0.6-0.1-2.8,0.1-2.8,0.1l-2.8-1.1l1.1-5.4l-2.3-4.3l-1-1
	l-2.9,0.3l-4.1-2.6l-0.3-2.1l-2.1-3v-5.1l4.4-1.1l2.5-4.8l-2.2-1.8l-6.9,1.5l-2.2,2.5l-8,2.1l-8.2,4.7l-4.1,0.4l-0.3,1.8h-2.6
	l-1.5-1.5l-7-2.1l-5.3,2.2l-2.3-2.1h-1.7l-3,4l-2.8,0.7l-1.8-1.2l-3.2-0.6l-5.4,1.8l-5.1,0.4l-5.5,6.5l0.4,3.6l-1.8,1.8v4l-2.6,2.3
	l-0.8,3l-4.3-1l-5.3,1.2l-2.1-1.4l-3-0.3l-1.4-1.7l-2.1,1l-1.8-0.4l1.9-2.5l-0.4-1.7l-3.2-0.8l-2.1-2.1l-3.9-0.1l-4.1,2.1l-4.7-0.3
	l-2.8-1.9l-0.4-1.5l1.7-4.3l-3.6-2.9l-0.4-1.8l-3.2-2.1l-1.2,0.4l-1.1-0.1l-1.5,4.1l-1.7,1.4l0.4,1.7l-1.1,0.4l-3.7-0.7l-0.8,2.9
	l-0.1,2.8l-2.9,0.8l-2.5,1.9l-3.3-0.1l-1.8-1.5l-2.2-0.1l-0.1-1.2l-3-2.6h-1.5l-1.4-1.1l-3,0.3l-1.7-2.6l-1.7-0.3l-0.1-2.8v-1.2
	l1.1-2.1l-1.8-2.8l-3-0.1l-2.6-1.5h-2.2l-5.5-2.8l-2.3,0.6l-2.5-0.4l1.4-3.7l-0.1-2.6l-0.4-1.8l-1.9-2.3l-1.2-1.2l0.3-3.9l-1.9-2.2
	l1-1.5l-2.5-2.3l1.9-3.3l2.1-2.5l1.8-4.1l1.9-0.3l2.3-0.8V789l-2.1-2.5l0.8-3.3l-3.9-1.2l-2.1-2.5l-0.7-1.4l1-3.2l3,0.3l7.5-0.4
	l1.7-3l1.2-2.9l3-0.1l1.2,1.5l-0.1,2.6l2.8,1.7l1.7-1.4l1.5,1.9l3.2-1.5l0.8,2.1l2.2,1.5v2.3l0.7,1l1.9-1.4h1.1v2.1l2.1,0.7l1.1,1.5
	l0.4,2.6l-2.3,1.5l1.5,1.2l0.3,4l1.5-1.7l2.6,0.4l1.8-2.3l3,0.8l0.7,2.2l-1.7,3l0.6,2.3l-0.6,1.4l-1.8,0.4l-1,1.2L297.5,802.1
	L297.5,802.1z"
      ></path>
      <g id="Schweiz" class="available">
        <g>
          <g>
            <g class="available">
              <path
                id="ZH_12_"
                class="st1"
                d="M194.9,809.5l-0.9,0.6l-1-0.1l-0.2-0.3l-0.2-0.9l-0.3,0l-1.1-0.1l-0.4-0.2l-0.2-0.5l0-0.7
					l-0.2-0.7l-0.2-0.7l0-0.7l-0.4-0.2l-0.5,0.1l-0.3-0.8l-0.8,0.1l-0.4-0.5l-0.3-0.3l-1.3,0.1l-1-0.1l-0.7,0.2l-0.7,0.6l-1,0.4
					L181,805l-1,0.1l-1.2-0.4l-0.5-0.4l-1.4,0c-0.2-0.3-0.4-0.8-0.5-1.2c-0.1-0.3-0.4-0.6-0.5-0.9c-0.1-0.3-0.3-0.6-0.4-0.9
					c0-0.1-0.1-0.3-0.1-0.3c0.1-0.3,0-0.7-0.1-1c-0.1-0.3-0.3-0.7-0.4-1c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.3-0.2-0.7-0.4-1
					c-0.1-0.2-0.4-0.5-0.5-0.8l1-0.2l0.8-0.3l0.8-0.8l0.6-1l0.6-1l-0.3-0.1l-0.6,0.9l-0.9-0.2l-0.4-0.4l-0.5-0.7l-0.2-2.5l0.2-0.8
					l-2.2-1.1l-0.1-0.7l1.9-2.3l0.2-0.6l-0.2-1l-1.1-0.7l-0.7-0.3l-0.1-0.3l-0.3-1.6l-0.2-0.9l-0.1-0.5l-0.5-0.4l-0.6-1.7l0.7-2.1
					l0.6-1.1v-1.3l1.1-0.9l1.3-1.3l0.7-1.9l0.1-1.2l-0.2-0.2l0.4-0.7c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.6,0.1,0.8,0.1
					c0.4,0,0.9,0,1.3-0.1c0.3-0.1,0.8-0.2,1.1-0.3c0.4-0.1,1.1-0.1,1.4-0.4c0.1-0.1,0.2-0.4,0.4-0.4c0.2-0.1,0.4,0.1,0.6,0.1
					c0.2,0,0.5,0,0.7,0c0.3,0,0.6,0.2,0.8,0.3c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.5,0.2,0.6,0.3c0.2,0.1,0.5,0.4,0.6,0.6
					c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.1,0.6,0.3,0.7c0.1,0.1,0.3,0,0.4-0.1c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.2,0.2-0.4,0.2-0.6
					c0-0.2-0.2-0.6-0.1-0.8c0.1-0.2,0.5-0.2,0.6-0.4c0.2-0.3,0.2-0.8,0.4-1.2c0.2-0.3,0.5-0.7,0.8-1c0.2-0.2,0.5-0.4,0.7-0.6
					c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.3,0.1-0.6,0.1-0.9c0-0.2,0-0.6-0.1-0.8c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.1-0.6-0.1-0.8
					c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0-0.7,0.3-0.7c0.2,0,0.1,0.4,0.2,0.5c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.5-0.2,0.6-0.4c0.1-0.2,0.1-0.6,0.1-0.9c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.4-0.3-0.5-0.4
					c-0.1-0.2-0.2-0.6-0.2-0.8c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.1-0.4,0.1-0.5c0-0.2,0.1-0.5,0.1-0.7
					c0-0.1-0.1-0.2,0-0.3c0.1-0.1,0.4-0.1,0.5-0.1c0.2,0,0.5-0.1,0.7-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.2,0.4,0.3
					c0.1,0.1,0.3,0.2,0.4,0.4c0.2,0.1,0.4,0.3,0.5,0.4c0.1,0.1,0.1,0.2,0.2,0.2l-0.7,0.9l-0.1,0.6l0.5,1.1l0.6,0.9l0.6,0.3l0.6,0.4
					l1.4,0.3l1.3,0l0.9-0.6l0.5-0.5l0.3-0.2l0.3-0.5l0.2-0.3l0.2,0.1l1.2,0.1v0.3l0.8,0.4l1,0.4l0.9,0.3l0.2,0.3l-1.3,2.5l-1.2,1.7
					l-0.6-0.4l0-0.3l-1.5-0.2l-0.8,0l-0.4,0.3l-0.1,0.6l0.1,0.4l0.3,0.8c0.3,0,0.6-0.2,0.8-0.3c0.2,0,0.4-0.1,0.5-0.1
					c0.4,0,1,0.2,1.4,0.4l0.1,1.4l0.9,0.6l1.2,0.4l0.9,0.8l0.4,0.5l-0.1,0.3l-1.2,0l0.2,0.8l0.3,0.7l0.5,0.3l0.8,0l0.6-0.2l0.8,0.4
					l1.4,0.6l0.2,1.1l-0.4,2l-0.6,1.3l-0.2,1.3l0.8,1.5l-0.1,0.9l0.3,0.9l0.6,0.5l0.4,0.4l-0.9,0.3l-0.4,1l-0.6,0.9
					c0.5,0.1,1.2,0.3,1.6,0.5c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.8,0.2,1.1,0.4c0.3,0.1,0.7,1.2,0.7,2.1l0.3,0.8l0.8,1.7l1,1.5
					l0.3,1.9l-0.3,1.2l-1.3,0.2l-0.9,0.9l-0.6,0.7l0.2,0.6l0.4-0.1l0.1,0.4l-0.5,1.2l0,1l-1.8,0.5l-1.5,0.5l-0.7,0.5l-0.5,0.3h-0.7
					l-1.3-0.2l-0.7-0.4l-0.8-0.2l-0.8,0.2l-0.6,0.6l-0.4,0.7l0.2,0.5l0,1.4 M194.9,809.5l-0.1-0.5l-0.4-0.8l0.1-0.6l0.7-0.5l0.7-0.8
					l0.5-0.9l0.2-0.5l0.7-0.1l3-1l2.1-0.1"
              ></path>
              <path
                id="BE_12_"
                class="st1"
                d="M92.8,872.8l0.9-1.2l1.5-1.2l1.1-0.6L98,870l1.1-0.7l-1.1-1.7l0.1-2.2l0.1-1.7l0.2-0.8l1-0.6
					l1.2,0.5l0.5-0.1l1,0.1l0.2-1.6l-0.1-2.5l-0.8-0.8l-1.3-1.2l-0.9-0.6l-1.6-0.6c-0.2-0.2-0.7-0.2-0.8-0.4c-0.1-0.2-0.1-0.6,0-0.9
					c0.1-0.3,0.5-0.6,0.6-0.9c0.1-0.2,0.2-0.5,0.2-0.7c-0.1-0.3-0.5-0.5-0.6-0.8c-0.2-0.4-0.5-0.9-0.5-1.3c0-0.2,0-0.6,0.1-0.8
					c0.2-0.3,0.7-0.3,0.9-0.6c0.1-0.2,0.1-0.6,0.1-0.8c0-0.3,0-0.7,0.1-1c0.1-0.3,0.4-0.6,0.5-0.9c0.1-0.3,0.2-0.6,0.3-0.9
					c0.1-0.3,0.3-0.8,0.5-1.1c0.2-0.3,0.4-0.6,0.6-0.8l-0.2-0.2l-0.7-0.4c0,0-0.8-0.7-0.8-0.9c0.1-0.2,0.3-0.7,0.3-0.7l0.6-0.8
					l0.2,0.5l0.8,0.6l0.3,0.5c0.2-0.5,0.9-0.7,1.2-1.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2-0.4-0.3-0.4-0.4c-0.1-0.2-0.1-0.6-0.1-0.8
					c0-0.2,0.1-0.6,0-0.8c-0.1-0.3-0.4-0.5-0.7-0.7c-0.2-0.1-0.6-0.3-0.9-0.3c-0.3,0-0.7,0-0.9,0.2c-0.1,0.1-0.1,0.4-0.3,0.4
					c-0.2,0.1-0.6-0.2-0.8-0.4c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2,0-0.6,0.1-0.8,0.2c-0.1,0-0.2,0.2-0.3,0.2c-0.3,0.1-0.7-0.1-1-0.2
					c-0.4-0.2-0.8-0.8-1.2-0.9c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0-0.3-0.2-0.4-0.3c-0.2-0.2-0.4-0.6-0.4-0.6l-1.3-0.1l0.3-2.6l-0.1-1.9
					l-0.5-1.9l0.6-0.7l1.3-0.8l-0.2-1.1l-1.1-1.2l-0.7,0.4l-2.9,1.3l-3.1,0.5l-1.4,0.1l-2.2,0.6l-0.2-0.1l-1.2-0.4l-1.7-0.8
					l-1.7-1.5c0,0,0.3-0.6,0.4-0.9c0.1-0.2,0.3-0.6,0.4-0.8c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.2,0.4-0.4,0.6-0.6
					c0.2-0.2,0.6-0.4,0.8-0.5c0.2-0.1,0.6-0.4,0.8-0.5c0.2-0.1,0.5-0.2,0.7-0.3c0.2,0,0.5,0,0.7-0.1c0.2,0,0.7-0.1,0.7-0.1l0.9-0.4
					l0.3-1.5l0.1-1.2l-0.3-1l-2.1-1.4l-2.2-1.2l-0.5-0.3l-1.9,0l-2.7,0.3l-1.6,0.8l-1.4,0.7l-1.1,0.5l-1.1,0.5l-0.2-1.2l0.7-0.9
					l0.3-1.3l-0.1-1.9l-0.6-1.2l1.8-0.2l1-0.7l1.5-1.3l0.9-0.8l1.9,0.2l1-0.1l0.5-0.8l0.1-0.8l1.9-0.3l1.2-1.6l1-2.2L81,801l2.4,0.5
					l4.2-0.3l-0.4-2.1l1.2-0.7l-0.1-1.4l0.5-1.1l1.2-0.2h1.3l1,1.1l1.9,0.4l2.3-0.1l2-0.4l1.7-0.9l1.7-1.4l1.2-1.6l0.5,0.2l0.3,1.6
					l1.4-0.1l2.9,0.3l1.7,0.2l2,0.7l1.1-0.8l1-1.2h2.1l0.6,0.1l-0.7,1.1l-0.6,0.6l-0.5,0.7l-0.7,0.5l-1.9,0.5l-0.9,0.3l-0.8,0.7
					l-0.2,0.9l-0.2,0.3l-0.4,0.1l-1.4,0.3l-1.2,0.1l0,1l-1.1,0.9l-1.3,0.8l-1.9,0.9l-1.7,0.7l0.3,2.2l0.5,0.6l1.1,0.3l0.4,0.2
					l0.4,1.5l0.3,0.5c0.1-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.6-0.2,0.8-0.4c0.2-0.1,0.4-0.4,0.5-0.6
					c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.6,0.1,0.8,0.1c0.2,0,0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.3,0.5-0.4
					c0.2-0.2,0.5-0.3,0.8-0.4c0.2-0.1,0.6,0,0.7-0.2l0.1,1.3l0.1,1.3l1,1.5l-0.6,1.2l-0.5,0.1l-0.9,0l-1.3-0.1l-0.6,0.8l-0.5,0.7
					v0.9l-0.2,0.7l-1.5,0.1l-0.7,0.1l-0.4-0.4l-0.9-0.1l-0.3,0.2l0,0.7l0.3,0.8l0.4,0.9l0.7,1.2l0.2,0.2l0.3-0.2l1-0.7l0.8-0.4
					l0.8-0.2l0,0.7l-0.1,0.6l0.4,0.6l0.9,0l0.9-0.3l0.5-0.4l0.1-0.7l-0.3-0.5l-0.3-0.6l0.1-0.3l1.2-0.6l1-0.6l1-0.6l0.7-1.3l0.3-1.4
					l0.2-1.1l1-0.3l0.7-0.2l0.8,0.2l0.8,0.5l0.7,0.7l1.7,0.3l1.7,0.1l1-0.3l1.2-1l0.7-1l0-0.7l-0.1-0.9l-0.7-0.7l-0.8-0.4l-0.6-0.6
					l-0.5-0.8l-0.8-1.6l0-0.4l-0.2-0.6l-0.7-0.4l-0.4-0.3l-0.6,0l-0.6-0.1v-0.9l-0.3-0.8l0.1-0.7l0-0.5l-0.1-0.6l0.8,0.1l2.4,0.2
					l0.6-0.1l1-0.4l1-0.3l0.6-0.4l1.1,0.1l0.3,0.4l0.9,1.2l0.6,1.4l1.1,0.1l1.1-0.1l1.1-0.5l0.4-0.3l0.6,0.9
					c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.1,0.4-0.1c0.4,0,0.8,0.1,1.2,0.1
					c0.3,0,0.6-0.1,0.9-0.1c0.2,0,0.6,0.1,0.8,0l-0.1,0.3l-0.2,1l0,1.7l0.1,1.1v0.3l-0.1,0.8l0.3,1.3l0.8,1.7l0.6,0.8l0.5,0.8
					l0.1,0.6l-0.2,1.6v1l0.7,1l0.3,0.8l-0.3,0.8l-0.6,0.7l-0.3,0.8l0.2,1.2l0.1,0.7l0,0.9l-0.2,0.8l-0.1,1.4l-0.3,0.9l0.7,0.7
					l0.7,0.9l0.3,0.6l0.1,0.9l0.2,1l1-0.1l1.1,0.1l0.8,0.2l0.2,0.9l0.4,1.2l0.1,0.7l-0.5,1l-0.7,1.1l-0.2,1.1l-0.3,0.7l-0.7,0.8
					l-1.2-0.1l-0.6,0.6l-0.8,0.5l-0.9,0.7l0.6,0.4l0.3,0.7l0,1.6l-0.4,0.8l-1.1,0l0.1,2.3l0.4,1.2l0.3,0.7l1.9,1.3l1.7,1l2,1.7
					l1.3,1.5l1.1,1.8l0.9-0.4l2.1-0.6l1.4-0.2l0.6,0.1l2.5,0.3l0.9,0.2l2.2,1.8l2,0.7l1.7-0.7l0.9-0.3l1.8,0l1.1-0.2l1.5,0.6l1.5,1
					l2-1.5l1.1-1l1.1-0.5l1.3-0.5l0.2-0.4l1.8,1.2l0.4,0.4l0.5,0.6l1.3-1l1.8,0.9l-0.7,1.3l-0.1,0.6l0.1,1.7l0.6,1.1l0.4,1.2l0,1v1
					l-0.3,0.5l-0.6,0.4l-0.8-0.1l-0.8-0.3l-1-0.2l-0.7-0.1l0.1,0.6l0.2,1.1l0.3,0.8l0.6,1l-1.2,1.4l-0.8,1.1l-0.7,1.3l-0.4,1.8v1.7
					l-0.2,1l-1.1,1l-1,0.7l-1.1,0.6l-0.4,0.7l-2.4,1.5l-1.5,0.5l-3.3,0.8l-1.7,0.3l-1.1-1.1l-2-0.4l-1.9-1.2l-1.4-0.5l-1.2,0.1
					l-1.6-0.7l-1.2-0.6l-1,0l-0.9,0.6l-1.2,0.6l-1.4,0.5l-0.2,3.2l-2.2,1.1l-0.7,0.9l-1.7,1.4l-1.8,0.3l-3,0.1l-4.1,3.6l-4.7,3.9
					l-1.4-2.2l-2.5-1.8l-1.8-0.3l-0.8,0.8l-0.7,1.3l-0.9,1.2l-1.6,0h-1.4l-0.7,0.4l-0.6,0.6l1.4,0.9l-0.3,0.5l-0.8,0.9l-1.3,0.6
					l-1.4,0.4l-2.6-0.8l-1.5-0.3c0,0-2.5,0.6-2.7,0.7c-0.2,0.1-2.1,1.6-2.1,1.6l-1.4,1l-1.4,0.9l-0.9-0.3l-0.4-0.7v-2l-1.3-0.2
					l-1.2,0.8l-0.8,0.6l-0.2,1.4l-0.4,1.2l-0.9,0.6l-1.9,0l0.3-0.9l-0.4-2l0.1-0.9l-1.1-1v-2.4l0.6-1l-0.1-1.1l-0.7-1l-0.1-1.1
					l0.4-0.5c0,0,1,0,1.2-0.2c0.2-0.2,0.8-0.8,0.8-0.8s-0.1-1.1,0-1.4c0.1-0.3-0.2-1.7-0.2-1.7l1-1.3l0.7-1.1l-0.1-1.8l-0.2-2
					L92.8,872.8z M123.7,810.6l0.4-0.4l0.5-0.3l0.4,0.1l0.5,0.5l0,0.3l-0.3,0.6c0,0-0.4,0.4-0.5,0.4c0,0-0.7,0.3-0.7,0.3l-0.4,0
					l-0.2-0.3v-0.7L123.7,810.6z"
              ></path>
              <path
                id="LU_12_"
                class="st1"
                d="M149.5,849l0.5-0.9l1-0.9l-0.1-0.5l-1.5-2.3v-0.9l0.4-0.9l0.8-0.6l0.5-0.9l0.2-0.8l0.2-2.3
					l0.5-0.5l0.9-1l0.5-0.6l0.9,0.6l-0.1,0.8l0.6,0.2l0.7-1.4l0.5-1.1l0.8-0.9l1-0.8l-0.8-1.6l1.6-1.3l1.3-0.1l0.6,0.5l1.8-0.7
					l-0.6-2.5l1.9-0.9l1,0.2l1-0.5l1.8,0.7l1.3,0l0.7-0.1l0.3,0.3l0.7-0.5l0.9,0.4l1-0.9l0.7-0.4l0.8,0l0.1,1.4h1.1l0.9-0.2
					c0.2,0,0.5,0,0.8,0c0.2,0,0.5,0,0.7-0.1c0.3,0,0.7,0,0.9,0c0.2,0,0.6-0.2,0.8,0l0.6,0.4l0.6-0.3l1,0.1l0.4-0.1l0.3-0.3l-0.1-2.1
					l-0.5-0.2l-0.5-0.3l-0.5-0.6l-0.9-0.8l-0.5-0.6l-0.5-0.1l-0.5,0.1l-0.8,0l-1-0.2l-1.3-0.3l-0.7-0.5l-0.1-0.2l0.5-0.5l0.4-0.5
					l0.3-0.8l0-0.2l1.1-1l1.2-1.6l0.6-0.4l0.6-0.4l-0.1-0.9l-0.1-0.5l-0.3,0.1l-0.5,0.4l-0.6-0.2l-0.6-0.9l-0.9-1.5l-1.5,0.5
					l-1.7,0.4l-0.7,0.1l-0.5-0.8l-0.7-1.3l-0.6-1.3l-0.7-1.9l-0.2-1.4l-0.6-1.7l-0.7-2.2l-0.3-1.5l-0.1-1l-0.2-0.7l-0.2-0.1
					l-1.4-0.4l-0.3-0.6l-1.1-0.2l-0.7,0l-1.4,1.9l-0.8,0.5l-0.3,0.8l-0.2,1.3l-0.1,0.8l-0.3,0.7l-0.6,0.5l-0.6,0.1l-0.7-0.2
					l-0.6-0.5l-0.1-0.4v-0.5l0.8-0.4l-0.1-0.5l-0.6,0.1l-1.6,0.2l-1.7,0.1l-0.8-0.2l0-0.9l-0.3-0.7l-0.1-0.2l-0.5-0.1L152,800
					l-0.8,0.2l-0.4,0.3l-0.2,0.7l-0.2,0.7l-0.8,0.2l-0.8,0.1l-0.9-0.1h-0.6l0.1-0.4l0.1-1.2v-0.9l-0.2-0.6l-0.5-0.9l-0.6,0.2
					l-0.9,0.3h-0.5h-0.6l-0.4-0.4l-0.4-0.1l0,0.7v2.8l-0.2,0.3l-0.3,0.3l-0.4,0.2l-1.3-0.1l-0.6-0.3l-1-0.1l-1.4,0.2l-0.6,0.2
					l-0.6,0.4l-1.3,0.1l0,0.3l-0.1,0.8l0.3,1.3l0.8,1.7l0.6,0.8l0.5,0.8l0.1,0.6l-0.2,1.6v1l0.7,1l0.3,0.8l-0.3,0.8l-0.6,0.7
					l-0.3,0.8l0.2,1.2l0.1,0.7l0,0.9l-0.2,0.8l-0.1,1.4l-0.3,0.9l0.7,0.7l0.7,0.9l0.3,0.6l0.1,0.9l0.2,1l1-0.1l1.1,0.1l0.8,0.2
					l0.2,0.9l0.4,1.2l0.1,0.7l-0.5,1l-0.7,1.1l-0.2,1.1l-0.3,0.7l-0.7,0.8l-1.2-0.1l-0.6,0.6l-0.8,0.5l-0.9,0.7l0.6,0.4l0.3,0.7
					l0,1.6l-0.4,0.8l-1.1,0l0.1,2.3l0.4,1.2l0.3,0.7l1.9,1.3l1.7,1l2,1.7l1.3,1.5l1.1,1.8l0.9-0.4l2.1-0.6L149.5,849z"
              ></path>
              <path
                id="UR_12_"
                class="st1"
                d="M208.3,845.9l-1.3,1.3l-0.9,0.1l-0.9,0.4l-0.7,0.5l0.4,1.6l-0.5,1.4l-1,1l-0.3,1.4l-0.9,0.8
					l-1.1-0.1l-0.6-1l-1.1,0.1l-0.2,0.8l-0.5,1.6l-1.3,0.7l-1.1,1.1l-1.6,0.1l-0.9,0.5l-0.5,1.1l0.1,0.7l0.7,1.4l-0.6,0.4l-0.8,1.1
					v2l0.9,1.2l1.1,1.6l-0.4,2l-0.5,0.8l-1,0.8l-1.4,0.1l-0.4-0.5l-0.8-0.4l-2.4-0.2l-0.7-0.5l-1.4-0.3l-1.7,0.3l-1,1l-0.4,1.1
					l0.2,1.3l-0.1,0.8l-0.4,0.5l-1.6,0.5l-1.2,0.1l-0.9-0.1l-0.7-0.4l-0.7-0.4l-0.4-0.6l-0.4-0.9l-0.3-0.7l-0.8-1.3l-0.3-0.6
					l0.5-1.6l0.7-1.8l0.2-3.4l-0.1-0.7l-0.7-0.5l-0.6-0.2l-0.6-1l-0.3-0.8l-0.2-1.1l-0.1-0.6l0.7,0.1l1,0.2l0.8,0.3l0.8,0.1l0.6-0.4
					l0.3-0.5v-1l0-1l-0.4-1.2l-0.6-1.1l-0.1-1.7l0.1-0.6l0.7-1.3l0.9-0.1l0.5,0.1l0.6,0.1l0.3,0.2l1-0.9l-0.1-0.3l-0.5-0.8l-0.5-0.4
					l0.1-0.6l-1.3-0.3l0-0.3l0.6,0l0.4-0.1l0.2-0.5l-0.3-0.5l-0.4-0.3l0.5-1.4l0.8-0.9l1.2-0.6l-0.1-0.8l-0.7-0.2l-0.4-0.2l-1.4,0
					l0-2.1l0.5-1.3l-0.2-0.4l-0.2,0.1l-0.1-0.5l-0.6,0l0.8-1.1l0.8-0.4l1.2-0.4l1.1-0.6l0.7-0.6l0.4-0.2l1-0.7l0.8-1l1.2-0.8
					l0.4-0.5l-0.1-0.9l0.1-0.8l0.5-0.6l0.4-0.5l-0.1-0.4l-0.2-0.6l0.8-0.3l0.7,1l0.1,1.7l0.2,1.8l1.5-0.1l1,0.4l0.5,0.1l0.9-0.1
					l1.9-0.5l0.3,2.2l-0.1,0.8l0.5,0.8l0.8-0.4l0.6-0.3l1-1l0.8-0.4l1.2-0.3l0.4,0.1l0.6,0.6l0.4-0.1l0.6-0.5l0.4-0.3l0.8-0.1l1,0.3
					l0.4,0.6l-0.1,1l-0.3,1l0.3,0.7l0.9-0.6l0.2,2.6l0.5-0.4l0.3-0.6l0.5-0.4l1.9-0.6l2.9-1.6l0.7,0.8l0.8,1.4l0.2,1.1l0,0.9
					l-0.7,0.9l-1.2,0.8l-1.6,0.7l-1.4,0.4l-1.2,0.3l0,0.4l0.1,0.5l0.5,1.3L208.3,845.9"
              ></path>
              <path
                id="SZ_12_"
                class="st1"
                d="M211.9,835.1l-2.9,1.6l-1.9,0.6l-0.5,0.4l-0.3,0.6l-0.5,0.4l-0.2-2.6l-0.9,0.6l-0.3-0.7l0.3-1
					l0.1-1l-0.4-0.6l-1-0.3l-0.8,0.1l-0.4,0.3l-0.6,0.5l-0.4,0.1l-0.6-0.6l-0.4-0.1l-1.2,0.3l-0.8,0.4l-1,1l-0.6,0.3l-0.8,0.4
					l-0.5-0.8l0.1-0.8l-0.3-2.2l-1.9,0.5l-0.9,0.1l-0.5-0.1l-1-0.4l-1.5,0.1l-0.2-1.8l-0.1-1.7l-0.7-1l-0.8,0.3l-2.3,0.9l-2.4,0.4
					l-2.9-0.9l-0.2-0.8l0.6-0.3l1,0.1l0.4-0.1l0.3-0.3l-0.1-2.1l-0.5-0.2l-0.5-0.3l-0.5-0.6l-0.9-0.8l-0.5-0.6l-0.5-0.1l-0.5,0.1
					l-0.8,0l-1-0.2l-1.3-0.3l-0.7-0.5l-0.1-0.2l0.5-0.5l0.4-0.5l0.3-0.8l0-0.2l1.1-1l1.2-1.6l0.6-0.4l0.6-0.4l0.8-0.4l0.9-0.5
					l0.5-0.1l0.5,1.6l0.3,0.7l0.5,0.2l1.3,0.5l0.8-0.3l0.9-0.1l1.1,0l0.2,1.1l0.9,0l0.8-0.4l0.7-0.5l0.7-0.2l0.5-0.3l1.1,0.3
					l0.9,0.2l0.4-0.2l0-0.4l0.1-0.9l0.4-0.5l0.6-0.5l0.4-0.7l0.2-1.3l0.6-1.5l-0.1-0.4l-0.2-0.9l-0.1-0.5l-0.4-0.8l0.1-0.6l0.7-0.5
					l0.7-0.8l0.5-0.9l0.2-0.5l0.7-0.1l3-1l2.1-0.1l2.6,1.1l3.9,0l2.5-0.4l1.8-0.6l0.6,0.6l0.5,0.3l0.1,2.5l0.2,0.7l0.8-0.1l0.9,0.2
					l-0.3,0.6l-2.2,4.9l0.7,0.7l0.3,0.9l-0.2,0.5l-0.5,0.3l-0.7,0.6l-0.1,0.5l0.4,0.4l0.6,0.1l0.5,0.1l-0.7,1.9l-0.9,1.6l-1.4,1.2
					l-0.8-0.1l-0.6-0.1l-0.6,0.1l0.1,1.5l-0.5,0.4l0.4,0.5l0.5,0.4l0.5,0.8l0.2,0.4l0.3-0.1l0.1,0.4l0.7,0.8l0.4,0.4l-0.4,0.5
					l0.3,0.5l0.8,1l1,0.9l0.6,0.4l-0.6,0.5l-0.4,0.6l-0.1,0.5l0.1,0.4l0.2,0.5l0.2,0.2l-0.8,0.3l-0.3,0.4L211.9,835.1z"
              ></path>
              <path
                id="OW_12_"
                class="st1"
                d="M172.1,848.6l-3.1-2.6l0.4-0.6l0.5-1.1l-0.1-1.2v-1.5l0.3-2.6l0.2-1.8l0.3-2.1l-0.7-0.1
					l-0.9,0.3l-1-0.5l-0.6-0.3l-0.3-0.9l-0.1-0.5l0.4-0.7l0.8-1.6l-0.1-0.8l-1.3-0.5l-2.3-0.5l-2.2,0.7l-1.8,0.7l-0.6-0.5l-1.3,0.1
					l-1.6,1.3l0.8,1.6l-1,0.8l-0.8,0.9l-0.5,1.1l-0.7,1.4l-0.6-0.2l0.1-0.8l-0.9-0.6l-0.5,0.6l-0.9,1l-0.5,0.5l-0.2,2.3l-0.2,0.8
					l-0.5,0.9l-0.8,0.6l-0.4,0.9v0.9l1.5,2.3l0.1,0.5l-1,0.9l-0.5,0.9l0.6,0.1l2.5,0.3l0.9,0.2l2.2,1.8l2,0.7l1.7-0.7l0.9-0.3l1.8,0
					l1.1-0.2l1.5,0.6l1.5,1l2-1.5l1.1-1l1.1-0.5l1.3-0.5L172.1,848.6z M179.4,841.8l1.4,0l0.4,0.2l0.7,0.2l0.1,0.8l-1.2,0.6
					l-0.8,0.9l-0.5,1.4l0.4,0.3l0.3,0.5l-0.2,0.5l-0.4,0.1l-0.6,0l0,0.3l1.3,0.3l-0.1,0.6l0.5,0.4l0.5,0.8l0.1,0.3l-1,0.9l-0.3-0.2
					l-0.6-0.1l-0.5-0.1l-0.9,0.1l-1.8-0.9l-1.3,1l-0.5-0.6l1-0.8l0.2-0.5v-0.4l-0.4-0.4l-0.5-0.2l-0.8-0.6l-0.5-0.8l-0.5-0.7
					l-0.3-0.8l-0.2-1.4l-0.1-1.2l-0.1-1.1l0.3-0.5h0.5l0.5,0.4l0,0.3l-0.5,0.2l0,0.3l0.6,0.2l0.5-0.1l0.7-0.4l0.6-0.7l1,1
					L179.4,841.8z"
              ></path>
              <path
                id="NW_12_"
                class="st1"
                d="M179.4,841.8l0-2.1l0.5-1.3l-0.2-0.4l-0.2,0.1l-0.1-0.5l-0.6,0l0.8-1.1l0.8-0.4l1.2-0.4
					l1.1-0.6l0.7-0.6l0.4-0.2l1-0.7l0.8-1l1.2-0.8l0.4-0.5l-0.1-0.9l0.1-0.8l0.5-0.6l0.4-0.5l-0.1-0.4l-0.2-0.6l-2.3,0.9l-2.4,0.4
					l-2.9-0.9l-0.2-0.8l-0.6-0.4c-0.2-0.2-0.6,0-0.8,0c-0.3,0-0.7,0-0.9,0c-0.2,0-0.5,0-0.7,0.1c-0.2,0-0.5,0-0.8,0l-0.9,0.2H174
					l-0.1-1.4l-0.8,0l-0.7,0.4l-1,0.9l-0.9-0.4l-0.7,0.5l-0.3-0.3l-0.7,0.1l-1.3,0l-1.8-0.7l-1,0.5l-1-0.2l-1.9,0.9l0.6,2.5l2.2-0.7
					l2.3,0.5l1.3,0.5l0.1,0.8l-0.8,1.6l-0.4,0.7l0.1,0.5l0.3,0.9l0.6,0.3l1,0.5l0.9-0.3l0.7,0.1l-0.3,2.1L170,839l-0.3,2.6v1.5
					l0.1,1.2l-0.5,1.1l-0.4,0.6l3.1,2.6l1.8,1.2l0.4,0.4l1-0.8l0.2-0.5v-0.4l-0.4-0.4l-0.5-0.2l-0.8-0.6l-0.5-0.8l-0.5-0.7l-0.3-0.8
					l-0.2-1.4l-0.1-1.2l-0.1-1.1l0.3-0.5h0.5l0.5,0.4l0,0.3l-0.5,0.2l0,0.3l0.6,0.2l0.5-0.1l0.7-0.4l0.6-0.7l1,1L179.4,841.8z"
              ></path>
              <path
                id="GL_12_"
                class="st1"
                d="M216.2,808.1c0.2,0.6,0.9,1.2,1.4,1.6c1,0.9,2.4,1.7,3.5,2.5c0.2,0.2,0.5,0.4,0.7,0.5
					c0.3,0.2,0.7,0.5,1.1,0.5c0.4,0.1,0.8-0.3,1.2-0.4c0.3-0.1,1-0.3,1-0.3l4,1.2v1l0.1,1.1l0.1,2.4l0.1,0.9l-1,1.3l-0.8,0.3
					l-1.1,0.4l-0.3,0.2l-0.1,0.6l0.2,0.6l0.4,0.5l0.8,0.3l1.7,0.1l0.7-0.3l0.7-0.4l0.7-0.2l0.6,0.2l0.9,0.6l0.3,1.6l0.4,2.3l0.1,3.3
					l-0.7,0.8l-0.3,0.3l0.7,0.6l0.6,0.7l0.5,1.2l-0.2,1l-1,0.2l-0.9,0.9l-0.9,1l-0.5,0.7l-0.4,1.1l-0.7-0.2l-1.6,0.1l-0.8,0.2
					l-0.6,0.6l-0.8,0.9l-0.9,0.7l-0.5,0.4l-0.6-0.3l-0.4-0.7l-0.7-1l-0.9-0.4l-0.9,0.1l-0.9,0.5c0,0-0.2,0.9-0.3,1
					c0,0.1-0.6,1.3-0.6,1.3l-0.4,1.6l-0.2,1l-0.8,0.9l-1.2,0.4l-2.2,0.4l-1,0.3l-1.3,0.6H211l-0.4-0.9l-1.2-0.3l-1.1-0.1l-0.3-1.2
					l-0.5-1.3l-0.1-0.5l0-0.4l1.2-0.3l1.4-0.4l1.6-0.7l1.2-0.8l0.7-0.9l0-0.9l-0.2-1.1l-0.8-1.4l-0.7-0.8l1-0.5l0.3-0.4l0.8-0.3
					l-0.2-0.2l-0.2-0.5l-0.1-0.4l0.1-0.5l0.4-0.6l0.6-0.5l-0.6-0.4l-1-0.9l-0.8-1l-0.3-0.5l0.4-0.5l-0.4-0.4l-0.7-0.8l-0.1-0.4
					l-0.3,0.1l-0.2-0.4l-0.5-0.8l-0.5-0.4l-0.4-0.5l0.5-0.4l-0.1-1.5l0.6-0.1l0.6,0.1l0.8,0.1l1.4-1.2l0.9-1.6l0.7-1.9l-0.5-0.1
					l-0.6-0.1l-0.4-0.4l0.1-0.5l0.7-0.6l0.5-0.3l0.2-0.5l-0.3-0.9l-0.7-0.7l2.2-4.9L216.2,808.1z"
              ></path>
              <path
                id="ZG_12_"
                class="st1"
                d="M194.9,809.5l-0.9,0.6l-1-0.1l-0.2-0.3l-0.2-0.9l-0.3,0l-1.1-0.1l-0.4-0.2l-0.2-0.5l0-0.7
					l-0.2-0.7l-0.2-0.7l0-0.7l-0.4-0.2l-0.5,0.1l-0.3-0.8l-0.8,0.1l-0.4-0.5l-0.3-0.3l-1.3,0.1l-1-0.1l-0.7,0.2l-0.7,0.6l-1,0.4
					L181,805l-1,0.1l-1.2-0.4l-0.5-0.4l-1.4,0c-0.2-0.3-0.4-0.8-0.5-1.2c-0.1-0.3-0.4-0.6-0.5-0.9c-0.1-0.3-0.3-0.6-0.4-0.9
					c0-0.1-0.1-0.3-0.1-0.3c-0.1,0.3-0.3,0.6-0.4,0.8c-0.1,0.3-0.4,0.6-0.4,0.9c0,0.4,0.4,0.8,0.5,1.2c0.1,0.2,0.2,0.5,0.3,0.7
					c0.1,0.3,0.1,0.8,0.1,1.2c0,0.4-0.1,0.9,0,1.3c0,0.3,0.2,0.6,0.3,0.9c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.2,0.4,0.5,0.4,0.7
					c0,0.3-0.3,0.6-0.4,0.8c-0.1,0.4-0.1,1-0.2,1.3l0.9,1.4l0.6,0.9l0.6,0.2l0.5-0.4l0.3-0.1l0.1,0.5l0.1,1l0.8-0.4l0.9-0.5l0.5-0.1
					l0.5,1.6l0.3,0.7l0.5,0.2l1.3,0.5l0.8-0.3l0.9-0.1l1.1,0l0.2,1.1l0.9,0l0.8-0.4l0.7-0.5l0.7-0.2l0.5-0.3l1.1,0.3l0.9,0.2
					l0.4-0.2l0-0.4l0.1-0.9l0.4-0.5l0.6-0.5l0.4-0.7l0.2-1.3l0.6-1.5l-0.1-0.4L194.9,809.5z"
              ></path>
              <path
                id="SO_12_"
                class="st1"
                d="M116.3,793.8l-0.7,1.1l-0.6,0.6l-0.5,0.7l-0.7,0.5l-1.9,0.5l-0.9,0.3l-0.8,0.7l-0.2,0.9
					l-0.2,0.3l-0.4,0.1l-1.4,0.3l-1.2,0.1l0,1l-1.1,0.9l-1.3,0.8l-1.9,0.9l-1.7,0.7l0.3,2.2l0.5,0.6l1.1,0.3l0.4,0.2l0.4,1.5
					l0.3,0.5c0.1-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.6-0.2,0.8-0.4c0.2-0.1,0.4-0.4,0.5-0.6
					c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.6,0.1,0.8,0.1c0.2,0,0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.3,0.5-0.4
					c0.2-0.2,0.5-0.3,0.8-0.4c0.2-0.1,0.6,0,0.7-0.2l0.1,1.3l0.1,1.3l1,1.5l-0.6,1.2l-0.5,0.1l-0.9,0l-1.3-0.1l-0.6,0.8l-0.5,0.7
					v0.9l-0.2,0.7l-1.5,0.1l-0.7,0.1l-0.4-0.4l-0.9-0.1l-0.3,0.2l0,0.7l0.3,0.8l0.4,0.9l0.7,1.2l0.2,0.2l0.3-0.2l1-0.7l0.8-0.4
					l0.8-0.2l0,0.7l-0.1,0.6l0.4,0.6l0.9,0l0.9-0.3l0.5-0.4l0.1-0.7l-0.3-0.5l-0.3-0.6l0.1-0.3l1.2-0.6l1-0.6l1-0.6l0.7-1.3l0.3-1.4
					l0.2-1.1l1-0.3l0.7-0.2l0.8,0.2l0.8,0.5l0.7,0.7l1.7,0.3l1.7,0.1l1-0.3l1.2-1l0.7-1l0-0.7l-0.1-0.9l-0.7-0.7l-0.8-0.4l-0.6-0.6
					l-0.5-0.8l-0.8-1.6l0-0.4l-0.2-0.6l-0.7-0.4l-0.4-0.3l-0.6,0l-0.6-0.1v-0.9l-0.3-0.8l0.1-0.7l0-0.5l-0.1-0.6l0.8,0.1l2.4,0.2
					l0.6-0.1l1-0.4l1-0.3l0.6-0.4l1.1,0.1l0.3,0.4l0.9,1.2l0.6,1.4l1.1,0.1l1.1-0.1l1.1-0.5l0.4-0.3l0.6,0.9
					c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.1,0.4-0.1c0.4,0,0.8,0.1,1.2,0.1
					c0.3,0,0.6-0.1,0.9-0.1c0.2,0,0.6,0.1,0.8,0c0.3-0.1,0.5-0.5,0.7-0.7c0.3-0.3,0.5-0.9,0.7-1.2c0.1-0.2,0.2-0.5,0.4-0.7
					c0.2-0.2,0.5-0.4,0.7-0.7c0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.6-0.4,0.7-0.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.3-0.3-0.8-0.2-1.1
					c0-0.2,0.2-0.4,0.3-0.6l0.6,0.4l0.6,0.5l0.8,0.3l1,0.2l1.9,0.2l1.2,0.1l0.5-0.1l0.4-0.2l0.4-0.6l0.3-1.1l0.6-1.7l0.6-1.2
					l0.6-1.4l-0.1-0.7l-0.9-0.8l-0.8-0.1l-0.6-0.3l-0.6-0.4l-1-0.6l-0.6-0.6l0.8-0.5l0-0.8l-0.2-1l-0.5-0.1l-0.2-0.8l0-0.6l-0.7-0.1
					l-0.4-0.1l-0.2,0.7l-0.3,0.7l-0.1,1l0.5,0.6c0,0,0.3,0.7,0.4,0.7l-0.1,0.8l-1.3,1l-1.1,0.5l-1.4-0.2l-0.9,0.2l-0.8,1l-1.2,1.2
					l-1,0.6l-1.1,0.5l-1.5,0.6l-1,0.7l-0.1,0.8v0.7l-0.7,0.1l-0.8-0.1l-0.8-0.3l-0.6-0.5l-0.7-0.6l-0.6-0.8l-0.3-0.7l-0.7-0.4
					l-1.9,0.3l-1.8,0.1h-1.2l-0.3-1.3l-0.2-1.2l-0.1-1.6l1.6-0.1l0.6-0.3l0.7-0.4l0.3-0.8l0.1-1.3l0.2-1l0.8-0.8l0.6-0.7l0.7-1.4
					l0.1-0.4l-0.9-0.4l-1,0.1l-1.4-0.2l-0.6-0.9l-0.4-0.1l-0.7,0.8l-0.8,0l-1.5,0l0,1.1l0.6,0.8l0.2,0.8l-0.4,1.2l-0.5,0.9l-0.1,0.9
					l-2.3,0.2l-0.3,1.2l-2.4,0.3l-0.4,1.1l-0.2,0.6l-1.1,0.3l-0.9,0.7l-1.1-0.7l-0.1-0.7l-1.1,0l-1,0l-0.5,0.3l-0.3,0.6l-0.1,0.5
					l0.9,1.6l2,0.4l1.7-0.4l1.4,0.1v1.4l0.1,1l0.5,1.1l0.2,0.5l2.2,0L116.3,793.8 M105.6,780.4l0.4,0.2l0.5-0.2l0.3,0.4l0.9,0.7
					l0.8,0.8l0.4,0.7l0,0.8l-0.2,0.6l-0.3,0.3l-0.7,0.1l-0.9-0.4l-1.2-0.3l-2.2-0.3l-0.2-1.6l0,0l1.2-0.6L105.6,780.4z M123.7,810.6
					l0.4-0.4l0.5-0.3l0.4,0.1l0.5,0.5l0,0.3l-0.3,0.6c0,0-0.4,0.4-0.5,0.4c0,0-0.7,0.3-0.7,0.3l-0.4,0l-0.2-0.3v-0.7L123.7,810.6z
					 M111.5,775.6l0.6,0l0.7,0.4l0,0.5l0,0.4l-0.5,0.7l-0.2,0.5l-0.4,0.4l-0.4,0l-0.7,0l-0.6-0.1l-0.9,0.4l-0.7,0.1l-0.6-0.2
					l-0.4-0.2l-0.3-0.4l0.2-0.2l0.5-0.5l-0.8-0.7l-0.7-0.5l-0.6-0.2l0.7-0.9l0.6-0.1l0.6,0.3l0.8,0.8c0,0,0.2,0.5,0.7,0.6
					c0.5,0.1,1.6-0.4,1.6-0.4L111.5,775.6z"
              ></path>
              <path
                id="FR_12_"
                class="st1"
                d="M71.9,832.1l1.9,1.6l0.1,0.1l1,0.5l0.4,1.5l0.5,1.3l1.3,1.1l0.7,0.8l0.8,0.6l0.1,1.6l-0.5,1.3
					l0.6,0.4l0.6-0.7l1-0.9l0.5-1.1l0.2-1.7l1.2-0.9l1-0.8l-0.9-1.9l-1.4-2.5l-0.4-0.5v-1.7l0.3-1.2l2.2-0.6l1.4-0.1l3.1-0.5
					l2.9-1.3l0.7-0.4l1.1,1.2l0.2,1.1l-1.3,0.8l-0.6,0.7l0.5,1.9l0.1,1.9l-0.3,2.6l1.3,0.1c0,0,0.3,0.5,0.4,0.6
					c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0,0.4-0.1,0.5-0.1c0.4,0.1,0.8,0.7,1.2,0.9c0.3,0.1,0.7,0.2,1,0.2c0.1,0,0.2-0.1,0.3-0.2
					c0.2-0.1,0.5-0.2,0.8-0.2c0.2,0,0.4,0.2,0.5,0.3c0.2,0.1,0.5,0.4,0.8,0.4c0.1,0,0.1-0.4,0.3-0.4c0.2-0.2,0.6-0.2,0.9-0.2
					c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.6,0.4,0.7,0.7c0.1,0.2,0,0.6,0,0.8c0,0.2,0,0.6,0.1,0.8c0.1,0.2,0.4,0.3,0.4,0.4
					c0,0.1-0.1,0.3-0.2,0.4c-0.3,0.4-1,0.7-1.2,1.1l-0.3-0.5l-0.8-0.6l-0.2-0.5l-0.6,0.8c0,0-0.2,0.5-0.3,0.7
					c-0.1,0.2,0.8,0.9,0.8,0.9l0.7,0.4l0.2,0.2c-0.2,0.2-0.5,0.5-0.6,0.8c-0.2,0.3-0.4,0.7-0.5,1.1c-0.1,0.3-0.2,0.6-0.3,0.9
					c-0.1,0.3-0.4,0.6-0.5,0.9c-0.1,0.3-0.1,0.7-0.1,1c0,0.2,0,0.6-0.1,0.8c-0.2,0.3-0.7,0.3-0.9,0.6c-0.1,0.2-0.1,0.6-0.1,0.8
					c0.1,0.4,0.3,0.9,0.5,1.3c0.1,0.3,0.6,0.5,0.6,0.8c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.3-0.5,0.6-0.6,0.9c-0.1,0.3-0.1,0.7,0,0.9
					c0.1,0.2,0.6,0.2,0.8,0.4l1.6,0.6l0.9,0.6l1.3,1.2l0.8,0.8l0.1,2.5l-0.2,1.6l-1-0.1l-0.5,0.1l-1.2-0.5l-1,0.6l-0.2,0.8l-0.1,1.7
					l-0.1,2.2l1.1,1.7L98,870l-1.7-0.2l-1.1,0.6l-1.5,1.2l-0.9,1.1l-2.1,1.1l-1.2-0.6l-1.1,0.9l-0.9,0.5l-1.1,0.6l-0.8,1.3l-1.3,1.4
					l-1.2,0.7l-2,0.2l-1.2,0.3l-1.1,1c0,0-1.1,1.1-1,1.4c0.1,0.3-1,1.5-1,1.5l-1,0.8l-1.3,0.4l0.2-0.7l-0.4-1.6l-0.5-2.2l-0.1-1.2
					l-1.5-0.4l-1.3-0.6l-1.4-1.2l-0.8-0.8l-0.4-0.2l-1.2,1.1l-0.6,1l-1.2,0.7l-1.1-0.2l-0.5-1.1l-0.3-0.6l-1-0.6l0.2-1L63,874
					l1.2,0.1l1,1l1.1,0.3l-0.1-0.8l0.3-0.6l0.5-0.1l0.4-0.5l0.6-0.8l1-0.8l0.3-0.5l-0.5-0.4l-1-0.2l-1-0.4l-1-0.9l-0.9,0.2l-1.1,0.6
					l-0.4-0.8l-0.6,0.2l-1,0.4l-0.2-0.7v-2.8l-0.3-1.8v-2l0.5-0.2l1.1-1.1l0.8-0.6l0.7,0.1l0.5,0.4l1.1-0.1l0.4-0.8l0.1-1.5l2-2.1
					l2.6-3.3l0.2-1.2l-0.8-0.7l0.3-0.8l1.4-1.6l0.8-0.9l0.5-1.8l0.3-1.3l1.3-1l0.1-2l-0.5-1.1v-1.7l-0.7-0.3l-1.3-1.1l-1.5-1.7
					l-1.7-2.4L71.9,832.1z M67,836.2l1.7,1.5l0.7-0.1l0.8,0.8l-1.6,0.9l0.1,0.3l1.1-0.4l1.1,1.6l-0.8,0.7l-0.7,1.7l-0.1,0.6
					l-0.6,1.5l0.8,0.1l0.2-0.6l0.6-0.1l0.4,0.2l-0.3,0.7v1.2l-0.3,1.2l-0.7,0.7h-1l-1.2-0.5l-0.9-0.6l-0.7,0.8l-0.7,0.4l-0.7-0.1
					l-1.6-0.7l-1.5-1.1l0.5-0.3l-1.3-1l-0.6-0.5l-0.2-3.5L67,836.2z M59.5,852.3l-0.6,1l0.2,0.9l0.7,0.6l0.6-0.5l0.3-1.1l-0.1-1
					L59.5,852.3z M62.3,853.1l0.8,0.1l0.7,0.5l-0.6,0.9l-0.7,0.3l-0.3-0.5L62.3,853.1z M63.3,852.8l1.2-1.6l0.7-1.2l-0.2-0.5
					l0.5-0.3l0.7,1l1,1l0.3,0.5l-0.9,1.7l-0.6,1l-0.7-0.1l-0.7-0.7L63.3,852.8z"
              ></path>
              <path
                id="BS_12_"
                class="st1"
                d="M116.3,768.9l0.9,0.4l0.1,1.4l0.7-0.1l0.6,0.7l1-0.2l0.5-0.5l-0.2-1.5l0.8-0.6l0.5-0.6l0.3-0.7
					l0.2-0.4l0.7,0.1l0.6-0.5V766l-0.1-0.3l-0.5-0.2l-0.1-0.7l0.6-0.6l0.1-0.4l-0.5-0.1l-0.7,0.5l-0.7,0.3l-0.9,0.4l-1,0.1l-0.8,0.4
					l-1.1,1.2l-0.2,1.3L116.3,768.9z"
              ></path>
              <path
                id="BL_12_"
                class="st1"
                d="M103.4,783.7l0.1,0.6l0.3,0.6l1.7,1.5l0.5,0.8l0.9,0.2l0.6-0.1l0.1-0.5l0.3-0.6l0.5-0.3l1,0
					l1.1,0l0.1,0.7l1.1,0.7l0.9-0.7l1.1-0.3l0.2-0.6l0.4-1.1l2.4-0.3l0.3-1.2l2.3-0.2l0.1-0.9l0.5-0.9l0.4-1.2l-0.2-0.8l-0.6-0.8
					l0-1.1l1.5,0l0.8,0l0.7-0.8l0.4,0.1l0.6,0.9l1.4,0.2l1-0.1l0.9,0.4l-0.1,0.4l-0.7,1.4l-0.6,0.7l-0.8,0.8l-0.2,1l-0.1,1.3
					l-0.3,0.8l-0.7,0.4l-0.6,0.3l-1.6,0.1l0.1,1.6l0.2,1.2l0.3,1.3h1.2l1.8-0.1l1.9-0.3l0.7,0.4l0.3,0.7l0.6,0.8l0.7,0.6l0.6,0.5
					l0.8,0.3l0.8,0.1l0.7-0.1v-0.7l0.1-0.8l1-0.7l1.5-0.6l1.1-0.5l1-0.6l1.2-1.2l0.8-1l0.9-0.2l1.4,0.2l1.1-0.5l1.3-1l0.1-0.8
					c-0.1,0-0.4-0.7-0.4-0.7l-0.5-0.6l0.1-1l0.3-0.7l0.2-0.7l-0.9-0.5l-0.2-1.1l0.1-0.7l-0.9-0.1l-0.8,0l-0.6-0.3l-0.8-1l-0.3-1.3
					l-1-1.7l-0.8-1.1l-0.7-0.8l-0.3-0.4l-0.5-0.3l-0.5,0l-0.5,0.3l-0.2,0.5l-0.2,0.8l-0.2,0.6l-0.2,0.8l-0.7,0.7l-0.7,0.5l-1.1,0.1
					l-0.1-0.7l0.1-0.5l-0.2-0.4l-0.5-0.2l-1-0.9l-0.9-0.5l-0.9-0.3l-0.1-0.5c-0.3,0.1-0.7,0.1-1,0.3c-0.2,0.1-0.4,0.3-0.5,0.4
					c-0.3,0.2-0.8,0.3-1.2,0.4c-0.3,0.1-0.6,0.3-0.9,0.3c-0.3,0-0.6-0.2-0.8-0.3c-0.3-0.1-0.7-0.3-0.9-0.4c-0.2-0.2-0.4-0.4-0.5-0.6
					c-0.2-0.2-0.5-0.5-0.6-0.8c-0.2-0.3-0.2-0.7-0.4-0.9l-0.8,0.6l0.2,1.5l-0.5,0.5l-1,0.2l-0.6-0.7l-0.7,0.1l-0.1-1.4l-0.9-0.4
					l-0.9,0.4l-0.9,0.1l-1,0.2l-1.1,0.4l-0.9,1.1l0.2,1.4l0.6,0.7v1.2l-0.7,1.1c0.4,0,0.4,0,0.6,0l0.7,0.4l0,0.5l0,0.4l-0.5,0.7
					l-0.2,0.5l-0.4,0.4l-0.4,0l-0.7,0l-0.6-0.1l-0.9,0.4l-0.7,0.1l-0.6-0.2l-0.4-0.2l-0.3-0.5l-0.5,0.4l-0.5,0.5l-0.6,1.3l0.4,0.2
					l0.5-0.2l0.3,0.4l0.9,0.7l0.8,0.8l0.4,0.7l0,0.8l-0.2,0.6l-0.3,0.3l-0.7,0.1l-0.9-0.4l-1.2-0.3L103.4,783.7z M103.2,782.1
					l0.2,1.6l-1.8-0.2l-1.3-1.2l0-0.9c0.3,0,0.7,0.3,1.1,0.3c0.4,0.1,0.9,0,1.3,0.1C102.9,781.9,103,782,103.2,782.1z"
              ></path>
              <path
                id="SH_12_"
                class="st1"
                d="M188.4,764.7c-0.3,0.3-0.6,0.6-0.8,1c-0.2,0.3-0.1,0.9-0.4,1.2c-0.1,0.2-0.5,0.2-0.6,0.4
					c-0.1,0.2,0.1,0.6,0.1,0.8c0,0.2-0.1,0.5-0.2,0.6c-0.1,0.2-0.2,0.4-0.4,0.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2-0.1-0.2-0.5-0.3-0.7
					c-0.1-0.2-0.1-0.5-0.3-0.7c-0.1-0.2-0.4-0.5-0.6-0.6c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.2-0.7-0.3
					c-0.2-0.1-0.6-0.2-0.8-0.3c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4-0.2-0.6-0.1l0.2-0.7l-0.9,0.1l-0.9-0.3l-0.3-0.4l0.2-0.7l0.1-0.6
					l0.3-0.7l0.7-0.5l0.6-0.3l0.9-0.5l1.1-0.5l0.9-0.1l0.5,0.4l0.7,0.5l0.6,0.2l0.7,0.3l0.3,0.5l0.2,0.5l-0.3,0.5l0.3,0.4l0.6,0.3
					l0.5,0.1L188.4,764.7z M193.8,755.2c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.4-0.3
					c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1,0,0.2,0,0.3c0,0.2-0.1,0.5-0.1,0.7
					c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.3,0.3-0.4,0.4c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.2,0,0.6,0.2,0.8l-0.4-0.5l-0.3-0.3l-0.5-0.3
					l-0.5-0.2l-0.3,0.1l-0.5,0.2l-0.9,0l-1.1,0l-0.5,0.1l-0.1,0.3l-0.4,0.2l-0.3,0.3L184,758l-0.2,0.7l0.2,0.3l-0.1,0.1l-2,0l-1,0.2
					l-0.2,0.3l-0.1,0.2l-0.3-0.1l-0.1-0.3l0.1-0.5l-0.1-0.7l-0.3-0.2h-0.8h-1l-0.5-0.5l-0.6-0.5l-0.6-0.1l0.1-0.8l0.4-0.9l0.1-0.6
					l-0.3-0.9l0.4-0.8l0.7-0.7l0.7-0.6l0.6-0.6l0.4-0.7l0.2-0.7l0.3-0.8l0.3-0.7l0.5-0.6l0.5-0.5l0.6-0.5l0.8-0.4l0.9-0.2h0.6
					l0.5-0.1l0.6-0.4l0.6-0.3l0.9,0.2l0.6,0l0.4-0.4l-0.1-0.6l-0.2-0.5l-0.3-0.3l0.1-0.6l0.5-0.1h0.9l1.1,0.3l0.5,0.2l1,0.4
					l-0.1,0.5l0,0.5l0,0.7l0.1,0.6l0.3,0.8l0.5,0.4l0.7,0.1l0.3-0.3l0.4-0.8l-0.4-1.3l-0.1-0.7l0.1-0.5l0.7,0.6l0.6,0.6l0.5,0.5
					l0.4,0.4l0.1,0.4v0.6l-0.1,0.9l0.1,0.4l0.6,0l0.2-0.3l0.6-0.3l0.6,0l0.8,0.4l0.3,0.9l-0.3,0.2l-0.5,0.8l-0.3,0.7l0.1,0.7
					l0.6,0.5l0.4,0.6l-0.1,0.7l-0.5,0.1l-0.5-0.1l-0.4-0.5l-0.3-0.2l-0.9-0.1l-0.7,0l-0.6,0.1l-0.3,0.3l-0.3,0.5l0.1,0.5l-0.1,0.6
					l-0.1,0.5L193.8,755.2z M208.9,758.6c-0.3-0.1-0.6-0.2-0.9-0.3c-0.2-0.1-0.6-0.1-0.8-0.3c-0.2-0.1-0.4-0.3-0.5-0.4
					c-0.2-0.2-0.4-0.4-0.6-0.5c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2,0-0.5,0.1-0.7,0.1
					c-0.3,0-0.6,0-0.9,0l0.3-0.6l0.2-0.5l0.3-0.6l0.1-0.5l-0.3-0.4l-0.5-0.3l-0.6-0.1l-0.5-0.2l-0.3-0.4l0.1-0.4l0-0.5l0.4-0.3
					l0.5-0.4l0.4-0.2l0.3-0.1l0.5,0.1l0,0.2l0.2,0.5l0.3,0.1l0.6,0.3l0.8,0.3l0.4,0.1l0.8,0.2l0.7,0.5l0.5,0.3l0.1,0.5l-0.2,0.2
					l-0.4,0.1l-0.4-0.1l-0.4,0.4v0.6l0.6,0.7l0.4,0.9l0.2,0.3l0.4,0.5l0.3,0.5L208.9,758.6z"
              ></path>
              <path
                id="SG_12_"
                class="st1"
                d="M251,767.7l-3.7,5.1l-0.5,0.4l-0.9,0.9l-1,0.4l-0.3,1.1l-0.2,1l-0.5-0.2l-1-0.3l-0.2-0.8
					l-0.8-1.2l-0.7-0.1l-0.9-0.8l0.1-0.1l0.3-0.6l-0.5-0.1l0.4-0.8l-0.9-0.5l-0.4,0.4l-0.8,0.1l-0.9,0.2l-0.7,0.1l-0.4,0.7l-0.7,0.4
					l-0.4,0.2l-0.1,0.4l0.9,0.2l0.5-0.3l0.8-0.1l0.8,0.9l0.3,1.4l0.1,0.7l-0.9,0.4l-1.3,0.2l-1.3,0.5l-0.9,0.4l-1.1-0.1l-1.2-0.8
					l-0.6-0.4l-1.4,0.1l-0.5-0.5l-0.2-0.6l-0.5,0l-0.7-0.2l-1.2-1l-0.4,1l-1.2,1.1l-0.9,0.3l-1.7,0.2l-0.9-0.5l-0.6-0.6l-1.4-0.7
					l-0.9-0.2l-0.9,0.4l-1.2,0.3l-0.5,0.6l0,0.8l0.6,1.1l0.6,0.4l1.2,1l0.5,0.3L219,781l-0.4,0.7h-1.3l-1.6,0.2l-0.4,0.5L215,784
					l-0.6,0.5l-0.4,1l-0.2,0.7l-0.4,0.2l-0.4,0.6l-0.6,0.3l0.3,0.8l0.8,1.7l1,1.5l0.3,1.9l-0.3,1.2l-1.3,0.2l-0.9,0.9l-0.6,0.7
					l0.2,0.6l0.4-0.1l0.1,0.4l-0.5,1.2l0,1l-1.8,0.5l-1.5,0.5l-0.7,0.5l-0.5,0.3h-0.7l-1.3-0.2l-0.7-0.4l-0.8-0.2l-0.8,0.2l-0.6,0.6
					l-0.4,0.7l0.2,0.5l0,1.4l2.6,1.1l3.9,0l2.5-0.4l1.8-0.6l0.6,0.6l0.5,0.3l0.1,2.5l0.2,0.7l0.8-0.1l0.9,0.2
					c0.2,0.6,0.9,1.2,1.4,1.6c1,0.9,2.4,1.7,3.5,2.5c0.2,0.2,0.5,0.4,0.7,0.5c0.3,0.2,0.7,0.5,1.1,0.5c0.4,0.1,0.8-0.3,1.2-0.4
					c0.3-0.1,1-0.3,1-0.3l4,1.2v1l0.1,1.1l0.1,2.4l0.1,0.9l-1,1.3l-0.8,0.3l-1.1,0.4l-0.3,0.2l-0.1,0.6l0.2,0.6l0.4,0.5l0.8,0.3
					l1.7,0.1l0.7-0.3l0.7-0.4l0.7-0.2l0.6,0.2l0.9,0.6l0.3,1.6l0.4,2.3l0.1,3.3l-0.7,0.8l-0.3,0.3l0.7,0.6l0.6,0.7l0.5,1.2l-0.2,1.1
					l1.2,1.3l0.4-0.2l0.6-0.4l0.6-0.1l0.8,0l0.9,0.3l1.2,0.3l1.8,0.2l2.1,0.1l1.4,0.3l1,0.4l1.5,0.8l1.1,0.5l0.2-0.2l0.3-0.5
					l0.1-0.6l-0.2-0.8l1.1,0l0-0.7l-0.3-0.6l0.1-1l0.5-1.4l1.2-1.3l1.3-2.2l0.4-0.9l0.7-0.3l1.1-0.6c-0.2-0.3-0.6-0.6-0.8-0.9
					c-0.2-0.2-0.4-0.6-0.5-0.9c-0.3-0.6-0.5-1.4-0.8-2c-0.3-0.6-0.9-1.3-1.1-1.9c-0.1-0.3-0.1-0.7-0.3-0.9c-0.2-0.2-0.6-0.3-0.6-0.5
					c-0.1-0.2,0-0.5,0.1-0.7c0.1-0.2,0.5-0.3,0.7-0.4c0.2-0.2,0.4-0.7,0.6-1c0.2-0.3,0.6-0.6,0.8-1c0.1-0.2,0.2-0.6,0.3-0.9
					c0.1-0.2,0.2-0.6,0.2-0.8c0-0.3,0.1-0.7,0-1c-0.1-0.4-0.3-0.9-0.4-1.3c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.4-0.3-0.9-0.4-1.3
					c-0.1-0.4-0.4-0.8-0.5-1.2c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.3-0.2-0.7-0.3-1.1c-0.1-0.3-0.1-0.6-0.2-0.9
					c-0.1-0.3-0.1-0.7-0.2-1.1c0-0.3-0.1-0.8-0.1-1.2c0-0.2,0.1-0.5,0.2-0.6c0.2-0.3,0.5-0.6,0.7-0.9c0.2-0.3,0.4-0.6,0.6-0.9
					c0.3-0.4,0.6-0.9,0.9-1.3c0.2-0.3,0.5-0.8,0.6-1.2c0.1-0.2,0.1-0.6,0.1-0.8c0-0.3,0-0.7,0.1-1c0.1-0.3,0.4-0.6,0.6-0.8
					c0.2-0.1,0.5-0.2,0.6-0.3c0.2-0.1,0.5-0.4,0.6-0.6c0.2-0.3,0.3-0.8,0.4-1.2c0.1-0.2,0.1-0.5,0.2-0.6c0.1-0.2,0.3-0.4,0.4-0.5
					c0.1-0.1,0.4-0.1,0.5-0.2c0.4-0.2,0.8-0.7,1-1.1c0.2-0.3,0.3-0.8,0.4-1.2c0.2-0.3,0.5-0.7,0.6-1.1c0.2-0.4,0.3-0.9,0.5-1.3
					c0.2-0.3,0.5-0.6,0.6-0.9c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.6,0.4-0.7c0.1-0.1,0.3,0.1,0.4,0.1c0.3,0,0.8,0,1.1,0
					c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.6-0.2,0.7-0.4c0.1-0.2,0-0.4-0.1-0.6c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.3-0.4-0.6-0.6-0.8
					c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.2,0-0.4,0-0.6c0-0.2,0.2-0.6,0.2-0.8c0-0.4,0-1-0.1-1.4c-0.1-0.5-0.6-1.1-0.5-1.6
					c0-0.3,0.5-0.7,0.4-1c0-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.4-0.4-0.4c-0.2-0.1-0.6-0.1-0.9-0.2c-0.2-0.1-0.5-0.4-0.7-0.5
					c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0.2-0.4,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.3,0.1-0.4,0
					c-0.2-0.1-0.3-0.4-0.4-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.3-0.7-0.5-1c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.4-0.5-0.6
					c-0.1-0.2-0.2-0.6-0.2-0.6l-2.5-4.8L251,767.7z M239.8,800.6l2,1.2l0.9,0.4l1.1,0l0.5-0.3l1.4-0.8l0.9-0.7l2-1.9l1.2-1.5
					l0.7-1.8l0.4-2.3l0-1.5l-0.3-1.4l-0.1-0.7l-0.4-0.8v-0.7l0.5-1.3l0.4-1l0.3-0.2l0.9,0l0.8,0.1l0.9,0.1l0.5-0.3l0.6-0.6l1.3-0.3
					l0.8-0.6l1.1-0.4l0.6-0.5l1.2-1.4l-0.7-0.4l-1.6-0.9l-1.4-0.5l-1.3-0.7l-1-0.8l-1.1,0l-0.9,0.8l-1,1.2l-1.6,0.7l-1.5,0.4
					l-1.4,0.3l-0.4,1.5h-0.8l-1.2,0.6l-1.6,0.3h-0.5l-1.4,0l-1.4,0.2l-1.1,0.8l-1.7,0.4l-1.7,0.1l-1.3,0.2l-0.9,0.9l-1,0.6l-0.1,0.6
					l-0.2,1l-0.8,0.9l-0.8,0.4l0,0.5l0.7,0.8v0.8l0.2,0.2l0.8-0.6l1,0.8l-0.5,0.5l-1,0.8l-0.1,0.8l0.4,1.7l0.6,0.3l-0.9,1.9l1,0.6
					l1,0.5l1.1,0.2l1-0.2l0.8-0.5l0.8,0.6l1.6,1l1.3,0.7L239.8,800.6z M250.4,773.6l-0.1,0.5l-0.5,1l-0.9,0.7l-0.8-0.2l-0.7-0.6
					l0-0.9l0.5-1l0.7-0.5l0.8,0.3L250.4,773.6z"
              ></path>
              <path
                id="AR_12_"
                class="st1"
                d="M258.7,782.6l-0.9-0.8l-1.1-0.8l-0.9-0.3l-1.2,0.3l0.1,1l0.9,0.6l0.7,0.5l0.8,0.4l-0.8,0.6
					l-1.3,0.3l-0.6,0.6l-0.5,0.3l-0.1-0.9l1.5-0.8l-0.7-0.6l-0.7-1.1l-0.3-0.6l-0.5,0.2l-0.9,0.3l-0.4,0.3l-0.4,0.5l0.9,0.6
					l-0.3,0.5l-0.8,0.4l-0.5,0.6l0.3,0.6l-0.4,1l-0.5,1.3v0.7l0.4,0.8l0.1,0.7l-1.1,0.2l-1.1,0l-0.9-0.4l-0.6-1.3l-1.6-0.7l-2-0.8
					l-1.2-0.5l-1.1,0.2l-0.8,0.2l0.5,1.2l0.4,0.5l-0.6,0.9l-1.3,1.2l-1,1.1l-0.2,1.9l-0.4,2l0.7,1.3l0.8,0.6l0.6,1.5l0.3,1.5
					l-0.9,0.7l-1.3-0.7l-1.6-1l-0.8-0.6l-0.8,0.5l-1,0.2l-1.1-0.2l-1-0.5l-1-0.6l0.9-1.9l-0.6-0.3l-0.4-1.7l0.1-0.8l1-0.8l0.5-0.5
					l-1-0.8l-0.8,0.6l-0.2-0.2v-0.8l-0.7-0.8l0-0.5l0.8-0.4l0.8-0.9l0.2-1l0.1-0.6l1-0.6l0.9-0.9l1.3-0.2l1.7-0.1l1.7-0.4l1.1-0.8
					l1.4-0.2l1.4,0h0.5l1.6-0.3l1.2-0.6h0.8l0.4-1.5l1.4-0.3l1.5-0.4l1.6-0.7l1-1.2l0.9-0.8l1.1,0l1,0.8l1.3,0.7l1.4,0.5l1.6,0.9
					l0.7,0.4L258.7,782.6z"
              ></path>
              <path
                id="AI_12_"
                class="st1"
                d="M239.8,800.6l-0.3-1.5l-0.6-1.5l-0.8-0.6l-0.7-1.3l0.4-2l0.2-1.9l1-1.1l1.3-1.2l0.6-0.9
					l-0.4-0.5l-0.5-1.2l0.8-0.2l1.1-0.2l1.2,0.5l2,0.8l1.6,0.7l0.6,1.3l0.9,0.4l1.1,0l1.1-0.3l0.3,1.4l0,1.5l-0.4,2.3l-0.7,1.8
					l-1.2,1.5l-2,1.9l-0.9,0.7l-1.4,0.8l-0.5,0.3l-1.1,0l-0.9-0.4L239.8,800.6z M258.7,782.6l-0.9-0.8l-1.1-0.8l-0.9-0.3l-1.2,0.3
					l0.1,1l0.9,0.6l0.7,0.5l0.8,0.4l1.1-0.4L258.7,782.6z M253.8,785.3l-0.1-0.9l1.5-0.8l-0.7-0.6l-0.7-1.1l-0.3-0.6l-0.5,0.2
					l-0.9,0.3l-0.4,0.3l-0.4,0.5l0.9,0.6l-0.3,0.5l-0.8,0.4l-0.5,0.6l0.3,0.6l0.3-0.2l0.9,0l0.8,0.1L253.8,785.3z"
              ></path>
              <path
                id="GR_12_"
                class="st1"
                d="M208.3,845.9l1.1,0.1l1.2,0.3l0.4,0.9h1.2l1.3-0.6l1-0.3l2.2-0.4l1.2-0.4l0.8-0.9l0.2-1
					l0.4-1.6c0,0,0.6-1.1,0.6-1.3c0-0.1,0.3-1,0.3-1l0.9-0.5l0.9-0.1l0.9,0.4l0.7,1l0.4,0.7l0.6,0.3l0.5-0.4l0.9-0.7l0.8-0.9
					l0.6-0.6l0.8-0.2l1.6-0.1l0.7,0.2l0.4-1.1l0.5-0.7l0.9-1l0.9-0.9l1-0.2l1.2,1.3l0.4-0.2l0.6-0.4l0.6-0.1l0.8,0l0.9,0.3l1.2,0.3
					l1.8,0.2l2.1,0.1l1.4,0.3l1,0.4l1.5,0.8l1.1,0.5l0.2-0.2l0.3-0.5l0.1-0.6l-0.2-0.8l1.1,0l0-0.7l-0.3-0.6l0.1-1l0.5-1.4l1.2-1.3
					l1.3-2.2l0.4-0.9l0.7-0.3l1.1-0.6c-0.2-0.3-0.6-0.6-0.8-0.9c-0.2-0.2-0.4-0.6-0.5-0.9c-0.3-0.6-0.5-1.4-0.8-2
					c-0.3-0.6-0.9-1.3-1.1-1.9c-0.1-0.3-0.1-0.7-0.2-0.9l0.9,0.4l1.1,0l0.4-0.4l0.4-0.6l1.2,0l0.9,0.6l0.3,0.6l0.9,0.1l1.2-0.4
					l0.4-0.4l0.5-0.2l1.2,0.3l1.2,0.1l1.1-0.4l2.6,0.6l1.8,1l2.3,0.4l1.7,0.2l1.7,0.9l1.1,1.1l1.2,0.6l1.1,0.3l2-0.9l0.2,1l0.5,0.9
					l0.3,1l-0.6,0.8l-0.6,2l0.3,0.8l0.1,1.4l-0.1,0.7l0.5,0.5l1.3,0.7l1.9,1.2l1.1,0.7l2.4-0.3l1.3,1.4l1.2,0.2l1.9,1.1l0.9,1.9
					l0.8,0.9l1.4-0.1l0.8,1.3l3.6,0.2l1.6-0.7l1.3-1.2l1.2-0.7l2.3,0.1l0.1-3.3l0.5-0.9l0.2-1.2l0.7-0.7l0.8,0.5l1,0.2h1.6l0.9-0.7
					l0.1-1.1l-0.2-0.9l0.7-0.1l0.8-1.1l0.6-1.5l0.3-1.5l1.3-0.6l1.4-0.4l0.7,0.2l1.8,2l0,1.3c0.2,0.1,0.5,0,0.7,0.1
					c0.4,0.2,0.7,0.8,1.1,1c0.4,0.2,1,0,1.3,0.1c0.2,0.1,0.5,0.3,0.7,0.4c0.2,0.2,0.5,0.5,0.6,0.8c0.1,0.2,0.1,0.6,0.2,0.8
					c0.1,0.3,0.2,0.9,0.2,0.9l0.1,0.1c0,0-0.1,0.3-0.2,0.5c-0.1,0.2-0.4,0.3-0.5,0.4c-0.2,0.2-0.3,0.6-0.4,0.9
					c-0.1,0.3-0.3,0.6-0.4,0.9c-0.1,0.3-0.2,0.7-0.3,1.1c0,0.2,0,0.5-0.1,0.7l0.4,0.8l-0.1,1l0.2,0.6l-0.1,0.8l-0.3,1.2l-0.6,1
					l-0.3,0.9l0,0.8l-0.5,0.7l-0.7,0.5l-0.6,0.5l-0.1,0.7l0.2,1.1l0.5,0.8l0.6,1l0,0.5l-0.9,0.5l-1.2,0.8l-0.8,0.7l-0.2,0.5l0.3,0.7
					l0.6,0.4l0.2,0.7l-2.1,2l-0.4,0.5l0.1,2l0.4,0.5l0.5,1.8l0.3,1l0.6,0.4l0.9-0.1l1.5-0.4l1.5,0.6l0.6,0.8l1,1.1l0,2.2l-0.3,1.7
					l-0.5,1.1l-0.3,1.2l-0.9,1.2l-0.9-0.1l-0.6-0.4l-1.1-0.3l-1.7,0.1l-0.9-0.3l-1.9-0.1l-0.9,0.3l-2.4,0l-0.2-0.6l-0.4-0.7
					l-0.4-0.9l-0.9-0.5l-1.3-0.1l-0.5-0.2l-0.6-0.5l0.1-0.9l0.8-0.6l0-1.1l-0.1-0.5l-0.7-0.9l-0.3-0.9l-0.1-0.4l-0.9,0l-0.3,0.6v0.3
					l-0.3,0.5h-0.5l-0.8-0.3l-0.7,0l-1,0.3l-0.3,0.7l-0.8,0.2l-0.5,0.5l-1.2,0.4l-0.3,0.2l-0.9-0.2l-1,0.2l-0.5,1l0.2,1.5l-0.9,0.4
					l-0.7,0.6l-0.3,1l-1.5,1.5l-0.2,0.9v1.5l-0.1,1.2l-0.3,0.5l-0.6,1.1l0.2,1.9l0.2,0.8l-0.2,0.8l-0.1,1.4l0.7,1l0.6,1.1l0.6,0.1
					l0.9,0.4l0.7-0.1l1.3-0.1c0,0,0.6-0.1,0.9-0.2s1.1-0.4,1.1-0.4l0.2,0.6l0.3,0.7l0.9,0.9l0.2,0.7l-0.2,0.4l-1.2,0.9l-0.9,0.4
					l-0.6,0.5l0,0.2l-0.3,1.1l-0.3,1.3l-0.4,1.3l0.2,1.1l0.7,1.4l0.9,1.3l0.8,0.7l0.9,1.2l0.6,1.1l0.7,1.2l-1.1,1.2l-1.4,1.8
					l-0.6,0.1l-1.1-0.1l-1.2-0.3l-1.1,0.1l-0.5,0.7l-0.3,0.4l-0.4-0.5l-0.7-0.5l0.1-1.2l0.2-1.7l-0.3-1.2l-1.5-1l-1.7-0.7l-0.5-0.3
					l0.1-1.3v-1l-0.5-0.6l-0.1-1.3l0.5-0.2l-0.1-1.5l-1.2-1.1l-0.6-0.9l-0.6-0.9l-0.7-0.6l-0.8,0.4l-0.9,0.5l-1.1-0.3l-1.1,0.3
					l-0.9,0.5l-0.6,0.3l-0.8,0.3h-2l-1.3,0.4l-0.5,1l-0.8,0.5l-0.7,0.5l-0.7,0.1l-1.5-0.7l-1.1-0.7l-1.1,0.7l-0.5,0.7l-0.1,1.9
					l0.1,1l-0.3,0.9l-0.6,0.5l-0.5-0.2l-1.3-0.3l-1.4,0.5l-1.5,0.4l-1,0.1l-0.8-0.3l-1.5-0.1l-1.1-0.6l-1.9-0.9l-1-1l-0.9-1.2
					l-0.3-0.9l-0.5-1.5l-0.9-0.6l-1.1-0.7l-1.1-0.6l0.3-0.9l0.1-1.5l-0.9-1.8l-0.3-1.1v-1.5l0.1-1.1l0.5-1.4l0.3-1.8l-0.1-1.1
					l-0.6-0.8l-1.5-0.1l-0.5,0.5l-0.9,1l-0.1,1l-0.5,0.8l-0.1,0.3l-0.9-0.2l-0.5-0.6l-1.2-1V879l-0.1-0.9l-1.1-0.4l-1.9,0.3
					l-1.6,0.3l-1.5,0.6l-1,1.1l0.1,1.1l0.2,1.7l-0.3,0.7l-1.1,0.7l-0.7,0.7l0.1,1.5l2.3,2.1v2.9l0.2,1.9l0.6,1.1l0.1,2.1l-0.8,1.6
					l-1.3,2.6l-1.3,1.5l-0.1,2.3l-0.1,0.7l-0.7,0.3l-1,0.8l-0.1,0.7l-0.6,0.9l-0.5,0.4l-0.7,1.3l-0.2,1.1l-0.1,0.9l-1.6,0.3
					l-1.2-1.2l-1.3-1.9l-1.1-1.1l-1.6-1.1l-0.2-1.2l0.2-1.6l-0.6-1.6l-1-1.6l-0.4-1.1l0.5-1l0.5-1.6l-0.1-1.7l0.4-1l0.7-0.6l0.7-1.1
					l-0.4-1.9l0.1-1.4l0.5-1.3l0.2-1l-0.9-1.1l-0.2-1.1l0.6-1.2l0.1-1.7l-1-1.2l-1.1-0.4l-0.7-0.1l-1-1l-0.8-1.2l-0.9-2l-0.1-1.6
					l0.3-2.3l0.7-0.5l1-1l-0.2-0.7l-0.3-0.2l-1.2-0.3l-1-1.3l-0.6-0.6l-1.4,0.4l-1.2-0.1l-0.2-0.7l-0.1-1l-0.1-1.5l-0.2-1l-0.2,0.2
					l-0.5,0.4l-1.9,0.1l-1,0.8l-0.4,0.8l0.6,1.3l0.3,0.5l0,0.8l-0.8,0.8l-0.9,0.6l-0.9,0.4L207,871l-1.2,0.3h-0.6l-0.5-0.3l-1.2,0.9
					l-1.1-0.2l-1-0.6l-0.7-0.4l-1.5-0.3l-1.1,0.2l-1.1-0.1l-1.3-0.6l-1.2-0.3l0.4-2l-1.1-1.6l-0.9-1.2v-2l0.8-1.1l0.6-0.4l-0.7-1.4
					l-0.1-0.7l0.5-1.1l0.9-0.5l1.6-0.1l1.1-1.1l1.3-0.7l0.5-1.6l0.2-0.8l1.1-0.1l0.6,1l1.1,0.1l0.9-0.8l0.3-1.4l1-1l0.5-1.4
					l-0.4-1.6l0.7-0.5l0.9-0.4l0.9-0.1L208.3,845.9"
              ></path>
              <g id="Canton_highlighted_8_">
                <path
                  id="AG_12_"
                  class="st1"
                  d="M175.7,767l-0.4,0.7l0.2,0.2l-0.1,1.2l-0.7,1.9l-1.3,1.3l-1.1,0.9v1.3l-0.6,1.1l-0.7,2.1
						l0.6,1.7l0.5,0.4l0.1,0.5l0.2,0.9l0.3,1.6l0.1,0.3l0.7,0.3l1.1,0.7l0.2,1l-0.2,0.6l-1.9,2.3l0.1,0.7l2.2,1.1l-0.2,0.8l0.2,2.5
						l0.5,0.7l0.4,0.4l0.9,0.2l0.6-0.9l0.3,0.1l-0.6,1l-0.6,1l-0.8,0.8l-0.8,0.3l-1,0.2c0.1,0.2,0.4,0.5,0.5,0.8
						c0.1,0.3,0.3,0.7,0.4,1c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.3,0.3,0.7,0.4,1c0.1,0.3,0.2,0.7,0.1,1c-0.1,0.3-0.3,0.6-0.4,0.8
						c-0.1,0.3-0.4,0.6-0.4,0.9c0,0.4,0.4,0.8,0.5,1.2c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.3,0.1,0.8,0.1,1.2c0,0.4-0.1,0.9,0,1.3
						c0,0.3,0.2,0.6,0.3,0.9c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.2,0.4,0.5,0.4,0.7c0,0.3-0.3,0.6-0.4,0.8c-0.1,0.4-0.1,1-0.2,1.3
						l-1.6,0.5l-1.7,0.4l-0.7,0.1l-0.5-0.8l-0.7-1.3l-0.6-1.3l-0.7-1.9l-0.2-1.4l-0.6-1.7l-0.7-2.2l-0.3-1.5l-0.1-1l-0.2-0.7
						l-0.2-0.1l-1.4-0.4l-0.3-0.6l-1.1-0.2l-0.7,0l-1.4,1.9l-0.8,0.5l-0.3,0.8l-0.2,1.3l-0.1,0.8l-0.3,0.7l-0.6,0.5l-0.6,0.1
						l-0.7-0.2l-0.6-0.5l-0.1-0.4v-0.5l0.8-0.4l-0.1-0.5l-0.6,0.1l-1.6,0.2l-1.7,0.1l-0.8-0.2l0-0.9l-0.3-0.7l-0.1-0.2l-0.5-0.1
						L152,800l-0.8,0.2l-0.4,0.3l-0.2,0.7l-0.2,0.7l-0.8,0.2l-0.8,0.1l-0.9-0.1h-0.6l0.1-0.4l0.1-1.2v-0.9l-0.2-0.6l-0.5-0.9
						l-0.6,0.2l-0.9,0.3h-0.5h-0.6l-0.4-0.4l-0.4-0.1l0,0.7v2.8l-0.2,0.3l-0.3,0.3l-0.4,0.2l-1.3-0.1l-0.6-0.3l-1-0.1l-1.4,0.2
						l-0.6,0.2l-0.6,0.4l-1.3,0.1l-0.1-1.1l0-1.7l0.2-1l0.2-0.3c0.3-0.1,0.5-0.4,0.7-0.7c0.3-0.3,0.5-0.9,0.7-1.2
						c0.1-0.2,0.2-0.5,0.4-0.7c0.2-0.2,0.5-0.4,0.7-0.7c0.1-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.6-0.4,0.7-0.6c0.2-0.2,0.4-0.5,0.4-0.8
						c0-0.3-0.3-0.8-0.2-1.1c0-0.2,0.2-0.4,0.3-0.6l0.6,0.4l0.6,0.5l0.8,0.3l1,0.2l1.9,0.2l1.2,0.1l0.5-0.1l0.4-0.2l0.4-0.6l0.3-1.1
						l0.6-1.7l0.6-1.2l0.6-1.4l-0.1-0.7l-0.9-0.8l-0.8-0.1l-0.6-0.3l-0.6-0.4l-1-0.6l-0.6-0.6l0.8-0.5l0-0.8l-0.2-1l-0.5-0.1
						l-0.2-0.8l0-0.6l-0.7-0.1l-0.3-0.1l-0.9-0.6l-0.2-1.1l0.1-0.7l-0.9-0.1l-0.8,0l-0.6-0.3l-0.8-1l-0.3-1.3l-1-1.7l-0.8-1.1
						l-0.7-0.8l-0.3-0.4l-0.5-0.3l-0.5,0l-0.5,0.3l-0.2,0.5l-0.2,0.8l-0.2,0.6l-0.2,0.8l-0.7,0.7l-0.7,0.5l-1.1,0.1l-0.1-0.7
						l0.1-0.5l-0.2-0.4l-0.5-0.2l-1-0.9l-0.9-0.5l-0.9-0.3l-0.1-0.5c0.2-0.1,0.6-0.1,0.9-0.2c0.3-0.1,0.8-0.2,1.2-0.3
						c0.3-0.1,0.6-0.3,0.9-0.4c0.4-0.1,0.9-0.2,1.3-0.4c0.3-0.1,0.7-0.3,0.9-0.4c0.3-0.2,0.5-0.5,0.7-0.8c0.2-0.3,0.2-0.7,0.4-1
						c0.1-0.2,0.2-0.5,0.3-0.6c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3,0,0.4,0c0.2,0.1,0.5,0.3,0.7,0.4c0.2,0.1,0.4,0.1,0.6,0.1
						c0.2,0,0.4,0,0.5-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.6-0.1,0.8-0.1c0.2,0,0.4,0,0.5,0.1
						c0.2,0.1,0.5,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.3,0.2,0.4c0,0.2,0,0.4,0.1,0.6
						c0,0.2,0.1,0.5,0.2,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.2,0.7,0.2,0.9,0.4c0.1,0.1,0.1,0.3,0.3,0.4c0.2,0.1,0.6,0,0.8-0.2
						c0.2-0.1,0.2-0.4,0.4-0.5c0.1-0.2,0.2-0.5,0.4-0.6c0.2-0.1,0.6,0,0.8,0c0.3,0,0.8,0.1,1.1,0.1c0.3,0,0.8,0,1.1,0.1
						c0.2,0,0.4,0.2,0.5,0.3c0.2,0.1,0.5,0.1,0.7,0.1c0.3,0,0.8,0,1.2,0c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3-0.3,0.4-0.4
						c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.4,0.4-0.4c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.4,0.3
						c0.3,0.1,0.6,0,0.9,0c0.1,0,0.3,0.1,0.4,0c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.4,0.3-0.5c0-0.2,0-0.4,0-0.6
						c0-0.1,0-0.1,0-0.2c0.1-0.2,0.3-0.4,0.4-0.5c0.1-0.1,0.3,0,0.4,0c0.2,0,0.5-0.1,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
						c0.3-0.2,0.6-0.5,0.8-0.7c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.6,0,0.8,0c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.5-0.3,0.7-0.4
						c0.2-0.1,0.4-0.1,0.5-0.3c0.1-0.1,0-0.3,0.1-0.4c0.2-0.3,0.7-0.6,1-0.9c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.1,0.2-0.4,0.4-0.4
						c0.2-0.1,0.4,0,0.5,0.1c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.4,0.4,0.6,0.4c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0
						c0.3,0,0.7-0.2,1-0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0.2,0.5,0.2c0.2,0,0.6,0,0.8,0c0.2,0,0.5,0,0.7,0.2c0.1,0.1,0.2,0.3,0.3,0.4
						c0.1,0.3,0,0.7,0.1,0.9c0.1,0.3,0.6,0.6,0.8,0.8c0.3,0.3,0.4,0.9,1,1c0.5,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.7,0.6,1.1,0.6
						c0.4,0,0.8,0.2,1.2,0.2c0.3,0,0.8,0,1.1,0c0.3,0,0.8,0.2,1,0c0.1-0.1,0-0.3,0.1-0.4c0.1-0.2,0.3-0.4,0.5-0.5c0.1,0,0.3,0,0.4,0
						C175.4,766.8,175.5,766.9,175.7,767z"
                ></path>
              </g>
              <path
                id="TG_12_"
                class="st1"
                d="M212.2,759c0,0-0.5,0.2-0.7,0.3c-0.3,0.1-0.6,0.1-0.9,0.1c-0.2,0-0.4-0.1-0.6-0.2
					c-0.3-0.1-0.7-0.5-1-0.6c-0.3-0.1-0.6-0.2-0.9-0.3c-0.2-0.1-0.6-0.1-0.8-0.3c-0.2-0.1-0.4-0.3-0.5-0.4c-0.2-0.2-0.4-0.4-0.6-0.5
					c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.3,0-0.6,0-0.9,0
					c-0.3,0-0.7-0.2-1-0.3c-0.2-0.1-0.6-0.2-0.8-0.4c-0.2-0.1-0.3-0.4-0.5-0.4c-0.2-0.1-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.2
					c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.8,0.1-1.2,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.3-0.2-0.4-0.3
					c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0.1-0.4,0.4-0.6,0.5c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.3,0.3-0.5,0.3l-0.7,0.9l-0.1,0.6
					l0.5,1.1l0.6,0.9l0.6,0.3l0.6,0.4l1.4,0.3l1.3,0l0.9-0.6l0.5-0.5l0.3-0.2l0.3-0.5l0.2-0.3l0.2,0.1l1.2,0.1v0.3l0.8,0.4l1,0.4
					l0.9,0.3l0.2,0.3l-1.3,2.5l-1.2,1.7l-0.6-0.4l0-0.3l-1.5-0.2l-0.8,0l-0.4,0.3l-0.1,0.6l0.1,0.4l0.3,0.8c0.3,0,0.6-0.2,0.8-0.3
					c0.2,0,0.4-0.1,0.5-0.1c0.4,0,1,0.2,1.4,0.4l0.1,1.4l0.9,0.6l1.2,0.4l0.9,0.8l0.4,0.5l-0.1,0.3l-1.2,0l0.2,0.8l0.3,0.7l0.5,0.3
					l0.8,0l0.6-0.2l0.8,0.4l1.4,0.6l0.2,1.1l-0.4,2l-0.6,1.3l-0.2,1.3l0.8,1.5l-0.1,0.9l0.3,0.9l0.6,0.5l0.4,0.4l-0.9,0.3l-0.4,1
					l-0.6,0.9c0.5,0.1,1.2,0.3,1.6,0.5c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.8,0.2,1.1,0.4c0.3,0.1,0.7,1.2,0.7,2.1l0.6-0.3l0.4-0.6
					l0.4-0.2l0.2-0.7l0.4-1l0.6-0.5l0.3-1.6l0.4-0.5l1.6-0.2h1.3l0.4-0.7l0.2-1.3l-0.5-0.3l-1.2-1l-0.6-0.4l-0.6-1.1l0-0.8l0.5-0.6
					l1.2-0.3l0.9-0.4l0.9,0.2l1.4,0.7l0.6,0.6l0.9,0.5l1.7-0.2l0.9-0.3l1.2-1.1l0.4-1l1.2,1l0.7,0.2l0.5,0l0.2,0.6l0.5,0.5l1.4-0.1
					l0.6,0.4l1.2,0.8l1.1,0.1l0.9-0.4l1.3-0.5l1.3-0.2l0.9-0.4l-0.1-0.7l-0.3-1.4l-0.8-0.9l-0.8,0.1l-0.5,0.3l-0.9-0.2l0.1-0.4
					l0.4-0.2l0.7-0.4l0.4-0.7l0.7-0.1l0.9-0.2l0.8-0.1l0.4-0.4l0.9,0.5l-0.4,0.8l0.5,0.1l-0.3,0.6l-0.1,0.1l0.9,0.8l0.7,0.1l0.8,1.2
					l0.2,0.8l1,0.3l0.5,0.2l0.2-1l0.3-1.1l1-0.4l0.9-0.9l0.5-0.4l3.7-5.1l-15.8-8.9l-4.6-0.1l-1.4,0l-1.1-0.3l-0.9-0.3l-0.2-0.5
					l-0.7-0.2l-2.2-1l-5.1-0.7l-1.9,0.7L212.2,759z M250.4,773.6l-0.1,0.5l-0.5,1l-0.9,0.7l-0.8-0.2l-0.7-0.6l0-0.9l0.5-1l0.7-0.5
					l0.8,0.3L250.4,773.6z"
              ></path>
              <path
                id="TI_12_"
                class="st1"
                d="M194.4,869.9l-0.5,0.8l-1,0.8l-1.4,0.1l-0.4-0.5l-0.8-0.4l-2.4-0.2l-0.7-0.5l-1.4-0.3l-1.7,0.3
					l-1,1l-0.4,1.1l0.2,1.3l-0.1,0.8l-0.4,0.5l-1.6,0.5l-1.2,0.1l-0.5,1.1l-1.2,1.1l-0.3,0.8l-0.4,0.7l-0.5,0.1l-1-0.1h-1.2
					l-0.6-0.2l0.1,1.4l0,0.9l-0.5,2l2-0.9l1.6-0.3l1.3,0.5l1.1,2l0.1,0.9l-0.1,1.7v1.9v2.1l0.2,2.3l-0.3,1.7l-0.9,1.7l-0.9,1.1
					l-0.3,1.5l1.1,1.5l0.4,1.2l-0.1,0.7l0.3,0.9l1.5,1.3l1.9,1.1l1.9,1l1.1,1.6l1.8,2.7l1.1,1l0.1,1.2l1.3,1.4l0.6,1.3l0.8,0.1
					l0.9,0.2l0.7,0.6l0.9,0.5l1.2,0.1l0.7,0.5l0.5,0.3l0.9,0.4l1.8-1.6l1.1,0.8l1,0.5l0.9,0.8l0.5-0.3l0.7-0.6l0.3-0.4l0.5,0.1
					l1.6,1.7l1.1,1.4l0.7,0.9l0.1,1l-0.6,0.7l-0.5,0.4l-0.3,0.1L206,925l-0.2,1.1l-0.5,0.4l-1.3,0.8l-0.8,0.7L203,929l0.4,0.9h0.4
					l0.9,0.1l1.2,0.4l0.8,0.8l1,0.8l0.8,0.8l0.7,0.2l0.7,0.4l0.1,0.9l0.1,1.7l0.9,1.2l0.5,0.7l0.4,0.5v0.7l0.5,1.4l0.9,1.5l-0.1,0.9
					l-0.9,0.8l-0.8,1.3v0.7l0.5,0.1l0.8-0.8l1.1,0.1l0.8,0.8l0.9,0.2l1-0.1l0.6,0.3l0.1,1l0.9,0.3l1.2-0.6l0.3-1.6l0.2-0.9l0.5-1.1
					l0.3-1.3l1-0.7l0.7-0.7l0.3-0.9l-0.3-0.2c0,0-0.7-0.9-1-1.3c-0.3-0.3-1.1-0.8-1.1-0.8H219l-0.7-0.5l-0.2-0.7l0.1-1l-0.5-0.8
					l-0.5-0.7l-0.4-1.1l-0.1-0.5l0.5-0.6l0.6-0.6l0.7-0.2l0-0.7l-0.1-1.7l-0.3-0.4l-0.4-0.8l0.1-1.3l0.5-1.4l0.7-0.5l1.3-0.5
					l1.3-0.5l0.6-0.4l0.5-0.7l0.3-1l-0.1-1.1l-0.5-1.1l-0.2-0.8l0.5-1.1l1.9-0.8l1.3-1.3l0.8-0.8l0.6-0.3l0.7-1.1l0.7-0.3l-1.2-1.2
					l-1.3-1.9l-1.1-1.1l-1.6-1.1l-0.2-1.2l0.2-1.6l-0.6-1.6l-1-1.6l-0.4-1.1l0.5-1l0.5-1.6l-0.1-1.7l0.4-1l0.7-0.6l0.7-1.1l-0.4-1.9
					l0.1-1.4l0.5-1.3l0.2-1l-0.9-1.1l-0.2-1.1l0.6-1.2l0.1-1.7l-1-1.2l-1.1-0.4l-0.7-0.1l-1-1l-0.8-1.2l-0.9-2l-0.1-1.6l0.3-2.3
					l0.7-0.5l1-1l-0.2-0.7l-0.3-0.2l-1.2-0.3l-1-1.3l-0.6-0.6l-1.4,0.4l-1.2-0.1l-0.2-0.7l-0.1-1l-0.1-1.5l-0.2-1l-0.2,0.2l-0.5,0.4
					l-1.9,0.1l-1,0.8l-0.4,0.8l0.6,1.3l0.3,0.5l0,0.8l-0.8,0.8l-0.9,0.6l-0.9,0.4L207,871l-1.2,0.3h-0.6l-0.5-0.3l-1.2,0.9l-1.1-0.2
					l-1-0.6l-0.7-0.4l-1.5-0.3l-1.1,0.2l-1.1-0.1l-1.3-0.6L194.4,869.9z M215.4,931.4l0.3,0.3l0.3-0.1l0.3-0.3l0.3-0.4l-0.1-0.5
					l-0.3-0.1l-0.5,0.5L215.4,931.4z"
              ></path>
              <path
                id="VD_12_"
                class="st1"
                d="M77.5,827.6l1.7,0.8l1.2,0.4l0.2,0.1l-0.3,1.2v1.7l0.4,0.5l1.4,2.5l0.9,1.9l-1,0.8l-1.2,0.9
					l-0.2,1.7l-0.5,1.1l-1,0.9l-0.6,0.7l-0.6-0.4l0.5-1.3l-0.1-1.6l-0.8-0.6l-0.7-0.8l-1.3-1.1l-0.5-1.3l-0.4-1.5l-1-0.5l-0.1-0.1
					l-1.9-1.6l0,0L77.5,827.6z M59.5,852.3l-0.6,1l0.2,0.9l0.7,0.6l0.6-0.5l0.3-1.1l-0.1-1L59.5,852.3z M63.3,852.8l1.2-1.6l0.7-1.2
					l-0.2-0.5l0.5-0.3l0.7,1l1,1l0.3,0.5l-0.9,1.7l-0.6,1l-0.7-0.1l-0.7-0.7L63.3,852.8z M62.3,853.1l0.8,0.1l0.7,0.5l-0.6,0.9
					l-0.7,0.3l-0.3-0.5L62.3,853.1z M19.3,894.5l1.9,1.1l-2,3.9l-0.2-0.1l-1.4-0.9l-1.6-1l-1.3-0.9l-0.4,0l0.9-1.5l0.8-1.3l0.1-1.3
					l-0.5-1.1l-2.1-2.1l-2.1-1.5l-1.5-1l0.7-1.1l0.3-1.3l-0.1-2.3l0.8-1.4l1.1-1.7l0.9-1.4l0.9-0.9l1.2-1l-0.4-1.9l-0.7-0.9l-1.8-2
					l0.8-1.1l1.5-1.4l3.2-3l4-3.7l1.3-1.4l1.4-1.3l1.6-0.7l3.2-2.1l2.3-2.1l0.2-0.9l0.6-0.3l1.5-0.3l1.1-0.7l0.9-1l0.9-1.3l-0.4-1
					l-1.1-0.5l-0.5-0.5l-0.3-0.7l0.7-0.5l0.1-1.6l0.1-0.7l0.7-0.6l1-1.2l5.2-0.7l2.9-1.9l1.8-0.8l2.5-0.7l1.2-1.5l2.1-1.3l1.7-1.6
					l1-0.4l0.7,1.7l-0.4,1.3l1,0.6l0.2,2.3l0.8,0.6l0.8,2.5l0.2,3.5l0.6,0.5l1.3,1l-0.5,0.3l1.5,1.1l1.6,0.7l0.7,0.1l0.7-0.4
					l0.7-0.8l0.9,0.6l1.2,0.5h1l0.7-0.7l0.3-1.2v-1.2l0.3-0.7l-0.4-0.2l-0.6,0.1l-0.2,0.6l-0.8-0.1l0.6-1.5l0.1-0.6l0.7-1.7l0.8-0.7
					l-1.1-1.6l-1.1,0.4l-0.1-0.3l1.6-0.9l-0.8-0.8l-0.7,0.1l-1.7-1.5l2.4-2l1.7,2.4l1.5,1.7l1.3,1.1l0.7,0.3v1.7l0.5,1.1l-0.1,2
					l-1.3,1l-0.3,1.3l-0.5,1.8l-0.8,0.9l-1.4,1.6l-0.3,0.8l0.8,0.7l-0.2,1.2l-2.6,3.3l-2,2.1l-0.1,1.5l-0.4,0.8l-1.1,0.1l-0.5-0.4
					l-0.7-0.1l-0.8,0.6l-1.1,1.1l-0.5,0.2v2l0.3,1.8v2.8l0.2,0.7l1-0.4l0.6-0.2l0.4,0.8l1.1-0.6l0.9-0.2l1,0.9l1,0.4l1,0.2l0.5,0.4
					l-0.3,0.5l-1,0.8l-0.6,0.8l-0.4,0.5l-0.5,0.1l-0.3,0.6l0.1,0.8l-1.1-0.3l-1-1L63,874l-0.3,0.7l-0.2,1l1,0.6l0.3,0.6l0.5,1.1
					l1.1,0.2l1.2-0.7l0.6-1l1.2-1.1l0.4,0.2l0.8,0.8l1.4,1.2l1.3,0.6l1.5,0.4l0.1,1.2l0.5,2.2l0.4,1.6l-0.2,0.7l1.3-0.4l1-0.8
					c0,0,1-1.2,1-1.5c-0.1-0.3,1-1.4,1-1.4l1.1-1l1.2-0.3l2-0.2l1.2-0.7l1.3-1.4l0.8-1.3l1.1-0.6l0.9-0.5l1.1-0.9l1.2,0.6l2.1-1.1
					l0.4,1.3l0.2,2l0.1,1.8l-0.7,1.1l-1,1.3c0,0,0.2,1.3,0.2,1.7c-0.1,0.3,0,1.4,0,1.4s-0.6,0.6-0.8,0.8c-0.2,0.2-1.2,0.2-1.2,0.2
					l-0.4,0.5l0.1,1.1l0.7,1l0.1,1.1l-0.6,1v2.4l1.1,1l-0.1,0.9l0.4,2l-0.3,0.9l-1.3,0.9l-0.9,0.8l0.7,1.5l-0.6,1l-0.6,1.2l-1.2,1.8
					l-1.5,1.4l-1.9,1.9l-1.7,1.4l-2.3,1.4l-1.8,1.3l-0.2,0.2c-0.2-0.6-0.7-1.4-0.9-2.1c-0.1-0.2-0.1-0.6-0.1-0.8
					c-0.1-0.5-0.1-1.2-0.3-1.7c-0.2-0.5-0.7-1-1.1-1.5c-0.2-0.3-0.4-0.7-0.7-1c-0.1-0.1-0.4-0.2-0.5-0.4c-0.2-0.3-0.4-0.7-0.5-1.1
					c-0.1-0.3-0.3-0.7-0.4-1c-0.1-0.3,0-0.7,0-1.1c0-0.2,0-0.6,0-0.8c0-0.3-0.2-0.6-0.3-0.9c-0.1-0.4-0.4-0.8-0.5-1.1
					c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.2-0.4-0.4-0.5-0.6c-0.2-0.3-0.3-0.8-0.5-1.1c-0.2-0.3-0.6-0.8-0.9-1.1
					c-0.2-0.2-0.6-0.3-0.8-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.3-0.3-0.7-0.4-1.1c-0.1-0.2-0.1-0.5-0.2-0.7
					c-0.1-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.3-0.5-0.4-0.5l-1.1-2.6l-4.3-0.3l-10.7-2.4l-10.9,0.5l-11.8,4.2
					l-5.6,4.6l-2.3-1l-1.7-0.9l-1.5-0.4l-0.3,1.3l0.8,0.3l1.2,0.6l-0.1,1L19.3,894.5"
              ></path>
              <path
                id="VS_12_"
                class="st1"
                d="M173.6,883.2l0.5-2l0-0.9l-0.1-1.4l0.6,0.2h1.2l1,0.1l0.5-0.1l0.4-0.7l0.3-0.8l1.2-1.1l0.5-1.1
					l-0.9-0.1l-0.7-0.4l-0.7-0.4l-0.4-0.6l-0.4-0.9l-0.3-0.7l-0.8-1.3l-0.3-0.6l0.5-1.6l0.7-1.8l0.2-3.4l-0.1-0.7l-0.7-0.5l-0.6-0.2
					l-1.2,1.4l-0.8,1.1l-0.7,1.3l-0.4,1.8v1.7l-0.2,1l-1.1,1l-1,0.7l-1.1,0.6l-0.4,0.7l-2.4,1.5l-1.5,0.5l-3.3,0.8l-1.7,0.3
					l-1.1-1.1l-2-0.4l-1.9-1.2l-1.4-0.5l-1.2,0.1l-1.6-0.7l-1.2-0.6l-1,0l-0.9,0.6l-1.2,0.6l-1.4,0.5l-0.2,3.2l-2.2,1.1l-0.7,0.9
					l-1.7,1.4l-1.8,0.3l-3,0.1l-4.1,3.6l-4.7,3.9l-1.4-2.2l-2.5-1.8l-1.8-0.3l-0.8,0.8l-0.7,1.3l-0.9,1.2l-1.6,0h-1.4l-0.7,0.4
					l-0.6,0.6l1.4,0.9l-0.3,0.5l-0.8,0.9l-1.3,0.6l-1.4,0.4l-2.6-0.8l-1.5-0.3c0,0-2.5,0.6-2.7,0.7c-0.2,0.1-2.1,1.6-2.1,1.6l-1.4,1
					l-1.4,0.9l-0.9-0.3l-0.4-0.7v-2l-1.3-0.2l-1.2,0.8l-0.8,0.6l-0.2,1.4l-0.4,1.2l-0.9,0.6l-1.9,0l-1.3,0.9l-0.9,0.8l0.7,1.5
					l-0.6,1l-0.6,1.2l-1.2,1.8l-1.5,1.4l-1.9,1.9l-1.7,1.4l-2.3,1.4l-1.8,1.3l-0.2,0.2c-0.2-0.6-0.7-1.4-0.9-2.1
					c-0.1-0.2-0.1-0.6-0.1-0.8c-0.1-0.5-0.1-1.2-0.3-1.7c-0.2-0.5-0.7-1-1.1-1.5c-0.2-0.3-0.4-0.7-0.7-1c-0.1-0.1-0.4-0.2-0.5-0.4
					c-0.2-0.3-0.4-0.7-0.5-1.1c-0.1-0.3-0.3-0.7-0.4-1c-0.1-0.3,0-0.7,0-1.1c0-0.2,0-0.6,0-0.8c0-0.3-0.2-0.6-0.3-0.9
					c-0.1-0.4-0.4-0.8-0.5-1.1c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.2-0.4-0.4-0.5-0.6c-0.2-0.3-0.3-0.8-0.5-1.1
					c-0.2-0.3-0.6-0.8-0.9-1.1c-0.2-0.2-0.6-0.3-0.8-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.3-0.3-0.7-0.4-1.1
					c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.4-0.4-0.6-0.6c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.3-0.5-0.4-0.5l-1.1-2.6l-4.3-0.3
					l0.3,2.7l0.3,1.7l-0.5,0.8l-0.7,0.7l-1.3,0.4l0.3,0.7l0.8,1l0.5,1.1l0.4,0.6l0.5,0.1l1.1,0.8l0.5,0.7l0.5,0.6l0.9,0.3l0.6,0.9
					v1.6l-0.3,1.5l-0.9,1.5l-1.3,1.8l-0.9,1.9l0.2,1.7l0.1,1.8l-1.1,0.7l-0.9,1.3l-0.3,0.7l0.7,0.5l0.7,0.9l1.8-0.1l0.9-0.1l0.9-0.1
					l1.3,0.5l1.6,0.4l0.5,0.9l-0.5,0.8l0.2,1.9l-0.1,1l-0.7,0.5l-0.2,1.5l0.1,0.9l0.8-0.1l0.5-0.5l0.5-0.9l0.2-0.5l1.1-0.1l0.6,0.2
					l0.6,1.2l0.6,0.2l0.9,1.3l1.1,1.9l1.5,1.8l1.1,1.9l0.3,1.4l-0.5,1.3l1.1,0.3l1.1,0.3l0.1,1.5v1.5l0.9,0.9c0,0,1.1,1.5,1.4,1.9
					c0.3,0.4,0.7,1.3,0.7,1.3l0.7,1.2l0.5,1.3l0.3,0.6l0.5-0.1l0.7-1.3l0.8-0.9l1.5-0.1l1.3,1.1l0.7,0.9l0.6,0.1V942l0.1-0.9
					l0.8-0.7h0.9l1.2-0.1l0.7-0.1l0.9-0.2l0.6-0.6l0.8-1.1l0.4-0.9l1.4,0.2l1.6,0.4l1.3-0.1l1.4,0.5l1.2,0.6l0.6-0.5l0.6-0.3
					l0.9-0.2l0.5-0.9l1.2-0.6l1.5-0.9l0.7-0.3l-0.2-0.3l0.5-1.4l0.7-0.1l0.7-0.5l0.7-0.3l1.1-0.2h0.9l0.9-0.6l-0.2-0.8l0.6-1.1
					l0.5-0.1l0.6-0.3l1,0.5l1.1,1.2l1.2,0.2l1.9-0.3l1.6,0.2l0.9,0.6l0.5,0.6l0.9,0.7l1.1,0.7l0.4,1.7l0.5,0.3l0.3-0.1l1.5-0.7
					l1-0.1l1.3,0.4l1.5,1.4l1.3-0.9l1.4,0.3l1.2,0.1l0.9-0.5v-0.3l-0.2-2.4l0.4-1.5l1.3-1.4l1.3-0.9l5.5-0.3l0.9-0.8l0.8-1.7
					l0.7-1.5l0.9-0.8l-0.6-1.1l0.1-2.6l0.4-0.7l1.2-1.1l1.8-0.9l1.4-0.1h1.2l0.2-0.9l0.7-1.1l1-0.9l0.9-0.3l0.1-0.5l-0.3-1.5l1-0.9
					l-0.2-1.3l-0.7-0.7l-0.5-1.6l-0.1-1.2l-1-1.4l-1.3-1.1l-1.7-0.9l0.1-1.1l0.9-0.9l1.1-1.7l1.1-1.1l1-0.4l1.2,0.1l1.2,0.5l1.3-0.3
					l1.5-1.9l0.6-1v-0.6l0.6-0.5l1.2-0.4l0.7-1.2l1.1-0.7l2.4-1l0.5-1.1l-0.3-0.9l-1.1-0.3l-0.8-0.7l0.3-1.3l0.6-0.8l1.4-0.3
					l1.5-1.1L173.6,883.2z"
              ></path>
              <path
                id="NE_12_"
                class="st1"
                d="M81.5,821.4c0,0-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.5,0.2-0.7,0.3
					c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.6,0.4-0.8,0.5c-0.2,0.2-0.5,0.4-0.6,0.6c-0.2,0.2-0.3,0.6-0.4,0.9
					c-0.1,0.2-0.3,0.6-0.4,0.8c-0.1,0.3-0.4,0.9-0.4,0.9l1.6,1.5l-5.6,4.5l-2.4,2.1l-2.4,2l-7.7,5.8l-0.8-2.5l-0.8-0.6l-0.2-2.3
					l-1-0.6l0.4-1.3l-0.7-1.7l-1,0.4l-1.7,1.6l-2.1,1.3l-1.2,1.5l-2.5,0.7l-1.8,0.8l-2.9,1.9l-5.2,0.7c0.2-1,0-2.2,0-2.2l0.2-1.9
					l-0.8-0.7l-0.9-1.2l-0.8-1l1.2-1.8l1.8-2l1-1.1l0.5,0.1l0.9-0.3l1.5-0.5l1.8-0.7l1.9-0.7l0.9-0.5l1.8-0.1l0.6-0.4l0.9-1.5
					l0.9-1.9l1.1-0.6l0.8-0.1l0.8-0.3l0.1-0.6l-0.2-0.6l-0.8-0.3v-0.6l0.3-0.6h1.1l0.3-0.5l0.3-0.5l0.1-0.9l0.3-0.3l1.2,0.1l1.1-0.3
					l0.3-0.6l-0.6-1.3l0.3-0.1c0.2-0.1,0.5-0.3,0.6-0.4c0.2-0.2,0.3-0.5,0.4-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.1,0.8,0.2,1.1,0.1
					c0.3-0.1,0.7-0.5,1-0.7c0.4-0.3,0.9-0.6,1.3-0.9c0.4-0.3,0.8-0.7,1.1-1c0.6-0.5,1.5-1.1,1.8-1.7l1.5,0l0.8,0.9l0.5,1.2l0.1,1.9
					l-0.3,1.3l-0.7,0.9l0.2,1.2l1.1-0.5l1.1-0.5l1.4-0.7l1.6-0.8l2.7-0.3l1.9,0l0.5,0.3l2.2,1.2l2.1,1.4l0.3,1l-0.1,1.2l-0.3,1.5
					L81.5,821.4z"
              ></path>
              <path
                id="GE_12_"
                class="st1"
                d="M20,892.3l2.3,1l-1.2,2.2l-1.9-1.1l-0.8-0.3l0.1-1l-1.2-0.6l-0.8-0.3l0.3-1.3l1.5,0.4L20,892.3
					z M14.6,896.6l-0.4,0l-0.8,0.7l-0.2,1.7l-0.4,0.5l-0.5,0.9v1.1l0.5,0.7l0.1,1.1l-0.3,0.7l-0.7,0.3l-1.1,0.4c0,0-0.8,0.1-1.2,0.2
					c-0.4,0.1-0.6,0.2-0.6,0.2l-0.6,0.5l-0.7,0.1l-1-0.1l-0.5-0.1l-0.6,0.2L5,906.3l-0.4,0.6l-0.3,0.5l-0.9,0.2l-0.7,1.1l-0.2,0.6
					l0.6,0.3l0.6,0.2l0.2,0.5l-0.1,1.1l-0.4,1L3,913.5l-0.1,0.7l1.3-0.1l1.3,0.3l1.3,0.3l0.6-1l0.5-0.5l0.5-0.1l0.9,0.3l1.5,0.1
					l1.2,0.3l1.1,0.1l0.7-0.3l0.6-0.5l0.9-0.5l0.9-0.4l0.9-0.6l0.9-1.1l0.8-0.9l0.9-1.1l0.7-0.7l1.1-0.3l0.9-0.5l1.1-0.5l0.9-0.5
					l0.5-0.8l0.5-0.5l-0.1-0.8l-0.1-0.6l-0.6-0.3h-0.4l-0.1,0.4l-0.6,0.2h-0.9l-0.6-0.6l-1-0.7l-0.6-1.3l-1.5-1.6l-0.2-0.1l-1.4-0.9
					l-1.6-1L14.6,896.6z"
              ></path>
              <path
                id="JU_12_"
                class="st1"
                d="M100.3,781.4c-0.6-0.1-1.3,0-1.9,0.1c-0.3,0-0.8,0.1-1.1,0.1c-0.2,0-0.5,0.1-0.7,0.2
					c-0.2,0.1-0.5,0.1-0.8,0.2c-0.3,0.1-0.6,0.2-0.8,0.3c-0.2,0.1-0.5,0.2-0.6,0.4c-0.1,0.1-0.3,0.3-0.4,0.5l-1.1-0.6l-0.5-0.7
					l-1.3,0.1l-1.3-0.6l-1.3-0.4l-0.4-0.3v-1.3l0.5-1.1l0.7-1v-1.1l-1,0.4l-1.1-0.3l-0.5-0.7l-1-0.7h-0.9l-1.1,0.5l-1.3,0.6
					l-1.9-0.4l-1.7-0.4l-1.1-0.5l-1.7,0.1l-0.7,0.4l-0.3,0.6l-0.2,0.9l0.8,1.3l0.5,1.2l-0.3,0.7l-0.9,0.3l-1.1,0.8l-0.6,0.5
					l-0.8,0.2l-0.4,0.6l-0.3,1.5l-0.5,0.3l-0.8,0.7l-0.2,1.3l-0.9,0.8l-0.4,1l0.3,1.3l3,0.1L73,789l1.2-0.5l1-0.5l1.5-0.4l1.5,0.1
					l0.6,0.5l0.7,0.7l0.8,1.5l-0.5,0.5l-0.1,0.7l-0.4,0.7l-1.4,0.3l-0.7,0.7l-0.4,0.9l-0.2,0.9c-0.1,0.1-0.6,0.4-0.8,0.5
					c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0-0.6-0.1-0.8,0c-0.3,0.1-0.5,0.4-0.7,0.5c-0.2,0.1-0.4,0.2-0.5,0.3c-0.3,0.1-0.7-0.1-0.9,0.1
					c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0,0.4c0.1,0.2,0.4,0.3,0.4,0.4c0.2,0.4,0,0.9,0,1.3c0,0.3-0.1,0.7-0.1,1.1
					c0,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.4,0.3-0.6,0.4c-0.2,0.2-0.4,0.4-0.5,0.6c-0.4,0.4-1,0.8-1.4,1.1
					c-0.4,0.3-1,0.6-1.3,0.9c-0.4,0.3-0.8,0.9-1.1,1.3c-0.2,0.2-0.3,0.6-0.4,0.8c-0.2,0.3-0.4,0.7-0.7,1c-0.2,0.2-0.5,0.3-0.7,0.4
					c-0.2,0-0.4-0.1-0.5,0c-0.2,0.1-0.4,0.3-0.4,0.4s0.3,0.4,0.4,0.4c0.1,0,0.3-0.2,0.4-0.2c0.1,0.1,0.1,0.4,0.1,0.5
					c0,0.2,0.1,0.4,0,0.5l1.5,0l0.8,0.9l1.8-0.2l1-0.7l1.5-1.3l0.9-0.8l1.9,0.2l1-0.1l0.5-0.8l0.1-0.8l1.9-0.3l1.2-1.6l1-2.2L81,801
					l2.4,0.5l4.2-0.3l-0.4-2.1l1.2-0.7l-0.1-1.4l0.5-1.1l1.2-0.2h1.3l1,1.1l1.9,0.4l2.3-0.1l2-0.4l1.7-0.9l1.7-1.4l1.2-1.6l0.5,0.2
					l0.3,1.6l1.4-0.1l2.9,0.3l1.7,0.2l2,0.7l1.1-0.8l1-1.2h2.1l0.7,0.1l0.1-0.9l-2.2,0l-0.2-0.5l-0.5-1.1l-0.1-1V789l-1.4-0.1
					l-1.7,0.4l-2-0.4l-0.9-1.5l-0.6,0.1l-0.9-0.2l-0.5-0.8l-1.7-1.5l-0.3-0.6l-0.1-0.7l-1.8-0.2l-1.3-1.2L100.3,781.4z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    regions: { type: [Array], default: null },
  },
  updated() {
    this.$nextTick(function() {});
  },
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: #ededed;
}

.st1 {
  fill: #ededed;
  stroke: #ededed;
  /*stroke-width: 2rem;*/
}

.available,
.available path {
  fill: #51b49a;
  /*stroke: #51b49a;
        stroke-width: 5px;*/
  stroke-width: 1px;
}

path {
  stroke-width: 0.5px;
  stroke: white;
}

#Bremen.available {
  stroke-width: 10px;
  stroke: #51b49a;
}

.region-map {
  max-width: 300px;
  display: block;
  margin: 1rem auto;
}
</style>
