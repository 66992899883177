var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper"},[(_vm.tabAllAndNoChallenges || _vm.tabSponsoredAndNoChallenges)?_c('div',{staticClass:"not-found"},[_vm._m(0),_c('hr'),_vm._v(" In diesem Bereich werden Ihnen Ihre "),_c('strong',[_vm._v("gesponserten Challenges")]),_vm._v(", die einzelnen "),_c('strong',[_vm._v("Lösungen")]),_vm._v(" und die "),_c('strong',[_vm._v("Entwickler:innen")]),_vm._v(" angezeigt. Sie möchten eine Challenge sponsern oder zusätzliche Informationen erhalten? Dann kontaktieren Sie uns per E-Mail an "),_c('a',{staticClass:"premium-link",attrs:{"href":"mailto:hallo@entwicklerheld.de"}},[_vm._v("hallo@entwicklerheld.de")]),_vm._v(". ")]):_vm._e(),(_vm.tabAllOrSponsoredAndError)?_c('base-error',{attrs:{"msg":"Fehler beim Laden der gesponserten Challenges"}}):_vm._e(),(_vm.tabAllOrSponsoredAndNotLoaded)?_c('p-challenge-card-loading-indicator'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(
        (_vm.tab === 'all' || _vm.tab === 'sponsored') &&
          _vm.detailsForSponsoredChallengesRequest.loaded &&
          !_vm.detailsForSponsoredChallengesRequest.error
      )?_c('div',{staticClass:"row"},_vm._l((_vm.sponsoredChallengesList),function(challenge){return _c('p-challenge-card',{key:challenge.id,attrs:{"challenge":challenge}})}),1):_vm._e()]),(_vm.tabPoolAndError)?_c('base-error',{attrs:{"msg":"Fehler beim Laden der Pool-Challenges"}}):_vm._e(),(_vm.tabPoolAndNoChallenges)?_c('div',{staticClass:"not-found"},[_vm._v(" Keine Pool-Challenges gefunden ")]):_vm._e(),(_vm.tabAllOrPoolAndNotLoaded)?_c('p-challenge-card-loading-indicator'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(
        (_vm.tab === 'all' || _vm.tab === 'pool') &&
          _vm.detailsForPoolChallengesRequest.loaded &&
          !_vm.detailsForPoolChallengesRequest.error
      )?_c('div',{staticClass:"row"},_vm._l((_vm.poolChallengesList),function(challenge){return _c('p-challenge-card',{key:challenge.id,attrs:{"challenge":challenge}})}),1):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('i',{staticClass:"fa fa-code"}),_vm._v(" Keine gesponserte Challenge gefunden"),_c('br')])
}]

export { render, staticRenderFns }