<template>
  <div>
    <c-header></c-header>
    <c-challenges-list v-if="selectedTab === 'ALL'" tab="all" sponsored />
  </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import CHeader from "@/components/challenges/CHeader";
import CChallengesList from "@/components/challenges/CChallengesList";

export default {
  components: {
    CChallengesList,
    CHeader,
  },
  mixins: [AuthenticatedMixin],
  data() {
    return {
      selectedTab: "ALL",
    };
  },
};
</script>

<style lang="scss" scoped>
.report-tab-wrapper {
  margin-bottom: 2rem;
}

.report-tab {
  background-color: white;
  padding: 1rem 2rem;
  margin-right: 2rem;

  &:hover {
    cursor: pointer;
  }

  &:hover:not(.active) {
    background-color: $eh-20;
  }
}

.active {
  background-color: $eh-green;
  color: white;
}
</style>
