<template>
  <div class="col-xl-6 col-lg-6 card-wrapper">
    <div class="challenge-card-loader">
      <content-loader width="771" height="257.9" :preserveAspectRatio="true" style="">
        <circle cx="55" cy="64" r="30"/>
        <path
          d="M106.3,54h637.3c2.4,0,4.3,1.8,4.3,4v12c0,2.2-1.9,4-4.3,4H106.3c-2.4,0-4.3-1.8-4.3-4V58C102,55.8,103.9,54,106.3,54z"/>
        <rect x="25" y="136" width="357.5" height="20"/>
        <rect x="181.5" y="204.9" width="408" height="20"/>
        <rect x="390.5" y="136" width="357.5" height="20"/>
      </content-loader>
    </div>
  </div>
</template>

<script>
import {ContentLoader} from "vue-content-loader";

export default {
  name: "PChallengeCardLoadingIndicator",
  components: {ContentLoader},
  props: {height: {type: Number, default: 260}},
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin-bottom: 2rem;
  padding-left: 0;
}

.challenge-card-loader {
  background: white;
  width: 100%;
  box-shadow: $elevation1;
}

.challenge-card {
  background-color: #fff;
  border-radius: 5px;
  flex: 1;
  display: flex;
  flex-direction: row;
  box-shadow: $elevation1;
  transition: $elevation1-transition;

  &:hover {
    box-shadow: $elevation1-hover;
    // transform: scale(1.02);
    cursor: pointer;
  }

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .icon {
      height: 3rem;
      margin-right: 1rem;
    }

    .title {
      font-size: 1.1rem;
      font-weight: 600;
      margin: 0;
      height: 15%;
    }
  }

  .stats {
    margin-bottom: 2rem;

    &-taken {
      margin-right: 2rem;
    }

    .stat-number {
      font-weight: bold;
    }
  }

  .solved-content {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .solved-count {
    margin-right: 0.8rem;
  }

  .progress {
    flex: 1;

    height: 0.5rem;

    .progress-bar {
      background-color: $eh-green;
    }
  }

  .regions {
    margin-bottom: 2rem;
    flex: 1;

    &-header {
      font-size: 1.1rem;
      font-weight: bold;
      color: #509797;
    }

    .regions-map {
      width: 70%;
    }
  }

  .access {
    color: $eh-green;
    font-weight: bold;
  }
}
</style>
