<template>
  <div class="list-wrapper">
    <div v-if="tabAllAndNoChallenges || tabSponsoredAndNoChallenges" class="not-found">
      <h2><i class="fa fa-code">
        </i> Keine gesponserte Challenge gefunden<br>
      </h2>
      <hr/>
      In diesem Bereich werden Ihnen Ihre <strong>gesponserten Challenges</strong>, die einzelnen <strong>Lösungen</strong> und die <strong>Entwickler:innen</strong> angezeigt. Sie möchten eine Challenge sponsern oder zusätzliche Informationen erhalten? Dann kontaktieren Sie uns per E-Mail an <a href="mailto:hallo@entwicklerheld.de" class="premium-link">hallo@entwicklerheld.de</a>.
    </div>
    <base-error v-if="tabAllOrSponsoredAndError" msg="Fehler beim Laden der gesponserten Challenges" />

    <p-challenge-card-loading-indicator v-if="tabAllOrSponsoredAndNotLoaded" />
    <transition name="fade">
      <div
        v-if="
          (tab === 'all' || tab === 'sponsored') &&
            detailsForSponsoredChallengesRequest.loaded &&
            !detailsForSponsoredChallengesRequest.error
        "
        class="row"
      >
        <p-challenge-card v-for="challenge in sponsoredChallengesList" :key="challenge.id" :challenge="challenge" />
      </div>
    </transition>
    <base-error v-if="tabPoolAndError" msg="Fehler beim Laden der Pool-Challenges" />
    <div v-if="tabPoolAndNoChallenges" class="not-found">
      Keine Pool-Challenges gefunden
    </div>

    <p-challenge-card-loading-indicator v-if="tabAllOrPoolAndNotLoaded"/>
    <transition name="fade">
      <div
        v-if="
          (tab === 'all' || tab === 'pool') &&
            detailsForPoolChallengesRequest.loaded &&
            !detailsForPoolChallengesRequest.error
        "
        class="row"
      >
        <p-challenge-card v-for="challenge in poolChallengesList" :key="challenge.id" :challenge="challenge" />
      </div>
    </transition>
  </div>
</template>

<script>
import { services } from "@/main";
import PChallengeCard from "@/components/challenges/PChallengeCard";
import { SponsoredChallengesResult, PoolChallengesResult } from "@/services/company/CompanyServiceResponses";
import { ChallengeList } from "@/services/task-management/TaskManagementServiceResponses";
import BaseError from "@/components/base/BaseError";
import PChallengeCardLoadingIndicator from "@/components/challenges/PChallengeCardLoadingIndicator";

export default {
  components: {
    PChallengeCardLoadingIndicator,
    PChallengeCard,
    BaseError,
  },
  props: {
    tab: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      expanded: true,
      sponsoredChallengesRequest: new SponsoredChallengesResult(),
      poolChallengesRequest: new PoolChallengesResult(),
      detailsForSponsoredChallengesRequest: new ChallengeList(),
      detailsForPoolChallengesRequest: new ChallengeList(),
    };
  },
  computed: {
    tabAllAndNoChallenges() {
      return (
        this.detailsForSponsoredChallengesRequest.loaded &&
        this.detailsForPoolChallengesRequest.loaded &&
        this.tab === "all" &&
        this.sponsoredChallengesList.length === 0 &&
        this.poolChallengesList.length === 0
      );
    },
    tabAllOrSponsoredAndError() {
      return (
        (this.tab === "sponsored" || this.tab === "all") &&
        (this.detailsForSponsoredChallengesRequest.error || this.sponsoredChallengesRequest.error)
      );
    },
    tabSponsoredAndNoChallenges() {
      return (
        this.detailsForSponsoredChallengesRequest.loaded &&
        this.tab === "sponsored" &&
        this.sponsoredChallengesList.length === 0
      );
    },
    tabPoolAndError() {
      return (
        (this.poolChallengesRequest.error || this.detailsForPoolChallengesRequest.error) &&
        (this.tab === "pool" || this.tab === "all")
      );
    },
    tabPoolAndNoChallenges() {
      return this.detailsForPoolChallengesRequest.loaded && this.tab === "pool" && this.poolChallengesList.length === 0;
    },
    titlePoolChallengesMap() {
      return new Map(
        this.detailsForPoolChallengesRequest.apiData.map((challenge) => [
          challenge.id,
          {
            title: challenge.title,
            icon: challenge.icon,
            technology: challenge.technology.name,
            language: challenge.technology.language,
          },
        ])
      );
    },
    tabAllOrSponsoredAndNotLoaded() {
      return (
        (this.tab === "sponsored" || this.tab === "all") &&
        (!this.sponsoredChallengesRequest.loaded || !this.detailsForSponsoredChallengesRequest.loaded) &&
        !this.sponsoredChallengesRequest.error &&
        !this.detailsForSponsoredChallengesRequest.error
      );
    },
    tabAllOrPoolAndNotLoaded() {
      return (
        (this.tab === "pool" || this.tab === "all") &&
        (!this.poolChallengesRequest.loaded || !this.detailsForPoolChallengesRequest.loaded) &&
        !this.poolChallengesRequest.error &&
        !this.detailsForPoolChallengesRequest.error
      );
    },
    titleSponsoredChallengesMap() {
      return new Map(
        this.detailsForSponsoredChallengesRequest.apiData.map((challenge) => [
          challenge.id,
          {
            title: challenge.title,
            icon: challenge.icon,
            technology: challenge.technology.name,
            language: challenge.technology.language,
          },
        ])
      );
    },
    sponsoredChallengesList() {
      let sponsoredChallengesList = [];

      for (let sponsoredChallenge of this.sponsoredChallengesRequest.apiData) {
        let sponsoredChallengeEntry = {};
        sponsoredChallengeEntry["sponsoredChallengeId"] = sponsoredChallenge.id;
        sponsoredChallengeEntry["challengeId"] = sponsoredChallenge.challenge_id;
        sponsoredChallengeEntry["companyId"] = sponsoredChallenge.company_id;
        sponsoredChallengeEntry["solved"] = sponsoredChallenge.solved;
        sponsoredChallengeEntry["accepted"] = sponsoredChallenge.accepted;
        sponsoredChallengeEntry["regions"] = sponsoredChallenge.regions;
        sponsoredChallengeEntry["start"] = this.prettifyDate(sponsoredChallenge.start);
        sponsoredChallengeEntry["end"] = this.prettifyDate(sponsoredChallenge.end);

        let entry = { title: "", icon: "", technology: "", language: "" };
        if (this.titleSponsoredChallengesMap.has(sponsoredChallenge.challenge_id)) {
          entry = this.titleSponsoredChallengesMap.get(sponsoredChallenge.challenge_id);
        }
        sponsoredChallengeEntry["title"] = entry.title;
        sponsoredChallengeEntry["icon"] = entry.icon;
        sponsoredChallengeEntry["technology"] = entry.technology;
        sponsoredChallengeEntry["language"] = entry.language;

        sponsoredChallengesList.push(sponsoredChallengeEntry);
      }

      return sponsoredChallengesList;
    },
    poolChallengesList() {
      let poolChallengesList = [];

      for (let poolChallenge of this.poolChallengesRequest.apiData) {
        let poolChallengeEntry = {};
        poolChallengeEntry["poolChallengeId"] = poolChallenge.id;
        poolChallengeEntry["challengeId"] = poolChallenge.challengeId;
        poolChallengeEntry["companyId"] = poolChallenge.companyId;
        poolChallengeEntry["solved"] = poolChallenge.solved;
        poolChallengeEntry["accepted"] = poolChallenge.accepted;
        poolChallengeEntry["start"] = this.prettifyDate(poolChallenge.start);
        poolChallengeEntry["end"] = this.prettifyDate(poolChallenge.end);

        let entry = { title: "", icon: "", technology: "", language: "" };
        if (this.titlePoolChallengesMap.has(poolChallenge.challengeId)) {
          entry = this.titlePoolChallengesMap.get(poolChallenge.challengeId);
        }
        poolChallengeEntry["title"] = entry.title;
        poolChallengeEntry["icon"] = entry.icon;
        poolChallengeEntry["technology"] = entry.technology;
        poolChallengeEntry["language"] = entry.language;

        poolChallengesList.push(poolChallengeEntry);
      }

      return poolChallengesList;
    },
  },
  watch: {
    tab: function() {
      this.detailsForSponsoredChallengesRequest = new ChallengeList();
      this.detailsForPoolChallengesRequest = new ChallengeList();
      this.sponsoredChallengesRequest = services.companyService.getSponsoredChallenges();
      this.poolChallengesRequest = services.companyService.getPoolChallenges();
    },
    "sponsoredChallengesRequest.loaded": function() {
      if (!this.sponsoredChallengesRequest.hasLoaded()) {
        return;
      }
      let challengeIdList = [];
      for (let sponsoredChallenge of this.sponsoredChallengesRequest.apiData) {
        challengeIdList.push(sponsoredChallenge.challenge_id);
      }
      this.detailsForSponsoredChallengesRequest = services.taskManagementService.getDataOfMultipleChallenges(
        challengeIdList
      );
    },
    "poolChallengesRequest.loaded": function() {
      if (!this.poolChallengesRequest.hasLoaded()) {
        return;
      }
      let poolChallengeIdList = [];
      for (let poolChallenge of this.poolChallengesRequest.apiData) {
        poolChallengeIdList.push(poolChallenge.challengeId);
      }
      this.detailsForPoolChallengesRequest = services.taskManagementService.getDataOfMultipleChallenges(
        poolChallengeIdList
      );
    },
  },
  mounted() {
    this.sponsoredChallengesRequest = services.companyService.getSponsoredChallenges();
    this.poolChallengesRequest = services.companyService.getPoolChallenges();
  },
  methods: {
    prettifyDate: function(isoDate) {
      return isoDate
        .split("T")
        .shift()
        .split("-")
        .reverse()
        .join(".");
    },
  },
};
</script>

<style lang="scss" scoped>
.list-wrapper {
  margin-bottom: 2rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.not-found {
  border-radius: 5px;
  background: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  padding: 2rem;
}

.fade-enter-active {
  transition: opacity 0.4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
