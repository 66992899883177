<template>
  <div class="col-xl-6 col-lg-6 card-wrapper">
    <div class="challenge-card" @click="goToChallenge(challenge)">
      <div>
        <div class="title-container">
          <img class="icon" :src="challenge.icon" alt />
          <h2 class="title"
            >{{ challenge.title }} ({{ challenge.language
            }}<span v-if="challenge.technology">/{{ challenge.technology }}</span
            >)
          </h2>
        </div>
        <hr/>
        <div class="stats d-flex">
          <div class="solved-content text-center">
            <span class="solved-count">Annahmen: <strong>{{ challenge.accepted }}</strong></span>
            <span class="solved-count">Lösungen: <strong>{{ challenge.solved }}</strong></span>
            <span class="solved-count">Lösungsquote: <strong>{{Math.round(challenge.solved / challenge.accepted * 100) }}%</strong></span>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="{ width: progressWidth }"
                :aria-valuenow="challenge.solved"
                aria-valuemin="0"
                :aria-valuemax="challenge.accepted"
              ></div>
            </div>
          </div>
        </div>
        <div class="access text-center">{{ challenge.start }} - {{ challenge.end }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CRegionMap from "@/components/challenges/CRegionMap";
import { navigateTo } from "@/utils/routing";

export default {
  components: {
  },
  props: {
    challenge: {
      type: Object,
      default: () => ({}),
    },
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    percentageSolved: function() {
      return (this.challenge.solved * 100) / this.challenge.accepted;
    },
    progressWidth: function() {
      return `${Math.round(this.percentageSolved)}%`;
    },
  },
  methods: {
    goToChallenge: function(challenge) {
      navigateTo(this.$router, {
        name: "challenge-details",
        params: {
          id: challenge.sponsoredChallengeId || challenge.poolChallengeId,
          sponsored: challenge.sponsoredChallengeId ? "sponsored" : "pool",
          challenge,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin-bottom: 2rem;
  display: flex;
}

.challenge-card {
  padding: 3rem 2rem;
  background-color: #fff;
  border-radius: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: $elevation1;
  transition: $elevation1-transition;

  &:hover {
    box-shadow: $elevation1-hover;
    // transform: scale(1.02);
    cursor: pointer;
  }

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .icon {
      height: 3rem;
      margin-right: 1rem;
    }

    .title {
      font-size: 1.1rem;
      font-weight: 600;
      margin: 0;
      height: 15%;
    }
  }

  .stats {
    margin-bottom: 2rem;

    &-taken {
      margin-right: 2rem;
    }

    .stat-number {
      font-weight: bold;
    }
  }

  .solved-content {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .solved-count {
    margin-right: 0.8rem;
  }

  .progress {
    flex: 1;

    height: 0.5rem;

    .progress-bar {
      background-color: #6CC99C;
    }
  }

  .regions {
    margin-bottom: 2rem;
    flex: 1;

    &-header {
      font-size: 1.1rem;
      font-weight: bold;
      color: #509797;
    }

    .regions-map {
      width: 70%;
    }
  }

  .access {
    color: $eh-green;
    font-weight: bold;
  }
}
</style>
