<template>
  <fragment>
    <div v-if="employeeByUserIdRequest.loaded && !employeeByUserIdRequest.error" class="heading-container">
      <h1 class="">
        Gesponserte Challenges
      </h1>
    </div>
    <div v-else class="loading-wrapper">
      <content-loader :height="35">
        <rect width="13%" />
        <rect x="14%" y="75%" width="20%" height="25%" />
      </content-loader>
    </div>
  </fragment>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

import { services } from "@/main";
import { EmployeeByUserIdResult } from "@/services/company/CompanyServiceResponses";

export default {
  components: {
    ContentLoader,
  },
  data() {
    return {
      employeeByUserIdRequest: new EmployeeByUserIdResult(),
    };
  },
  mounted() {
    this.employeeByUserIdRequest = services.companyService.getEmployeeByUserId();
  },
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  margin-bottom: 4rem;
}

.heading-container {
  margin-bottom: 4rem;
  display: flex;

  .heading {
    align-self: flex-end;
    margin: 0;

    .heading-appendix {
      font-size: 1.2rem;
      color: #777;
    }
  }

  .img-container {
    background-color: #fff;
    padding: 2rem;
    margin-right: 1rem;
    box-shadow: $elevation1;
  }
}
</style>
